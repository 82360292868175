import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useBranchHierarchy from '../../hooks/useBranchHierarchy'
import LinkToLeafItem from './LinkToLeafItem'

const StyledContainer = styled.div`
    // margin: 16px;
`

export const LeafListContainer = (...rest) => {
    const [leafList, setLeafList] = useState()
    const { hierarchy } = useBranchHierarchy()

    useEffect(() => {
        const generateList = (doc, i = 0) => {
            const parent = doc && (
                <LinkToLeafItem key={doc.id} leafId={doc.id} doc={doc} />
            )
            const children =
                doc &&
                doc.children.map((childDoc) => {
                    return generateList(childDoc, i)
                })
            const partner =
                doc &&
                doc.partners.map((partnerDoc) => {
                    return (
                        <LinkToLeafItem
                            key={partnerDoc.id}
                            leafId={partnerDoc.id}
                            doc={partnerDoc}
                        />
                    )
                })

            i++

            return (
                <StyledContainer key={doc?.id}>
                    {parent}
                    {partner}

                    <div style={{ paddingLeft: `${i * 3 * 8}px` }}>
                        {children}
                    </div>
                </StyledContainer>
            )
        }

        setLeafList(generateList(hierarchy[0]))
    }, [hierarchy])

    return <>{leafList}</>
}

export default LeafListContainer
