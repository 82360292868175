import React, { useContext, useState } from 'react'
import LeafImagesContextProvider, {
    LeafImagesContext,
} from '../../contexts/LeafImageContext'
import CurrentTreeMembersContext from '../../contexts/CurrentTreeMembersContext'
import Avatar from '../atoms/Avatar'
import Header from '../atoms/Header'
import Card from '../organisms/Card'
import Modal from '../organisms/Modal'
import styled from 'styled-components'
import LeavesContext from '../../contexts/LeavesContext'
import Select from '../atoms/Select'
import {
    CHILD_OPTIONS,
    PARENT_OPTIONS,
    PARTNER_OPTIONS,
    SIBLING_OPTIONS,
} from '../../assets/js/utils'
import ButtonGroup from '../organisms/ButtonGroup'
import Button from '../atoms/Button'
import { useParams } from 'react-router'
import firebase from 'firebase/app'
import toaster from 'toasted-notes'

const StyledHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 40px;
`

const StyledTextContainer = styled.div``

const StyledListContainer = styled.ul`
    margin: 0 16px;
`
const StyledItemContainer = styled.li`
    display: flex;
    align-items: center;
    margin-top: 8px;
`

export const EditRelativesModal = ({
    leafDoc,
    memberDoc,
    type,
    isShowing,
    hide,
    ...rest
}) => {
    const { getImageUrlById } = useContext(LeafImagesContext)
    const relType = type.charAt(0).toUpperCase() + type.slice(1)
    const displayName = memberDoc?.display_name || leafDoc?.display_name
    const { leaves } = useContext(LeavesContext)
    const { treeId } = useParams()
    const options = {
        siblings: SIBLING_OPTIONS,
        children: CHILD_OPTIONS,
        parents: PARENT_OPTIONS,
        partners: PARTNER_OPTIONS,
    }
    const [relationships, setRelationships] = useState({})
    const leavesRef = firebase
        .firestore()
        .collection('trees')
        .doc(treeId)
        .collection('leaves')
    const arrayUnion = firebase.firestore.FieldValue.arrayUnion
    let relativeType = type
    const { currentTreeMembers } = useContext(CurrentTreeMembersContext)

    switch (type) {
        case 'children':
            relativeType = 'parents'
            break
        case 'parents':
            relativeType = 'children'
            break
        default:
            relativeType = type
            break
    }

    // show the current relationships status

    const handleSubmit = (e) => {
        e.preventDefault()
        const relArray = Object.entries(relationships)

        if (relArray.length > 0) {
            // Update relatives
            relArray.map(([relativeId, value]) => {
                const relativeDoc = leaves.find((l) => l.id === relativeId)
                const currRelativeRelationships = relativeDoc.relationships[
                    relativeType
                ].filter((r) => r.id !== leafDoc.id)
                const newRelativeRelationships = [
                    ...currRelativeRelationships,
                    {
                        id: leafDoc.id,
                        relation: value,
                    },
                ]

                leavesRef
                    .doc(relativeId)
                    .update({
                        [`relationships.${relativeType}`]: newRelativeRelationships,
                    })
                    .then(() => {
                        console.log('Updated?')
                    })
            })

            // Update OG leaf
            const currentOriginRelationships = leafDoc.relationships[type]
            const newOriginRelationships = currentOriginRelationships.map(
                (relObj) => {
                    const match = relArray.find((obj) => obj[0] === relObj.id)
                    if (match) {
                        return {
                            id: relObj.id,
                            relation: match[1],
                        }
                    } else {
                        return relObj
                    }
                }
            )

            leavesRef
                .doc(leafDoc.id)
                .update({
                    [`relationships.${type}`]: newOriginRelationships,
                })
                .then(() => {
                    toaster.notify('Relationships updated!')
                })
        }

        setRelationships({})
        hide()
    }

    const handleChange = (e) => {
        const relativeId = e.target.name
        const value = e.target.value

        setRelationships({ ...relationships, [relativeId]: value })
    }

    return (
        <Modal isShowing={isShowing} hide={hide} size="m" closeButton={true}>
            <Card.Container as="form" onSubmit={(e) => handleSubmit(e)}>
                <Card.Content>
                    <StyledHeader>
                        <Avatar
                            className="u-mar-r_3"
                            size="88px"
                            src={getImageUrlById(memberDoc?.id || leafDoc?.id)}
                            isMember={memberDoc}
                        />
                        <StyledTextContainer>
                            <div>{`${relType} of`}</div>
                            <Header>{displayName}</Header>
                        </StyledTextContainer>
                    </StyledHeader>
                    <StyledListContainer>
                        {leafDoc?.relationships[type].map((r) => {
                            const doc = leaves.find((l) => l.id === r.id)
                            const claimedDoc = doc.claimed_by
                                ? currentTreeMembers.find(
                                      (m) => m.id === doc.claimed_by
                                  )
                                : null

                            if (doc) {
                                return (
                                    <StyledItemContainer key={doc.id}>
                                        <Avatar
                                            className="u-mar-r_2"
                                            src={getImageUrlById(
                                                claimedDoc?.id || doc.id
                                            )}
                                            isMember={claimedDoc}
                                        />
                                        {claimedDoc?.display_name ||
                                            doc.display_name}
                                        <Select
                                            onChange={(e) => handleChange(e)}
                                            name={r.id}
                                            className="u-mar-l_auto"
                                            defaultValue={r.relation}
                                        >
                                            <option value="">Choose...</option>
                                            {options[type].map((opt) => (
                                                <option key={opt} value={opt}>
                                                    {opt}
                                                </option>
                                            ))}
                                        </Select>
                                    </StyledItemContainer>
                                )
                            }
                        })}
                    </StyledListContainer>
                </Card.Content>
                <Card.Footer>
                    <ButtonGroup>
                        <Button
                            type="button"
                            onClick={() => hide()}
                            whiteTransparent={true}
                        >
                            Cancel
                        </Button>
                        <Button>Save relationships</Button>
                    </ButtonGroup>
                </Card.Footer>
            </Card.Container>
        </Modal>
    )
}

export default EditRelativesModal
