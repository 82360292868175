import React, { useContext, useState } from 'react'
import MemberContext from '../../contexts/MemberContext'
import styled from 'styled-components'
import ButtonGroup from '../organisms/ButtonGroup'
import Button from '../atoms/Button'
import LeafProfileItem from './DetailsPanel/LeafProfileItem'
import Avatar from '../atoms/Avatar'
import Header from '../atoms/Header'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import useModal from '../../hooks/useModal'
import IconButton from '../atoms/IconButton'
import UploadPhotoModal from './UploadPhotoModal'
import useProfileData from '../../hooks/useProfileData'
import useProfileForm from '../../hooks/useProfileForm'
import ChangePassword from '../organisms/ChangePassword'
import Dropdown from '../organisms/Dropdown'
import useMemberProfile from '../../hooks/useMemberProfile'

const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 40px;
    margin-top: 56px;
    align-items: flex-start;
    // padding: 16px 40px;
`

const StyledNameDetails = styled.div`
    font-size: 15px;
    color: var(--color_text_600);
`

const StyledHeaderArea = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
    margin-top: 8px;
    width: 100%;
`

const StyledButton = styled(Button)`
    display: block;
`

const StyledButtonGroup = styled(ButtonGroup)`
    margin-left: auto;
`

const StyledAvatarWrapper = styled.div`
    display: inline-flex;
    align-items: flex-start;
    position: sticky;
    top: 0;
`

const StyledUploadPhotoButton = styled(IconButton)`
    position: absolute;
    right: 8px;
    bottom: 8px;
`

export const MemberProfile = ({ changeEditMode, ...rest }) => {
    const { memberData, memberPhoto } = useContext(MemberContext)
    const [editMode, setEditMode] = useState(false)
    const [toggleMemberUploadModal, isMemberUploadModalShowing] = useModal(
        false
    )
    const [profileInputs, submitForm] = useProfileForm(memberData)
    const [changePasswordMode, setChangePasswordMode] = useState(false)
    const { memberProfile } = useMemberProfile()

    return (
        <>
            <StyledContainer
                as={editMode ? 'form' : 'div'}
                onSubmit={(e) =>
                    editMode && submitForm(e, () => setEditMode(false), true)
                }
            >
                <StyledAvatarWrapper>
                    <Avatar borderRadius="25%" size="l" src={memberPhoto} />
                    {editMode && (
                        <StyledUploadPhotoButton
                            onClick={toggleMemberUploadModal}
                            label="Change photo"
                            icon="pencil-alt"
                            type="button"
                            data-tooltip="Edit photo"
                            data-tooltip-position="bm"
                            circle={true}
                        />
                    )}
                </StyledAvatarWrapper>
                <div>
                    <StyledHeaderArea>
                        <div>
                            <Header size="l">
                                {editMode
                                    ? 'Edit profile'
                                    : memberProfile.displayName}
                            </Header>
                            {!editMode && memberProfile?.nameDetails && (
                                <StyledNameDetails>
                                    {memberProfile &&
                                        memberProfile?.nameDetails?.map(
                                            (detail) => <div>{detail}</div>
                                        )}
                                </StyledNameDetails>
                            )}
                        </div>
                        <div>
                            {!editMode && !changePasswordMode && (
                                <ButtonGroup>
                                    <Dropdown
                                        trigger={
                                            <IconButton
                                                iconType="regular"
                                                whiteTransparent={true}
                                                icon="ellipsis-h"
                                            />
                                        }
                                    >
                                        <Dropdown.Item
                                            onClick={() =>
                                                setChangePasswordMode(true)
                                            }
                                        >
                                            Change password
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() =>
                                                alert('Coming soon!')
                                            }
                                        >
                                            Change account email
                                        </Dropdown.Item>
                                    </Dropdown>
                                    <StyledButton
                                        onClick={() => setEditMode(true)}
                                    >
                                        Edit profile
                                    </StyledButton>
                                </ButtonGroup>
                            )}
                            {editMode && (
                                <StyledButtonGroup>
                                    <Button
                                        outline={true}
                                        type="button"
                                        onClick={() => setEditMode(false)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button>Save changes</Button>
                                </StyledButtonGroup>
                            )}
                        </div>
                    </StyledHeaderArea>
                    <div>
                        {editMode && !changePasswordMode && profileInputs}
                        {!editMode &&
                            !changePasswordMode &&
                            memberProfile?.data?.map((details) => (
                                <LeafProfileItem
                                    key={details.key}
                                    data={details}
                                    labelWidth="35%"
                                    showActions={false}
                                />
                            ))}
                        {changePasswordMode && (
                            <ChangePassword
                                changePasswordMode={setChangePasswordMode}
                            />
                        )}
                    </div>
                </div>
            </StyledContainer>
            {isMemberUploadModalShowing && (
                <UploadPhotoModal
                    isModalShowing={isMemberUploadModalShowing}
                    hide={toggleMemberUploadModal}
                    docType="member"
                />
            )}
        </>
    )
}

export default MemberProfile
