import React, { useContext } from 'react'
import Modal from '../organisms/Modal'
import Card from '../organisms/Card'
import styled from 'styled-components'
import { UserContext } from '../../contexts/UserContext'
import Icon from '../atoms/Icon'

const StyledMessage = styled.div`
    color: var(--color_text_700);
    margin-bottom: 16px;
`

const StyledAccountEmail = styled.span`
    font-weight: bold;
    color: var(--color_text);
`

const SyledCardContent = styled(Card.Content)`
    padding: 56px;
    text-align: center;
`

const StyledIcon = styled(Icon)`
    font-size: 32px;
    margin-bottom: 28px;
    color: var(--turquoise_300);
`

const StyledHeader = styled.div`
    color: var(--turquoise_300);
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 24px;
`

export const EmailVerificationModal = ({ ...rest }) => {
    const { user } = useContext(UserContext)

    if (user) {
        return (
            <Modal isShowing={!user.emailVerified} size="400px" {...rest}>
                <Card.Container>
                    <SyledCardContent>
                        <StyledIcon icon="envelope" />
                        <StyledMessage>
                            <StyledHeader>Check your inbox</StyledHeader>
                            {`Verify that `}
                            <StyledAccountEmail>
                                {user?.email}
                            </StyledAccountEmail>
                            {`  belongs to you.`}
                        </StyledMessage>
                        <StyledMessage>
                            Not seeing the email? Check your spam.
                        </StyledMessage>
                        <StyledMessage>
                            Once verified, <a href="/trees">refresh</a> this
                            page.
                        </StyledMessage>
                    </SyledCardContent>
                </Card.Container>
            </Modal>
        )
    } else {
        return null
    }
}

export default EmailVerificationModal
