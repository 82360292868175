export const THEME_COLOR_OPTIONS = [
    {
        name: 'purple-blue',
        hex: '#835fb3',
    },
    {
        name: 'blue-purple',
        hex: '#5497d5',
    },
    {
        name: 'lightGreen-blue',
        hex: '#609462',
    },
]

export const PRIVATE_PROFILE_DETAILS = {
    'First name': {
        pathName: 'first_name',
        category: 'name',
        defaultValue: null,
        icon: 'id-badge',
    },
    'Middle name': {
        pathName: 'middle_name',
        category: 'name',
        defaultValue: null,
        icon: 'id-badge',
    },
    'Last name (current)': {
        pathName: 'last_name_current',
        category: 'name',
        defaultValue: null,
        icon: 'id-badge',
    },
    'Last name (at birth)': {
        pathName: 'last_name_birth',
        category: 'name',
        defaultValue: null,
        icon: 'id-badge',
    },

    'Address 1': {
        pathName: 'address_1',
        category: 'address',
        defaultValue: null,
        icon: 'house',
    },
    'Address 2': {
        pathName: 'address_2',
        category: 'address',
        defaultValue: null,
        icon: 'house',
    },
    City: {
        pathName: 'city',
        category: 'address',
        defaultValue: null,
        icon: 'house',
    },
    State: {
        pathName: 'state',
        category: 'address',
        defaultValue: null,
        icon: 'house',
    },
    Zipcode: {
        pathName: 'zipcode',
        category: 'address',
        defaultValue: null,
        icon: 'house',
    },
    Country: {
        pathName: 'country',
        category: 'address',
        defaultValue: null,
        icon: 'house',
    },

    'Primary phone': {
        pathName: 'primary_phone',
        category: 'contact',
        defaultValue: null,
        icon: 'mobile-alt',
        dataType: 'tel',
        dataFormat: 'phone',
    },
    'Home phone': {
        pathName: 'home_phone',
        category: 'contact',
        defaultValue: null,
        icon: 'house',
        dataType: 'tel',
        dataFormat: 'phone',
    },
    'Work phone': {
        pathName: 'work_phone',
        category: 'contact',
        defaultValue: null,
        icon: 'building',
        dataType: 'tel',
        dataFormat: 'phone',
    },

    'Primary email': {
        pathName: 'primary_email',
        category: 'contact',
        defaultValue: null,
        icon: 'envelope',
        dataType: 'email',
        dataFormat: 'email',
    },
    'Work email': {
        pathName: 'work_email',
        category: 'contact',
        defaultValue: null,
        icon: 'building',
        dataType: 'email',
        dataFormat: 'email',
    },

    'Birth month': {
        pathName: 'birth_month',
        category: 'birthday',
        defaultValue: null,
        icon: 'birthday-cake',
        dataType: 'select',
        dataFormat: 'text',
        selectOptions: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
    },
    'Birth date': {
        pathName: 'birth_date',
        category: 'birthday',
        defaultValue: null,
        icon: 'birthday-cake',
        dataType: 'number',
        dataFormat: 'text',
    },
    'Birth year': {
        pathName: 'birth_year',
        category: 'birthday',
        defaultValue: null,
        icon: 'birthday-cake',
        dataType: 'text',
        dataFormat: 'text',
    },

    'Death month': {
        pathName: 'death_month',
        category: 'deathdate',
        defaultValue: null,
        icon: 'tombstone',
        dataType: 'select',
        dataFormat: 'text',
        selectOptions: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
    },
    'Death day': {
        pathName: 'death_date',
        category: 'deathdate',
        defaultValue: null,
        icon: 'tombstone',
        dataType: 'text',
        dataFormat: 'text',
    },
    'Death year': {
        pathName: 'death_year',
        category: 'deathdate',
        defaultValue: null,
        icon: 'tombstone',
        dataType: 'text',
        dataFormat: 'text',
    },

    'Personal website': {
        pathName: 'website',
        category: 'other',
        defaultValue: null,
        icon: 'link',
        dataFormat: 'url',
    },
    Occupation: {
        pathName: 'occupation',
        category: 'other',
        defaultValue: null,
        icon: 'briefcase',
    },

    Gender: {
        pathName: 'gender',
        category: 'other',
        defaultValue: null,
        icon: 'transgender-alt',
        dataType: 'select',
        selectOptions: ['Male', 'Female', 'Other', 'Undefined'],
    },
}

export const PROFILE_DETAILS = {
    'Profile photo': {
        pathName: 'profile_photo',
        category: 'name',
        icon: 'image',
        defaultValue: null,
        dataType: 'file',
    },
    'Display name': {
        pathName: 'display_name',
        category: 'name',
        defaultValue: null,
        icon: 'id-badge',
        message: 'This could be your first name or nickname.',
    },
}

export const LEAF_SPECIFIC_DETAILS = {
    Relationships: {
        pathName: 'relationships',
        category: null,
        defaultValue: {
            parents: [],
            children: [],
            siblings: [],
            partners: [],
        },
    },
    'Created by': {
        pathName: 'created_by',
        category: 'name',
        defaultValue: null,
    },
}

export const MEMBER_SPECIFIC_DETAILS = {
    'Account email': {
        pathName: 'account_email',
        category: null,
        defaultValue: null,
    },
}

export const META_DETAILS = {
    'Claimed by': {
        pathName: 'claimed_by',
        category: 'name',
        defaultValue: null,
    },
    'Completed new member walkthrough ': {
        pathName: 'completed_nm_walkthrough',
        category: null,
        defaultValue: false,
    },
}

export const MEMBER_BLUEPRINT = {
    ...MEMBER_SPECIFIC_DETAILS,
    ...PROFILE_DETAILS,
    ...PRIVATE_PROFILE_DETAILS,
    ...META_DETAILS,
}

export const LEAF_BLUEPRINT = {
    ...LEAF_SPECIFIC_DETAILS,
    ...PROFILE_DETAILS,
    ...PRIVATE_PROFILE_DETAILS,
    ...META_DETAILS,
}

export const DETAIL_METADATA = [
    'id',
    'claimed_by',
    'created_by',
    'preferences',
    'profile_photo',
    'completed_nm_walkthrough',
    'relationships',
    'account_email',
]

export const PLACEHOLDER_IMG =
    '../../../assets/images/profile_placeholder_yellow.svg'

export const TOAST_TIMEOUT = 3000
