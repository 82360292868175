import React, { useContext } from 'react'
import MemberContext from '../contexts/MemberContext'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import Header from '../components/atoms/Header'
import Button from '../components/atoms/Button'
import LinkButton from '../components/atoms/LinkButton'
import useModal from '../hooks/useModal'
import NewTreeModal from '../components/general/NewTreeModal'
import Dropdown from '../components/organisms/Dropdown'
import EmailVerificationModal from '../components/general/EmailVerificationModal'
import PromptDisplayNameModal from '../components/general/PromptDisplayNameModal'
import Icon from '../components/atoms/Icon'
import useTrees from '../hooks/useTrees'
import useDeleteTree from '../hooks/useDeleteTree'

import ButtonGroup from '../components/organisms/ButtonGroup'
import useNotifcation from '../hooks/useNotification'

export const pageContainerStyles = css`
    // padding: 64px clamp(24px, 2.5vw, 80px) ;
`

const StyledContainer = styled.div`
    // max-width: 800px;
    margin: 0 auto;
    padding: 48px clamp(24px, 100%, 80px);
`

const StyledHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 64px;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 24px;
`

const StyledNewButton = styled(Button)`
    margin-left: auto;
`

const StyledTreeList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`

const StyledLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: var(--color_text);
    text-decoration: none;
    border-radius: 16px;
    background-color: var(--color_background_depth_2);

    // &:hover {
    //     box-shadow: 0 0 0 2px var(--color_15_dark);
    // }

    // &:active {
    //     background-color: var(--activeBackgroundColor);
    // }
`

const StyledTreeLink = styled.div`
    --color_anchor: var(--color_text);
    display: flex;
    flex: 1;
    height: 100%;
    padding: 32px;
    text-decoration: none;
    justify-content: space-between;
`

const StyledEmptyMessage = styled.p`
    padding: 32px 16px;
    margin: 16px auto;
    border-radius: 8px;
    color: var(--color_text_700);
    font-size: 18px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%:
`

const StyledLoadingMessage = styled.div`
    padding: 32px;
    border-radius: 8px;
    text-align: center;
    // background-color: var(--color_background_depth_4);
`

const StyledIconButton = styled(Icon)`
    margin-left: auto;
    margin-right: 32px;
    pointer-events: none;
    color: var(--color_text_700);
`

const StyledEmptyNewButton = styled(Button)`
    margin-top: 24px;
`

const StyledTreeAvatar = styled.div`
    --treeItemAvatarSize: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 80px;
    width: var(--treeItemAvatarSize);
    height: var(--treeItemAvatarSize);
    background-color: var(--brand_100);
    color: var(--color_text_300);
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 12px;
`

const StyledTreeNamePermissionContainer = styled.div`
    // display: grid;
    // gap: 0px;
`

const StyledTreeName = styled.div`
    margin-top: 0;
    font-size: 22px;
    font-size: 20px;
    font-weight: bold;
`

const StyledPermissionLabel = styled.div`
    text-transform: capitalize;
    margin-right: 8px;
    color: var(--color_text_500);
    font-size: 14px;
`

const StyledFlex = styled.div`
    display: flex;
    color: var(--color_text_500);
    font-size: 14px;
`

const StyledNotificationContainer = styled.div`
    margin-bottom: 40px;
    padding: 40px;
    border-bottom: 1px solid var(--color_border);
`

const StyledNotificationItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    background-color: var(--color_background_depth_4);
    border-radius: 8px;
`

export const TreesPage = () => {
    const { memberData } = useContext(MemberContext)
    const [toggleNewTreeModal, isNewTreeModalShowing] = useModal(false)
    const { trees, loading } = useTrees()
    const { deleteTree } = useDeleteTree()
    const {
        notifications,
        acceptNotification,
        declineNotifcation,
    } = useNotifcation()

    const getMemberPermission = (treeDoc, memberId) => {
        let newVersion = true

        if (newVersion) {
            return treeDoc.member_permissions.map((doc) => {
                return doc.id === memberId ? doc.permission : null
            })
        } else {
            let memberPermission = Object.entries(treeDoc.members).find(
                ([member, perm]) => member === memberId
            )

            return memberPermission ? memberPermission[1] : null
        }
    }

    return (
        <>
            <EmailVerificationModal />
            {memberData && <PromptDisplayNameModal />}
            <StyledContainer>
                {notifications.length > 0 && (
                    <StyledNotificationContainer>
                        {notifications.map((notification) => (
                            <StyledNotificationItem>
                                <p>
                                    <Icon className="u-mar-r_2" icon="bell" />
                                    {`${notification.from_member_name} invited you to join ${notification.for_tree_name}`}
                                </p>
                                <ButtonGroup>
                                    <Button
                                        whiteTransparent={true}
                                        onClick={() =>
                                            declineNotifcation(notification.id)
                                        }
                                        size="s"
                                    >
                                        Decline
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            acceptNotification(notification.id)
                                        }
                                        size="s"
                                    >
                                        Accept
                                    </Button>
                                </ButtonGroup>
                            </StyledNotificationItem>
                        ))}
                    </StyledNotificationContainer>
                )}
                <StyledHeaderContainer>
                    <Header size="l">Family trees</Header>
                    {trees.length > 0 && (
                        <StyledNewButton
                            iconLeft="plus"
                            onClick={() => toggleNewTreeModal()}
                        >
                            New
                        </StyledNewButton>
                    )}
                </StyledHeaderContainer>
                {trees.length === 0 && !loading && (
                    <StyledEmptyMessage>
                        Create your first family tree!
                        <StyledEmptyNewButton
                            onClick={() => toggleNewTreeModal()}
                            iconLeft="plus"
                        >
                            Create a tree
                        </StyledEmptyNewButton>
                    </StyledEmptyMessage>
                )}
                {loading && (
                    <StyledLoadingMessage>
                        Loading trees...
                    </StyledLoadingMessage>
                )}
                <StyledTreeList>
                    {trees.map((tree) => (
                        <StyledLinkContainer key={tree.id}>
                            <StyledTreeLink>
                                {/* <StyledTreeAvatar>
                                    {tree?.name.charAt(0)}
                                </StyledTreeAvatar> */}
                                <StyledTreeNamePermissionContainer>
                                    <StyledTreeName size="m">
                                        {tree?.name || 'Unnamed tree'}
                                    </StyledTreeName>
                                    {/* <StyledPermissionLabel>
                                        {getMemberPermission(
                                            tree,
                                            memberData.id
                                        )}
                                    </StyledPermissionLabel> */}
                                    <StyledFlex>
                                        <div>
                                            {tree.members.length === 1
                                                ? `${tree.members.length} member`
                                                : `${tree.members.length} members`}
                                        </div>
                                    </StyledFlex>
                                </StyledTreeNamePermissionContainer>
                                <ButtonGroup>
                                    <Dropdown
                                        trigger={
                                            <Button
                                                whiteTransparent={true}
                                                iconRight="caret-down"
                                                iconRightType="solid"
                                            >
                                                More
                                            </Button>
                                        }
                                    >
                                        <Dropdown.Item
                                            onClick={() => deleteTree(tree.id)}
                                            danger={true}
                                        >
                                            Delete tree
                                        </Dropdown.Item>
                                    </Dropdown>

                                    <LinkButton to={`/trees/${tree.id}`}>
                                        View
                                    </LinkButton>
                                </ButtonGroup>
                            </StyledTreeLink>
                            {/* <Dropdown
                                trigger={
                                    <StyledIconButton
                                        icon="arrow-right"
                                        whiteTransparent={true}
                                    />
                                }
                            >
                                <Dropdown.Item iconLeft="i-cursor">
                                    Rename
                                </Dropdown.Item>
                                <Dropdown.Item iconLeft="door-open" danger>
                                    Leave
                                </Dropdown.Item>
                                <Dropdown.Item iconLeft="trash-alt" danger>
                                    Delete
                                </Dropdown.Item>
                            </Dropdown> */}
                        </StyledLinkContainer>
                    ))}
                </StyledTreeList>
            </StyledContainer>
            <NewTreeModal
                isModalShowing={isNewTreeModalShowing}
                hide={toggleNewTreeModal}
            />
        </>
    )
}

export default TreesPage
