import React, { useContext, useState, useEffect, useRef } from 'react'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import LeavesContext from '../../contexts/LeavesContext'
import styled, { css } from 'styled-components'
import { getLeafImage } from '../../assets/js/utils'
import Icon from './Icon'
import useTreeMembers from '../../hooks/useTreeMembers'
import IconButton from './IconButton'
import AddRelationship from '../organisms/AddRelationships'
import useCurrentMemberPermission from '../../hooks/useCurrentMemberPermission'
import { LeafImagesContext } from '../../contexts/LeafImageContext'

const StyledAddRelative = styled(IconButton)`
    position: absolute;
    top: -16px;
    right: -16px;
    opacity: 0;
    transform: scale(0.65);
    z-index: calc(var(--baseZIndex) + 2);
    transition: transform 0.2s ease-out;
`

const StyledDeceasedIcon = styled(Icon)`
    color: var(--color_text_900);
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 22px;
    height: 22px;
    border-radius: 160px;
    background-color: var(--color_background_depth_1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
`

const StyledLeafImage = styled.div`
    background-color: var(--color_background_depth_2);
    width: var(--leafSize);
    height: var(--leafSize);
    border-radius: var(--leafSize);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${(props) => props.src && `${props.src}`});
    -webkit-touch-callout: none; /* iOS Safari */
    user-select: none;
    position: relative;

    ${(props) =>
        props.isMember &&
        css`
            border-radius: 25%;
        `}
`

const StyledFigure = styled.figure`
    --calculatedWidth: calc(var(--leafSize) + 16px);
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    flex-direction: column;
    width: var(--calculatedWidth);
    max-width: var(--calculatedWidth);
    z-index: calc(var(--baseZIndex) + 1);

    .leaf__connection {
        z-index: var(--baseZIndex);
    }

    &:focus {
        outline: none;
    }

    &:hover {
        .leaf_connections {
            path {
                transition: all 0.1s ease-in-out;
                stroke: var(--blue_200);
                stroke-width: 4;
            }
        }
    }
`

const StyledLeaf = styled.div`
    --leafSize: 72px;
    --baseZIndex: 5;
    position: relative;
    margin: 8px 12px;
    margin-bottom: 32px;
    opacity: 1;
    cursor: pointer;
    background-color: var(--color_background_depth_2);
    border-radius: 8px;
    // box-shadow: 0 0 0 0 var(--color_border_200);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 64px #eaeaea; // light

    ${(props) =>
        props.theme.mode === 'dark' &&
        css`
            box-shadow: 0 2px 64px #292929;
        `}

    --highlightShadow: 0 0 0 4px var(--color_10_light);

    &:focus {
        outline: none;
        box-shadow: var(--highlightShadow);
    }

    &.highlight {
        box-shadow: var(--highlightShadow);
    }

    &:hover,
    &:focus,
    &:focus-within {
        box-shadow: var(--highlightShadow);

        ${StyledAddRelative} {
            opacity: 1;
            transform: scale(1);
        }
    }

    &:active {
        box-shadow: 0 0 0 5px var(--color_10_dark);
    }

    &.active {
        background-color: var(--color_10_light);
        color: white;

        ${StyledDeceasedIcon} {
            background-color: var(--color_10_light);
        }
    }

    // > * {
    //     pointer-events: none;
    // }

    &.removing {
        filter: grayscale(1);
        transform: scale(0);
        opacity: 0;
        transition: all 0.2s ease-out;
    }

    &.adding {
        // transform-origin: top center;
        // transform: scale(.5);
        opacity: 0;
        transition: opacity 0.4s ease-out;
    }

    polyline,
    path {
        fill: none;
        stroke: var(--color_border_300);
        stroke-width: 2;
    }

    .leaf_connections {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: visible;
        z-index: -1;

        &.highlight {
            path {
                transition: all 0.1s ease-in-out;
                stroke: var(--blue_200);
                stroke-width: 4;
            }

            & ~ .leaf_connections {
                z-index: -5;
            }
        }

        &.removing {
            stroke-dasharray: 400;
            stroke-dashoffset: 400;
            transition: all 0.2s ease-in-out;
        }
    }

    .partners .leaf svg {
        z-index: -2;
    }
`

const StyledFigcaption = styled.figcaption`
    font-size: 14px;
    // font-weight: bold;
    padding-top: 8px;
    max-width: calc(100% - 8px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:empty:before {
        content: 'No name';
        font-style: italic;
        opacity: 0.75;
    }
`

export const Leaf = ({ leafId, name, toggleProfileModal }) => {
    const { panelState, setPanelState } = useContext(DetailsPanelContext)
    const { leafImages, getImageUrlById } = useContext(LeafImagesContext)
    const { leaves } = useContext(LeavesContext)
    const [deceased, setDeceased] = useState(false)
    // const [position, setPosition] = useState()
    // const [isContextMenuVisible, setShowContextMenu] = useState(false)
    // const leafRef = useRef()
    const { treeMembers, getClaimedBy } = useTreeMembers()
    const [
        isAdmin,
        isContributor,
        isViewer,
        memberPermission,
    ] = useCurrentMemberPermission()

    const leafDoc = leaves.find((doc) => doc.id === leafId)
    let docData = leafDoc

    const [leafProfilePhoto, setLeafProfilePhoto] = useState(getLeafImage())
    let claimedDocData

    if (docData?.claimed_by) {
        claimedDocData = getClaimedBy(docData.claimed_by)
        docData = claimedDocData || docData
        name = docData?.display_name
    }

    useEffect(() => {
        let data = claimedDocData || docData
        if (data?.death_date || data?.death_month || data?.death_year) {
            setDeceased(true)
        }
    }, [docData, claimedDocData])

    useEffect(() => {
        if (leafImages.length > 0) {
            setLeafProfilePhoto(getImageUrlById(docData?.id))
        }
    }, [leafImages, panelState])

    const handleActiveLeaf = (leafId, dblClick = false) => {
        let leafDoc = leaves.find((leaf) => leaf.id === leafId)
        let memberDoc = leafDoc?.claimed_by && getClaimedBy(leafDoc.claimed_by)

        setPanelState({
            ...panelState,
            leafId: leafId,
            leafDoc: leafDoc,
            linkedMemberDoc: memberDoc,
        })

        if (dblClick) {
            toggleProfileModal()
        }
    }

    return (
        <>
            <StyledLeaf
                className={`leaf ${panelState.leafId === leafId && 'active'}`}
                deceased={deceased}
                data-id={leafId}
            >
                {(isAdmin || isContributor) && (
                    <AddRelationship
                        tabIndex="0"
                        forceDoc={leafDoc}
                        trigger={
                            <StyledAddRelative
                                icon="plus"
                                size="m"
                                circle={true}
                            />
                        }
                    />
                )}
                <StyledFigure
                    tabIndex="0"
                    onClick={() => handleActiveLeaf(leafId)}
                >
                    {docData && (
                        <StyledLeafImage
                            alt={`Profile image for ${name}`}
                            src={leafProfilePhoto}
                            className="leaf__image"
                            isMember={claimedDocData}
                        >
                            {deceased && (
                                <StyledDeceasedIcon
                                    iconType="regular"
                                    icon="tombstone"
                                />
                            )}
                        </StyledLeafImage>
                    )}
                    <StyledFigcaption className="leaf__caption">
                        {name}
                    </StyledFigcaption>
                </StyledFigure>
            </StyledLeaf>
        </>
    )
}

export default Leaf
