import React, { useEffect, useContext, useState, useRef } from 'react'
import Button from '../atoms/Button'
import AccountDropdown from '../organisms/AccountDropdown'
import Icon from '../atoms/Icon'
import styled, { css } from 'styled-components'
import ButtonGroup from '../organisms/ButtonGroup'
import Header from '../atoms/Header'
import InviteModal from './InviteModal'
import useModal from '../../hooks/useModal'
import MembersModal from '../organisms/MembersModal'
import { LinkButton } from '../atoms/LinkButton'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import Dropdown from '../organisms/Dropdown'
import ViewPreferenceContext from '../../contexts/ViewPreferenceContext'
import { Link } from 'react-router-dom'
import SideMenuTrigger from './SideMenuTrigger'
import LeavesContext from '../../contexts/LeavesContext'
import Input from '../atoms/Input'
import useOnClickOutside from '../../hooks/useOnOutsideClick'
import toaster from 'toasted-notes'
import firebase from 'firebase/app'
import useCurrentMemberPermission from '../../hooks/useCurrentMemberPermission'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useCollection } from 'react-firebase-hooks/firestore'
import IconButton from '../atoms/IconButton'

const StyledHomeButton = styled(IconButton)`
    font-size: 18px;
    padding: 8px 12px;
    // background-color: var(--brand);
    font-size: 18px;
    border-radius: 80px;
    margin-right: 16px;
    position: relative;
    color: white;

    &:hover {
        background-color: var(--color_10_light);
    }

    &:active {
        background-color: var(--activeBackgroundColor);
    }
`

const StyledContainer = styled.div`
    // height: var(--treeNavHeight);
    padding: 8px 8px 8px 16px;
    display: flex;
    align-items: center;
    flex-wrap: no-wrap;
    // border-bottom: 1px solid var(--color_border_200);
    background-color: var(--color_15_light);
    color: white;

    ${(props) =>
        props.white &&
        `
        color: var(--color_text);
        background-color: var(--color_background_depth_2);

        ${StyledHomeButton} {
            color: var(--color_accent);
        }
    `}
`

const StyledTreeToolbar = styled.div`
    display: flex;
    flex-direction: column;
`
const StyledHeader = styled(Header)`
    font-size: 22px;
    margin-bottom: 0;
    margin-top: 0;
    max-width: 25ch;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 4px;
`

const StyledTreeHeader = styled.div`
    display: flex;
    align-items: center;
`

const StyledTreeDetails = styled.div`
    display: flex;
    font-size: 12px;
    color: var(--color_text_600);
    margin-top: -4px;
`

const StyledLogo = styled(Link)`
    // background-color: var(--brand);
    font-size: 22px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-family: var(--fontFamily_header);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: var(--color_text);
    height: calc(var(--navHeight) - 32px);
    text-decoration: none;
    border-radius: 8px;
    margin-right: 24px;
    color: white;
`

const StyledJoinButton = styled(Button)`
    // margin-left: 16px;
`

const StyledVerticalDivider = styled.div`
    width: 1px;
    height: 24px;
    // min-height: calc(100% - 16px);
    margin: 8px 4px;
    background-color: var(--color_border_200);
`

const StyledTreeDetailItem = styled.div`
    margin-right: 6px;

    ${(props) =>
        props.link &&
        css`
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        `}
`

const StyledTreeButtonGroup = styled(ButtonGroup)`
    margin-left: auto;
`

const StyledPrivacyContainer = styled.div`
    display: grid;
    gap: 8px;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
`

const StyledPrivacyIcon = styled(Icon)`
    margin-right: 16px;
    margin-top: 4px;
    color: var(--brand_900);

    ${(props) =>
        props.selected === true &&
        css`
            color: white;
        `}
`

const StyledPrivacyDescription = styled.div`
    font-size: 13px;
    color: var(--color_text_600);
    margin-top: 0px;
`

const StyledPrivacyOption = styled.div`
    display: flex;
    padding: 16px;
    border-radius: 8px;

    ${(props) =>
        props.selected === true &&
        css`
            // box-shadow: 0 0 0 4px var(--color_accent) inset;
            pointer-events: none;
            background-color: var(--color_10_dark);
            color: white;

            ${StyledPrivacyDescription} {
                color: rgba(255, 255, 255, 0.5);
            }
        `}

    &:hover {
        cursor: pointer;
        background-color: var(--color_10_light);
        color: white;

        ${StyledPrivacyIcon} {
            color: white;
        }

        ${StyledPrivacyDescription} {
            color: rgba(255, 255, 255, 0.5);
        }
    }

    &:active {
        background-color: var(--color_10_dark);
    }
`

export const TreeNavBar = () => {
    const { leaves } = useContext(LeavesContext)
    const { panelState, setPanelState } = useContext(DetailsPanelContext)
    const { viewPreference, setViewPreference } = useContext(
        ViewPreferenceContext
    )
    const [treeName, setTreeName] = useState('')
    const [newTreeName, setNewTreeName] = useState('')
    const [isTreeNameEditable, setTreeNameEditable] = useState(false)
    const [
        isAdmin,
        isContributor,
        isViewer,
        memberPermission,
    ] = useCurrentMemberPermission([])
    const [user] = useAuthState(firebase.auth())
    const treeInputRef = useRef()

    const [toggleInviteMemberModal, isInviteMemberModalShowing] = useModal(
        false
    )
    const [toggleViewMembersModal, isViewMembersModalShowing] = useModal(false)

    const PrivacyOption = ({ title, description, icon, selected, ...rest }) => {
        return (
            <StyledPrivacyOption selected={selected} {...rest}>
                <StyledPrivacyIcon
                    iconType="regular"
                    icon={icon}
                    selected={selected}
                />
                <div>
                    {title}
                    <StyledPrivacyDescription selected={selected}>
                        {description}
                    </StyledPrivacyDescription>
                </div>
            </StyledPrivacyOption>
        )
    }

    const focusOnInput = () => {
        treeInputRef.current.focus()
    }

    const toggleViewPreference = () => {
        if (viewPreference.layout === 'tree') {
            setViewPreference({ layout: 'table' })
        } else {
            setViewPreference({ layout: 'tree' })
        }
    }

    const handleInputChange = () => {
        const treeRef = firebase
            .firestore()
            .collection('trees')
            .doc(panelState?.treeId)

        if (newTreeName.length > 0) {
            if (treeName !== newTreeName) {
                treeRef
                    .update({
                        name: newTreeName,
                    })
                    .then(() => {
                        setTreeName(newTreeName)
                        toaster.notify(`Tree name changed!`)
                    })
                    .catch(() => {
                        toaster.notify(`Error updated tree name`)
                        setTreeName(treeName)
                    })
            }
        } else {
            alert("Tree name can't be blank")
        }
        setTreeNameEditable(false)
    }

    useOnClickOutside(treeInputRef, (e) => handleInputChange())

    useEffect(() => {
        isTreeNameEditable && focusOnInput()
    }, [isTreeNameEditable])

    useEffect(() => {
        if (panelState.treeDoc) {
            setTreeName(panelState.treeDoc.name)
            setNewTreeName(panelState.treeDoc.name)
        }
    }, [panelState.treeDoc])

    return (
        <StyledContainer white={false}>
            {user && (
                <StyledHomeButton
                    whiteTransparent
                    // href="/trees"
                    // icon="arrow-left"
                    // whiteTransparent={true}
                    // circle={true}
                    icon="bars"
                    onClick={() =>
                        setPanelState({
                            ...panelState,
                            showSideMenu: !panelState.showSideMenu,
                        })
                    }
                    // data-tooltip="Menu"
                    // data-tooltip-position="right"
                >
                    {/* <Icon icon="bars" /> */}
                </StyledHomeButton>
            )}
            {!user && (
                <StyledLogo to={user ? '/dashboard' : '/'}>Mily</StyledLogo>
            )}
            <StyledTreeToolbar>
                {!isTreeNameEditable && (
                    <StyledTreeHeader>
                        <StyledHeader
                            onClick={() => isAdmin && setTreeNameEditable(true)}
                            as="h1"
                            size="m"
                        >
                            {treeName}
                        </StyledHeader>
                        {/* <SideMenuTrigger/> */}
                    </StyledTreeHeader>
                )}
                {isTreeNameEditable && (
                    <Input
                        placeholder="Enter a tree name"
                        size="m"
                        value={newTreeName}
                        ref={treeInputRef}
                        onKeyPress={(e) =>
                            e.key === 'Enter' && handleInputChange()
                        }
                        onChange={(e) => setNewTreeName(e.target.value)}
                    />
                )}
            </StyledTreeToolbar>
            <StyledTreeButtonGroup>
                {memberPermission && (
                    <Button
                        whiteTransparent={true}
                        whiteText={true}
                        outline={true}
                        iconLeft="users"
                        onClick={() => toggleViewMembersModal()}
                    >
                        Members
                    </Button>
                )}
                {isAdmin && (
                    <Button
                        fontSize="16px"
                        icon="envelope"
                        label="Invite"
                        onClick={toggleInviteMemberModal}
                        alwaysShowLabel={true}
                        solid={true}
                        iconLeft="envelope"
                    >
                        Invite people
                    </Button>
                )}
                {memberPermission && <SideMenuTrigger />}
                {!user && (
                    <LinkButton
                        to="/sign-up"
                        whiteTransparent={true}
                        whiteText={true}
                        className="u-mar-l_auto"
                    >
                        Create account
                    </LinkButton>
                )}
                {!user && (
                    <LinkButton
                        to="/sign-in"
                        whiteTransparent={true}
                        whiteText={true}
                        className="u-mar-r_1"
                    >
                        Sign in
                    </LinkButton>
                )}
            </StyledTreeButtonGroup>
            {user && <AccountDropdown />}
            {isAdmin && (
                <InviteModal
                    isModalShowing={isInviteMemberModalShowing}
                    hide={toggleInviteMemberModal}
                />
            )}
            {memberPermission && (
                <MembersModal
                    isModalShowing={isViewMembersModalShowing}
                    hide={toggleViewMembersModal}
                />
            )}
        </StyledContainer>
    )
}

export default TreeNavBar
