import React, { useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import styled from 'styled-components'
import Button from '../components/atoms/Button'
import useInvitationParams from '../hooks/useInvitationParams'
import Header from '../components/atoms/Header'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Redirect } from 'react-router'
import InputGroup from '../components/organisms/InputGroup'
import { Link } from 'react-router-dom'

const StyledWrapper = styled.div`
    background-color: var(--color_15_dark);
    padding: 48px;
    min-height: 100vh;
`

const StyledSignInContainer = styled.div`
    margin: 0 auto;
    color: white;
`

const StyledErrorMessage = styled.p`
    max-width: 280px;
    width: 100%;
    text-align: center;
    color: var(--color_danger);
    padding: 16px;
    border-radius: 8px;
    background-color: var(--color_background_depth_2);
`

const StyledForm = styled.form`
    max-width: 280px;
`

const StyledHeader = styled(Header)`
    margin-bottom: 16px;
`

const StyledSignInButton = styled(Button)`
    margin-top: 24px;
`

const StyledLink = styled(Link)`
    color: white;
`

export const SignIn = (props) => {
    const { invitation } = useInvitationParams()
    const [email, setEmail] = useState(invitation?.for_email || '')
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState(null)
    const [user, loading, error] = useAuthState(firebase.auth())

    const handleEmailInput = (e) => {
        setEmail(e.target.value)
    }

    const handlePasswordInput = (e) => {
        setPassword(e.target.value)
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault()

        if (email) {
            const userEmail = invitation?.for_email || email

            firebase
                .auth()
                .signInWithEmailAndPassword(userEmail, password)
                .then(() => {
                    setErrorMessage(null)
                    window.history.pushState(
                        null,
                        null,
                        invitation ? `/trees/${invitation.for_tree}` : '/trees'
                    )
                    window.location.reload()
                })
                .catch((err) => {
                    setErrorMessage(err.message)
                })
        } else {
            setErrorMessage('Please provide an email')
        }
    }

    if (!loading && user) {
        return <Redirect to="/trees" />
    } else {
        return (
            <StyledWrapper>
                <StyledSignInContainer>
                    <StyledForm
                        onSubmit={handleOnSubmit}
                        className="u-mar-lr_auto u-ta_center u-mar-t_8"
                    >
                        <StyledHeader size="l">
                            {invitation
                                ? `Sign in view the invitation`
                                : `Sign in`}
                        </StyledHeader>
                        <InputGroup
                            special={true}
                            key="email"
                            autocomplete="email"
                            className="u-mar-b_1"
                            iconLeft="envelope"
                            name="email"
                            type="email"
                            label="Email"
                            required
                            value={invitation?.for_email || email}
                            readOnly={invitation}
                            onChange={handleEmailInput}
                        />
                        <InputGroup
                            key="password"
                            autocomplete="current-password"
                            className="u-mar-b_2"
                            iconLeft="key"
                            name="password"
                            label="Password"
                            type="password"
                            showPasswordOption={true}
                            required
                            value={password}
                            onChange={handlePasswordInput}
                        />
                        {errorMessage && (
                            <StyledErrorMessage>
                                {errorMessage}
                            </StyledErrorMessage>
                        )}
                        <StyledSignInButton className="u-w_full">
                            Sign in
                        </StyledSignInButton>
                        <p>
                            Don't have an account?{' '}
                            <StyledLink to="/sign-up">Create one.</StyledLink>
                        </p>
                    </StyledForm>
                </StyledSignInContainer>
            </StyledWrapper>
        )
    }
}

export default SignIn
