import React, { useState, useContext, useRef, useEffect } from 'react'
import Button from '../atoms/Button'
import Modal from '../organisms/Modal'
import Card from '../organisms/Card'
import useModal from '../../hooks/useModal'
import Input from '../atoms/Input'
import ButtonGroup from '../organisms/ButtonGroup'
import styled, { css } from 'styled-components'
import LinkToleafModal from '../organisms/LinkToLeafModal'
import { inputStyle } from '../../globalStyles'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import MemberContext from '../../contexts/MemberContext'
import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/auth'
import IconButton from '../atoms/IconButton'
import Header from '../atoms/Header'
import toaster from 'toasted-notes'
import TreeInvitationsContextProvider from '../../contexts/TreeInvitationsContext'
import { CurrentTreeMembersContext } from '../../contexts/CurrentTreeMembersContext'
import Dropdown from '../organisms/Dropdown'

const StyledInviteContainer = styled.div`
    display: flex;
    margin-bottom: unset;
`
const StyledMessage = styled.p`
    font-size: 15px;
    text-align: center;
    max-width: 75%;
    margin-left auto;
    margin-right: auto;
    margin-top: 24px;
    margin-bottom: 0;

    ${(props) =>
        props.error &&
        css`
            color: var(--color_danger);
        `}
`
const StyledIconButton = styled(IconButton)`
    margin-right: 8px;
    font-size: 14px;
`

const StyledTitle = styled(Header)`
    margin-bottom: 0;
`

const StyledP = styled.p`
    margin-top: 4px;
    margin-bottom: 32px;
    font-size: 15px;
`

const StyledInviteInputContainer = styled.div`
    ${inputStyle};
    padding: 0px;
    display: flex;
    align-items: center;
    font-size: 18px;

    &:focus-within {
        outline: 0px solid transparent;
        box-shadow: 0 0 0 2px var(--color_outline);
    }
`

const StyledPermissionDropdownButton = styled(Button)`
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: auto;
`

const StyledInviteInput = styled(Input)`
    &[type='email'] {
        ${inputStyle}
        box-shadow: none;
        flex: 1;
        margin-right: 8px;
        height: 100%;

        &:focus,
        &:hover {
            box-shadow: none;
        }
    }
`

export const InviteModal = ({ hide, isModalShowing, ...rest }) => {
    const [toggleLinkToLeafModal, isLinkToLeafModalShowing] = useModal(false)
    const [permissionInput, setPermissionInput] = useState('Viewer')
    const [email, setEmail] = useState('')
    const [leafToLink, setLeafToLink] = useState(null)
    const { panelState } = useContext(DetailsPanelContext)
    const { memberData } = useContext(MemberContext)
    const [togglePermissionsModal, isPermissionsModalShowing] = useModal(false)
    const { currentTreeMembers } = useContext(CurrentTreeMembersContext)
    const [emailExists, setEmailExists] = useState(false)
    const emailInput = useRef()

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
        const matchedMemberDoc = currentTreeMembers.find(
            (memberDoc) => memberDoc.account_email === e.target.value
        )
        matchedMemberDoc && setEmailExists(true)
        !matchedMemberDoc && setEmailExists(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!emailExists) {
            const notificationsRef = firebase
                .firestore()
                .collection('notifications')
            const memberRef = firebase.firestore().collection('members')
            const treeDoc = panelState.treeDoc
            const sendInvite = firebase.functions().httpsCallable('sendInvite')

            notificationsRef
                .add({
                    for_leaf: leafToLink,
                    for_tree: panelState.treeId,
                    for_email: email,
                    for_tree_name: treeDoc.name,
                    from_member: memberData.id,
                    from_member_name:
                        memberData.display_name || memberData.first_name,
                    permission_type: permissionInput,
                    status: 'pending',
                })
                .then((newNotificationRef) => {
                    console.log(
                        `new notification created ${newNotificationRef.id}`
                    )

                    memberRef
                        .where('account_email', '==', email)
                        .get()
                        .then((result) => {
                            // const for_email_has_account = result.empty ? false : true;
                            const origin = 'https://www.milyapp.com'
                            const url = encodeURI(
                                `${origin}/${
                                    result.empty ? 'sign-up' : 'sign-in'
                                }?invitation=${newNotificationRef.id}`
                            )
                            const link_label = `${
                                result.empty ? 'Create an account' : 'Sign in'
                            }`

                            sendInvite({
                                email: email,
                                tree_name: treeDoc.name,
                                from_member_name:
                                    memberData.display_name ||
                                    memberData.first_name,
                                invitation_url: url,
                                link_label: link_label,
                            }).then((result) => {
                                toaster.notify(`Invitation sent to ${email}`)
                            })
                        })
                })

            hide()
            setEmail('')
            setPermissionInput('Viewer')
            setLeafToLink(null)
            setEmailExists(false)
        }
    }

    const handleClose = (e) => {
        hide()
        setEmail('')
        setPermissionInput('Viewer')
        setLeafToLink(null)
        setEmailExists(false)
    }

    const handleSelectedLeaf = (leafId) => {
        setLeafToLink(leafId)
    }

    useEffect(() => {
        isModalShowing && emailInput.current.focus()
    }, [isModalShowing])

    // useEffect(() => {
    //     if (leafToLink) {
    //         let leafDoc = leavesData.find(leaf => leaf.id === leafToLink);
    //         setSelectedLeafDoc(leafDoc);
    //     }
    // }, [leafToLink])

    return (
        <>
            <TreeInvitationsContextProvider>
                <Modal
                    isShowing={isModalShowing}
                    hide={handleClose}
                    size="m"
                    closeButton={true}
                    {...rest}
                >
                    <Card.Container as="form" onSubmit={(e) => handleSubmit(e)}>
                        <Card.Header
                            title="Invite people"
                            titleAs="h2"
                            align="center"
                        />
                        <Card.Content maxHeight="45vh">
                            <StyledInviteInputContainer>
                                <StyledInviteInput
                                    removeBaseStyling={true}
                                    type="email"
                                    required
                                    ref={emailInput}
                                    value={email}
                                    placeholder="Enter an email address"
                                    onChange={handleEmailChange}
                                />
                                <Dropdown
                                    usedInModal={true}
                                    trigger={
                                        <StyledPermissionDropdownButton
                                            iconRight="chevron-down"
                                            size="m"
                                            type="button"
                                            whiteTransparent={true}
                                        >
                                            {permissionInput}
                                        </StyledPermissionDropdownButton>
                                    }
                                >
                                    <Dropdown.Item
                                        size="s"
                                        iconRight={
                                            permissionInput === 'Viewer' &&
                                            'check'
                                        }
                                        subtext="Can view the tree."
                                        onClick={() =>
                                            setPermissionInput('Viewer')
                                        }
                                    >
                                        Viewer
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        size="s"
                                        iconRight={
                                            permissionInput === 'Contributor' &&
                                            'check'
                                        }
                                        subtext="Can modify people they've added."
                                        onClick={() =>
                                            setPermissionInput('Contributor')
                                        }
                                    >
                                        Contributor
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        size="s"
                                        subtext="Can manage and modify all aspects of the tree."
                                        iconRight={
                                            permissionInput === 'Admin' &&
                                            'check'
                                        }
                                        onClick={() =>
                                            setPermissionInput('Admin')
                                        }
                                    >
                                        Admin
                                    </Dropdown.Item>
                                </Dropdown>
                                <StyledIconButton
                                    iconType="light"
                                    circle={true}
                                    transparent={true}
                                    type="button"
                                    icon="question-circle"
                                    size="m"
                                    onClick={() => togglePermissionsModal()}
                                />
                            </StyledInviteInputContainer>
                            <StyledInviteContainer>
                                {/* <StyledInput
                                    type="email"
                                    required
                                    value={email}
                                    placeholder="janesmith@gmail.com"
                                    onChange={handleEmailChange}
                                /> */}
                                {/* <StyledSelect
                                    value={permissionInput}
                                    onChange={(e) => setPermissionInput(e.target.value)
                                }>
                                    <option value="Viewer">Viewer</option>
                                    <option value="Contributor">Contributor</option>
                                    <option value="Admin">Admin</option>
                                </StyledSelect> */}
                                {/* <StyledIconButton
                                    iconType="light"
                                    circle={true}
                                    transparent={true}
                                    type="button"
                                    icon="question-circle"
                                    onClick={() => togglePermissionsModal()}
                                /> */}
                                <Modal
                                    isShowing={isPermissionsModalShowing}
                                    hide={togglePermissionsModal}
                                    size="s"
                                    zIndexOrder={2}
                                    closeButton={true}
                                >
                                    <Card.Container>
                                        <Card.Header title="Permissions" />
                                        <Card.Content>
                                            <ul>
                                                <li>
                                                    <StyledTitle size="s">
                                                        Viewer:
                                                    </StyledTitle>
                                                    <StyledP>
                                                        Can view people and
                                                        their details.
                                                    </StyledP>
                                                </li>
                                                <li>
                                                    <StyledTitle size="s">
                                                        Contributor:
                                                    </StyledTitle>
                                                    <StyledP>
                                                        Can add people.
                                                        Contributors can only
                                                        modify or delete the
                                                        people they have added.
                                                    </StyledP>
                                                </li>
                                                <li>
                                                    <StyledTitle size="s">
                                                        Admins:
                                                    </StyledTitle>
                                                    <StyledP>
                                                        Can manage and modify
                                                        anything on the tree.
                                                    </StyledP>
                                                </li>
                                                <li>
                                                    Note: Profiles cannot be
                                                    edited if they have been
                                                    claimed by a member.
                                                </li>
                                            </ul>
                                        </Card.Content>
                                    </Card.Container>
                                </Modal>
                            </StyledInviteContainer>
                            {emailExists && (
                                <StyledMessage
                                    error={true}
                                >{`${email} is already a member of this tree`}</StyledMessage>
                            )}
                            {/* <StyledHeaderDivider as="h6">More options</StyledHeaderDivider>
                            <StyledInfoBanner>
                                    <Icon size="18px" icon="info-circle"/>
                                    <StyledInfoText>Link a member to a leaf! Once accepted, the member's profile will display in place of the leaf.</StyledInfoText>
                                </StyledInfoBanner>
                            <StyledChooseLeafContainer>
                                {leafToLink && <Avatar size="xs" src={getLeafImage(selectedLeafDoc && selectedLeafDoc.id)}/>}
                                {leafToLink ? <span className="u-mar-l_2">{`${selectedLeafDoc && selectedLeafDoc.first_name}`}</span> : <span className="u-o_50 u-italic u-font-size_14 u-mar-l_1">Link to a leaf (optional)</span>}
                                <StyledActionsContainer>
                                    {leafToLink && <IconButton
                                            className="outline"
                                            icon="times"
                                            type="button"
                                            data-tooltip="Clear selection"
                                            data-tooltip-position="top middle"
                                            onClick={() => setLeafToLink(null)}
                                        />}
                                        <StyledChooseLeafIconButton
                                            className="outline"
                                            icon="pencil-alt"
                                            type="button"
                                            data-tooltip="Choose leaf"
                                            data-tooltip-position="top middle"
                                            onClick={toggleLinkToLeafModal}
                                        />
                                </StyledActionsContainer>
                            </StyledChooseLeafContainer> */}
                        </Card.Content>
                        <Card.Footer>
                            <ButtonGroup>
                                <Button
                                    type="button"
                                    onClick={handleClose}
                                    transparent={true}
                                >
                                    Cancel
                                </Button>
                                <Button iconLeft="paper-plane">
                                    Send invite
                                </Button>
                            </ButtonGroup>
                        </Card.Footer>
                    </Card.Container>
                </Modal>
                <LinkToleafModal
                    zIndexOrder={2}
                    currentLeaf={leafToLink || null}
                    isModalShowing={isLinkToLeafModalShowing}
                    hide={toggleLinkToLeafModal}
                    onSelectLeaf={handleSelectedLeaf}
                />
            </TreeInvitationsContextProvider>
        </>
    )
}

export default InviteModal
