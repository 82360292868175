import React, { useState, createContext } from 'react'

export const TreeInvitationsContext = createContext()

export const TreeInvitationsContextProvider = (props) => {
    const [treeInvitations, setTreeInvitations] = useState([])

    // useEffect(() => {
    //     if (panelState.treeId && panelState.treeId !== previousTreeId) {
    //         setPreviousTreeId(panelState.treeId);
    //         const notificationsRef = firebase.firestore().collection("notifications");
    //         const notificationsQuery = notificationsRef.where("for_tree", "==", panelState.treeId).where("status", "==", "pending");

    //         console.log(panelState.treeId);
    //         console.log(previousTreeId);

    //         // notificationsQuery.get()
    //         // .then((queryResults) => {
    //         //     if (!queryResults.empty) {
    //         //         for (const doc of queryResults.docs) {
    //         //             notificationItems.push(doc)
    //         //         }
    //         //         setItems(notificationItems);
    //         //     }
    //         // });

    //         // LEFT OFF trying to make an invitation context to check to see if an initee was already invted. This could also hold the snapshot for tree invitations. Consider unsubscribing callback like "onUnsubscribe"
    //         panelState.treeDoc && alert("tree changed");
    //     }
    // }, [panelState.treeId]);

    return (
        <TreeInvitationsContext.Provider
            value={{
                treeInvitations,
                setTreeInvitations,
            }}
        >
            {props.children}
        </TreeInvitationsContext.Provider>
    )
}

export default TreeInvitationsContextProvider
