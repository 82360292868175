import React from 'react'
import styled, { css } from 'styled-components'

const StyledHeader = styled.div`
    font-weight: 900;
    font-family: var(--fontFamily_header);
    // margin-bottom: 1em;

    ${(props) =>
        props.size === 'xxl' &&
        css`
            font-size: 48px;
        `}
    ${(props) =>
        props.size === 'xl' &&
        css`
            font-size: 40px;
        `}
    ${(props) =>
        props.size === 'l' &&
        css`
            font-size: 32px;
        `}
    ${(props) =>
        props.size === 'm' &&
        css`
            font-size: 24px;
        `}
    ${(props) =>
        props.size === 's' &&
        css`
            font-size: 18px;
        `}
    ${(props) =>
        props.size === 'xs' &&
        css`
            font-size: 16px;
        `}
    ${(props) =>
        props.size === 'xxs' &&
        css`
            font-size: 14px;
        `}
`

export const Header = ({ as, children, size = 'm', ...rest }) => {
    return (
        <StyledHeader as={as} size={size} {...rest}>
            {children}
        </StyledHeader>
    )
}

export default Header
