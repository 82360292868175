import React, { useContext, useEffect, useRef, useState } from 'react'
import DetailsPanelContext from '../../../contexts/DetailsPanelContext'
import styled, { css } from 'styled-components'
import Header from '../../atoms/Header'
import LeavesContext from '../../../contexts/LeavesContext'
import LeafProfileCard from '../LeafProfileCard'
import AddRelationship from '../../organisms/AddRelationships'
import AddLeafToTrees from '../AddLeafToTree'
import useCurrentMemberPermission from '../../../hooks/useCurrentMemberPermission'
import LeafListContainer from '../../organisms/LeafListContainer'
import { CSSTransition } from 'react-transition-group'
import IconButton from '../../atoms/IconButton'
import Avatar from '../../atoms/Avatar'
import curveAsset from '../../../assets/images/wavy-sm_green.svg'
import Icon from '../../atoms/Icon'
import useModal from '../../../hooks/useModal'
import RemoveLeaf from './RemoveLeaf'
import ProfileModal from '../ProfileModal'
import LinkToMemberModal from '../../organisms/LinkToMemberModal'
import CurrentTreeMembersContext from '../../../contexts/CurrentTreeMembersContext'
import { LeafImagesContext } from '../../../contexts/LeafImageContext'
import useUnlinkLeaf from '../../../hooks/useUnlinkLeaf'
import useTreeMembers from '../../../hooks/useTreeMembers'
import EditRelativesModal from '../EditRelativeModal'
import ClaimLeafModal from '../../organisms/ClaimLeafModal'
import MemberContext from '../../../contexts/MemberContext'
import PanelHeaderDecoration from '../PanelHeaderDecoration'

export const profileInfoContainer = css`
    --containerPadding: 24px;
    padding: var(--containerPadding);
    width: 100%;
`
const StyledDetailsPanelContainer = styled.aside`
    --spacing: 0px;
    scroll-behavior: smooth;
    flex-direction: column;
    display: flex;
    width: clamp(320px, var(--detailsPanelWidth), 600px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    min-height: 100%;
    max-height: 100%;
    border: 0px solid transparent;
    border-radius: 0px;
    background-color: var(--color_background_depth_5);
    background-color: var(--color_background_depth_2);
    border-right: 1px solid var(--color_border);
    padding-bottom: 0;
    position: relative;

    ${(props) =>
        !props.showPanel &&
        css`
            display: none;
        `}
`

const speed = 180
const StyledTransitionContainer = styled.div`
    width: 100%;
    transition: transform ${speed}ms ease-out;
    background-color: var(--color_background_depth_2);

    &.main-enter,
    &.main-enter-done,
    &.main-enter-active,
    &.main-exit-done,
    &.main-exit-active {
        z-index: 1;
    }

    // &.main-enter-active {
    //     transform: translateX(0%);
    // }

    // &.main-exit {
    //     position: absolute;
    //     transform: translateX(-110%);
    // }

    // &.main-exit-active {
    //     // transform: translateX(-110%);
    // }

    &.secondary-enter,
    &.secondary-enter-active {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
    }

    &.secondary-enter {
        transform: translateX(110%);
    }

    &.secondary-enter-active {
        transform: translateX(0%);
    }

    &.secondary-enter-done {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
    }

    &.secondary-exit {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
        transform: translateX(110%);
    }

    &.secondary-exit-active {
        transform: translateX(110%);
    }

    &.secondary-exit-done {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
        transform: translateX(0%);
    }
`

const StyledFlexGroup = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 99;
    max-height: 100%;
    // overflow: hidden;
`

const StyledPanelHeader = styled.div`
    --panelHeaderSpacing: 12px;
    padding: 16px;
    // margin: 0 16px;
    display: flex;
    align-items: center;
    max-height: 100%;
    justify-content: space-between;
    // border-bottom: 1px solid var(--color_border);
    background-color: var(--color_15_dark);
    color: white;
`

const StyledLeafListContainer = styled.div`
    // display: grid;
    // grid-template-columns: 1fr;
    // row-gap: 0px;
    padding: 8px;
    overflow: auto;
    flex: 1;
    -webkit-overflow-scrolling: touch;
`

const StyledPanelHeaderTitle = styled(Header)`
    flex: 1;
    // color: white;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    padding: 0 8px;
`

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
`

const StyledSpan = styled.span`
    font-weight: bold;
`

const StyledContainer = styled.div`
    position: relative;
    position: sticky;
    top: 0;
    z-index: 2;
`
const StyledCurve = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    // background-color: var(--brand);
    color: white;
    // background-image: url(${curveAsset});
    // background-position: top center;
    // background-size: 100% 68%;
    // background-repeat: no-repeat;
    z-index: -1;
`

const StyledNavigation = styled.div`
    position: relative;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const StyledBackButton = styled(IconButton)`
    position: absolute;
    left: 16px;
    top: 16px;
`

const StyledAvatar = styled(Avatar)`
    box-shadow: 0 0 0 6px var(--color_background_depth_2);
`

const StyledButtonContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 100px);
    gap: 16px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 16px 24px 8px 32px;
    margin-bottom: 24px;
    scroll-behavior: smooth;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
`

const StyledClaimMessage = styled.div`
    background-color: var(--color_background_depth_4);
    border-radius: 8px;
    font-size: 14px;
    margin: 16px 32px;
    padding: 20px 16px;
    text-align: center;
    display: flex;
    justify-content: space-between;
`

const StyledClaimActions = styled.div`
    display: grid;
    gap: 8px;
    grid-template-columns: auto auto;
`

const StyledNameHeader = styled(Header)`
    color: white;
    text-align: center;
    max-width: 65%;
`
const StyledRelationshipNameContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const StyledRelationshipList = styled.ul`
    margin-bottom: 48px;
    display: grid;
    grid-template-columns: repeat(auto-fit, calc(56px + 20px));
    gap: 0px;
`

const StyledRelationshipItem = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;

    &:hover {
        background-color: var(--color_background_depth_5);
    }
`

const StyledInfoContainer = styled.div`
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 80px;
    flex: 1;
`

const StyledName = styled.div`
    font-size: 12px;
    // font-weight: bold;
    margin-top: 8px;
    text-align: center;
    line-height: 1.3;
`

const StyledEditName = styled.div`
    font-size: 13px;
    margin-top: 8px;
    text-align: center;
    line-height: 1.3;
`

const StyledRelation = styled.div`
    font-weight: normal;
    font-size: 12px;
    color: var(--color_text_600);
`

const StyledPanelButton = styled.div`
    padding: 16px 24px 16px 16px;
    border-radius: 16px;
    box-shadow: 0 0 0 1px var(--color_border);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2;
    cursor: pointer;

    &:hover {
        background-color: var(--color_10_light);
        color: white;
        box-shadow: none;
    }

    &:active {
        background-color: var(--activeBackgroundColor);
    }

    ${(props) =>
        props.solid === true &&
        css`
            color: white;
            background-color: var(--color_10_light);
            box-shadow: none;

            &:hover {
                background-color: var(--color_10_dark);
            }

            &:active {
                background-color: var(--color_10_light);
            }
        `}

    ${(props) =>
        props.danger &&
        css`
            color: var(--color_danger);

            &:hover {
                background-color: var(--color_danger);
                color: white;
            }
        `}
`

const StyledPanelButtonIcon = styled(Icon)`
    opacity: 75%;
`

const StyledEmptyMessage = styled.div`
    text-align: center;
    padding: 16px;
    border-radius: 8px;
    font-size: 13px;
    background-color: var(--color_background_depth_4);
    margin-bottom: 40px;
`

const StyledEditIcon = styled(Icon)`
    width: var(--accountMenuSize);
    height: var(--accountMenuSize);
    border-radius: var(--accountMenuSize);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color_background_depth_4);
`
export const DetailsPanel = () => {
    const { panelState, setPanelState } = useContext(DetailsPanelContext)
    const { leaves } = useContext(LeavesContext)
    const detailsPanelEl = useRef()
    const buttonGroup = useRef()
    const [
        isAdmin,
        isContributor,
        isViewer,
        memberPermission,
        isCreatorOfLeaf,
    ] = useCurrentMemberPermission([])
    const [toggleProfileModal, isProfileModalShowing] = useModal(false)
    const [toggleLinkToLeafModal, isLinkToLeafModalShowing] = useModal(false)
    const [
        toggleEditRelationshipsModal,
        isEditRelationshipsModalShowing,
    ] = useModal(false)
    const [toggleDeleteModal, isDeleteModalShowing] = useModal(false)
    const [toggleClaimLeafModal, isClaimLeafModalShowing] = useModal(false)
    const { currentTreeMembers } = useContext(CurrentTreeMembersContext)
    const { leafImages, getImageUrlById } = useContext(LeafImagesContext)
    const [editMode, setEditMode] = useState(false)
    const [unlinkLeaf] = useUnlinkLeaf()
    const { getClaimedBy } = useTreeMembers()
    const [relType, setRelType] = useState('')
    const { memberData, claimedLeaf } = useContext(MemberContext)
    const [similarName, setSimilarName] = useState(false)
    const [activeMenu, setActiveMenu] = useState(false)

    useEffect(() => {
        if (detailsPanelEl) {
            detailsPanelEl.current.scrollTop = 0
        }
    }, [panelState.leafId])

    useEffect(() => {
        if (memberPermission && buttonGroup && buttonGroup.current) {
            buttonGroup.current.scrollTo(0, 0)
        }
    }, [panelState.leafId])

    const editRelationshipType = (type) => {
        setRelType(type)
        toggleEditRelationshipsModal()
    }

    const PanelButton = ({ label, icon, solid, danger, ...rest }) => {
        return (
            <StyledPanelButton
                tabIndex="0"
                solid={solid}
                danger={danger}
                {...rest}
            >
                <StyledPanelButtonIcon
                    size="15px"
                    iconType="regular"
                    icon={icon}
                />
                {label}
            </StyledPanelButton>
        )
    }

    const handleProfileModal = (edit = false) => {
        setEditMode(edit)
        toggleProfileModal()
    }

    const RelationshipItem = ({ docId, type, edit, ...rest }) => {
        if (!edit) {
            const leafDoc = leaves.find((leaf) => leaf.id === docId)
            let doc = leafDoc || null
            const memberDoc = getClaimedBy(leafDoc?.claimed_by) || null

            if (leafDoc?.claimed_by) {
                doc = currentTreeMembers.find(
                    (member) => member.id === leafDoc.claimed_by
                )
            }

            const highlightLeaf = (shouldHighlight, leafId) => {
                let leafEl = document.querySelector(`[data-id="${leafId}"]`)

                if (leafEl && shouldHighlight) {
                    leafEl.classList.add('highlight')
                } else {
                    leafEl.classList.remove('highlight')
                }
            }

            if (leafDoc) {
                return (
                    <StyledRelationshipItem
                        // data-tooltip={doc.display_name}
                        // data-tooltip-position={'bm'}
                        onMouseOver={() => highlightLeaf(true, docId)}
                        onMouseOut={() => highlightLeaf(false, docId)}
                        onClick={() =>
                            setPanelState({
                                ...panelState,
                                leafId: docId,
                                leafDoc: leafDoc,
                                linkedMemberDoc: memberDoc,
                            })
                        }
                    >
                        <Avatar
                            size="40px"
                            isMember={memberDoc}
                            src={getImageUrlById(doc.id)}
                        />
                        <StyledName>{doc.display_name || 'No name'}</StyledName>
                        <StyledRelation>
                            {
                                panelState.leafDoc?.relationships[type]?.find(
                                    (r) => r.id === docId
                                ).relation
                            }
                        </StyledRelation>
                    </StyledRelationshipItem>
                )
            } else {
                return null
            }
        } else {
            return (
                <>
                    <StyledRelationshipItem
                        onClick={() => editRelationshipType(type)}
                    >
                        <StyledEditIcon icon="pencil-alt" />
                    </StyledRelationshipItem>
                </>
            )
        }
    }

    useEffect(() => {
        if (!claimedLeaf && memberData && panelState.leafDoc) {
            const leafName = panelState.leafDoc?.display_name.toLowerCase()
            const memberName = memberData.display_name?.toLowerCase() || null
            let match = false

            if (memberName) {
                match = leafName === memberName ? true : false
                match = match || memberName.includes(leafName)
                match = match || leafName.includes(memberName)
            }

            setSimilarName(match)
        }

        if (leaves.length === 0 || !panelState.leafDoc) {
            setActiveMenu('allLeaves')
        } else {
            setActiveMenu('leafDetails')
        }
    }, [panelState.leafId, leaves])

    return (
        <>
            <StyledDetailsPanelContainer
                ref={detailsPanelEl}
                showPanel={panelState.showPanel}
            >
                <CSSTransition
                    in={activeMenu === 'allLeaves'}
                    // unmountOnExit
                    classNames="main"
                    timeout={speed}
                    // onEnter={calcHeight}
                >
                    <StyledTransitionContainer
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxHeight: '100%',
                        }}
                    >
                        <StyledFlexGroup>
                            <StyledPanelHeader>
                                <StyledPanelHeaderTitle size="xxs">
                                    {`People ${
                                        leaves.length > 0
                                            ? `(${leaves.length})`
                                            : ''
                                    }`}
                                </StyledPanelHeaderTitle>
                            </StyledPanelHeader>
                            <StyledLeafListContainer>
                                <LeafListContainer />
                            </StyledLeafListContainer>
                        </StyledFlexGroup>
                    </StyledTransitionContainer>
                </CSSTransition>
                <CSSTransition
                    in={activeMenu === 'leafDetails'}
                    unmountOnExit
                    classNames="secondary"
                    timeout={speed}
                >
                    <StyledTransitionContainer>
                        <StyledContainer>
                            <StyledCurve>
                                <PanelHeaderDecoration />
                            </StyledCurve>
                            <StyledNavigation>
                                <StyledBackButton
                                    icon="list"
                                    whiteText={true}
                                    solid={true}
                                    circle={true}
                                    whiteTransparent={true}
                                    onClick={() =>
                                        setPanelState({
                                            ...panelState,
                                            leafId: null,
                                            leafDoc: null,
                                        })
                                    }
                                />
                                <StyledNameHeader
                                    size="s"
                                    className="u-mar-t_1 u-mar-b_3"
                                >
                                    {panelState?.linkedMemberDoc
                                        ?.display_name ||
                                        panelState?.leafDoc?.display_name ||
                                        'No name'}
                                </StyledNameHeader>
                                <StyledAvatar
                                    src={getImageUrlById(
                                        panelState.linkedMemberDoc?.id ||
                                            panelState.leafId
                                    )}
                                    size="120px"
                                    isMember={panelState.linkedMemberDoc}
                                />
                            </StyledNavigation>
                        </StyledContainer>
                        {memberPermission && !claimedLeaf && similarName && (
                            <StyledClaimMessage>
                                <span>Is this your leaf? </span>
                                <StyledClaimActions>
                                    <span
                                        className="a-link a-link--hover"
                                        onClick={toggleClaimLeafModal}
                                    >
                                        Yes, claim leaf.
                                    </span>
                                </StyledClaimActions>
                            </StyledClaimMessage>
                        )}
                        {memberPermission && (
                            <StyledButtonContainer ref={buttonGroup}>
                                {isAdmin && (
                                    <AddRelationship
                                        leafId={panelState.leafId}
                                        trigger={
                                            <PanelButton
                                                icon="plus"
                                                solid={true}
                                                label="Add relative"
                                            />
                                        }
                                    />
                                )}
                                <PanelButton
                                    icon="user-circle"
                                    label="View profile"
                                    onClick={() => handleProfileModal()}
                                />
                                {(isAdmin ||
                                    (isContributor &&
                                        isCreatorOfLeaf(
                                            panelState.leafId
                                        ))) && (
                                    <PanelButton
                                        icon="pencil-alt"
                                        label="Edit profile"
                                        disabled={panelState.linkedMemberDoc}
                                        onClick={() => handleProfileModal(true)}
                                    />
                                )}

                                {/* prettier-ignore */}
                                {claimedLeaf === panelState.leafId && (
                                    <PanelButton
                                        icon="leaf"
                                        label="Unclaim leaf"
                                        onClick={() =>
                                            unlinkLeaf(
                                                panelState.treeId,
                                                panelState.leafId
                                            )
                                        }
                                    />
                                )}
                                {!panelState.linkedMemberDoc &&
                                    !claimedLeaf && (
                                        <PanelButton
                                            icon="leaf"
                                            label="Claim leaf"
                                            onClick={toggleClaimLeafModal}
                                        />
                                    )}
                                {(isAdmin ||
                                    (isContributor &&
                                        isCreatorOfLeaf(
                                            panelState.leafId
                                        ))) && (
                                    <PanelButton
                                        icon="trash-alt"
                                        label="Delete leaf"
                                        danger={true}
                                        onClick={toggleDeleteModal}
                                    />
                                )}
                                <PanelButton
                                    style={{
                                        opacity: 0,
                                        pointerEvents: 'none',
                                        width: '32px',
                                    }}
                                />
                            </StyledButtonContainer>
                        )}

                        {panelState.leafDoc && (
                            <StyledInfoContainer>
                                <LeafProfileCard
                                    showProfileEditModal={() =>
                                        handleProfileModal(true)
                                    }
                                />
                                {[
                                    { label: 'Children', value: 'children' },
                                    { label: 'Parents', value: 'parents' },
                                    { label: 'Siblings', value: 'siblings' },
                                    { label: 'Partners', value: 'partners' },
                                ].map(
                                    (obj) =>
                                        panelState.leafDoc?.relationships[
                                            `${obj.value}`
                                        ]?.length > 0 && (
                                            <>
                                                <StyledRelationshipNameContainer
                                                    key={obj.label}
                                                    className="u-mar-b_2"
                                                >
                                                    <span className="u-bold u-mar-r_auto">
                                                        {obj.label}
                                                    </span>
                                                    {isAdmin && (
                                                        <div
                                                            onClick={() =>
                                                                editRelationshipType(
                                                                    obj.value
                                                                )
                                                            }
                                                            className="a-link a-link--hover u-font-size_13"
                                                        >
                                                            Edit
                                                        </div>
                                                    )}
                                                </StyledRelationshipNameContainer>
                                                <StyledRelationshipList
                                                    key={obj.value}
                                                >
                                                    {panelState?.leafDoc?.relationships[
                                                        `${obj.value}`
                                                    ]?.map((doc) => (
                                                        <RelationshipItem
                                                            key={doc.id}
                                                            docId={doc.id}
                                                            type={obj.value}
                                                        />
                                                    ))}
                                                    {/* {isAdmin && (
                                                        <RelationshipItem
                                                            type={obj.value}
                                                            edit={true}
                                                        />
                                                    )} */}
                                                </StyledRelationshipList>
                                            </>
                                        )
                                )}
                                <>
                                    {panelState.leafDoc.relationships.children
                                        .length === 0 &&
                                        panelState.leafDoc.relationships.parents
                                            .length === 0 &&
                                        panelState.leafDoc.relationships
                                            .siblings.length === 0 &&
                                        panelState.leafDoc.relationships
                                            .partners.length === 0 && (
                                            <>
                                                <div className="u-bold u-mar-b_2">
                                                    Relatives
                                                </div>
                                                <StyledEmptyMessage>
                                                    No relatives
                                                </StyledEmptyMessage>
                                            </>
                                        )}
                                </>

                                {/* <LeafRelationshipCard/> */}
                            </StyledInfoContainer>
                        )}
                    </StyledTransitionContainer>
                </CSSTransition>
                <StyledContent leafSelected={panelState.leafDoc}>
                    {leaves.length === 0 && <AddLeafToTrees />}
                </StyledContent>
                {isDeleteModalShowing && (
                    <RemoveLeaf
                        isModalShowing={isDeleteModalShowing}
                        hide={toggleDeleteModal}
                    />
                )}
                {isProfileModalShowing && (
                    <ProfileModal
                        isModalShowing={isProfileModalShowing}
                        hide={toggleProfileModal}
                        editMode={editMode}
                    />
                )}
                {isLinkToLeafModalShowing && (
                    <LinkToMemberModal
                        isModalShowing={isLinkToLeafModalShowing}
                        hide={toggleLinkToLeafModal}
                        leafDoc={panelState?.leafDoc}
                    />
                )}
                {isEditRelationshipsModalShowing && (
                    <EditRelativesModal
                        type={relType}
                        isShowing={isEditRelationshipsModalShowing}
                        hide={toggleEditRelationshipsModal}
                        size="m"
                        closeButton={true}
                        leafDoc={panelState?.leafDoc}
                        memberDoc={panelState.linkedMemberDoc}
                    />
                )}
                {isClaimLeafModalShowing && (
                    <ClaimLeafModal
                        isShowing={isClaimLeafModalShowing}
                        hide={toggleClaimLeafModal}
                    />
                )}
            </StyledDetailsPanelContainer>
        </>
    )
}

export default DetailsPanel
