import React, { Fragment } from 'react'
import firebase from 'firebase/app'
import 'firebase/analytics'
import { Route, Switch, Redirect } from 'react-router-dom'
import HomePage from './pages/HomePage'
import ProfilePage from './pages/ProfilePage'
import TreePage from './pages/TreePage'
import SignIn from './pages/SignIn'
import { UserContextProvider } from './contexts/UserContext'
import { MemberContextProvider } from './contexts/MemberContext'
import { LeavesContextProvider } from './contexts/LeavesContext'
import NotificationsPage from './components/organisms/NotificationsPage'
import SignUp from './pages/SignUp'
import PublicRoute from './routes/PublicRoute'
import GlobalStyle from '../src/globalStyles'
import NotificationsContextProvider from './contexts/NotificationsContext'
import { CustomThemeContextProvider } from './contexts/CustomThemeContext'
import LayoutRoute from './components/general/LayoutRoute'
import TreeLayout from './components/general/TreeLayout'
import TreesPage from './pages/TreesPage'
import SettingsPage from './components/organisms/SettingsPage'
import TutorialsPage from './components/organisms/TutorialsPage'
import SignOut from './pages/SignOut'
import 'toasted-notes/src/styles.css'
import DashboardLayout from './components/general/DashboardLayout'
import WindowSizeContextProvider from './contexts/WindowSizeContext'

var firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    // apiKey: process.env.REACT_APP_API_KEY,
    // authDomain: 'mily-4c2a8.firebaseapp.com',
    // databaseURL: 'https://mily-4c2a8.firebaseio.com',
    // projectId: 'mily-4c2a8',
    // messagingSenderId: '1885564039',
    // storageBucket: 'mily-4c2a8.appspot.com',
    // appId: '1:1885564039:web:c6027cccf72728f96fdc22',
    // measurementId: 'G-R4V33BYYZ4',
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()

const App = () => {
    return (
        <Fragment>
            <CustomThemeContextProvider>
                <GlobalStyle />
                <div className="App">
                    <UserContextProvider>
                        <MemberContextProvider>
                            <LeavesContextProvider>
                                <NotificationsContextProvider>
                                    <WindowSizeContextProvider>
                                        <Switch>
                                            <LayoutRoute
                                                exact={true}
                                                path="/"
                                                component={HomePage}
                                                routeType={Route}
                                            />
                                            <LayoutRoute
                                                layout={DashboardLayout}
                                                exact={true}
                                                path="/profile"
                                                component={ProfilePage}
                                                authenticatedRoute={true}
                                            />
                                            <LayoutRoute
                                                path="/trees/:treeId"
                                                layout={TreeLayout}
                                                component={TreePage}
                                                routeType={Route}
                                            />
                                            <LayoutRoute
                                                path="/trees"
                                                layout={DashboardLayout}
                                                component={TreesPage}
                                                authenticatedRoute={true}
                                            />
                                            <LayoutRoute
                                                path="/notifications"
                                                layout={DashboardLayout}
                                                component={NotificationsPage}
                                                authenticatedRoute={true}
                                            />
                                            <LayoutRoute
                                                path="/settings"
                                                layout={DashboardLayout}
                                                component={SettingsPage}
                                                authenticatedRoute={true}
                                            />
                                            <LayoutRoute
                                                path="/tutorials"
                                                layout={DashboardLayout}
                                                component={TutorialsPage}
                                            />
                                            <LayoutRoute
                                                path="/sign-in"
                                                component={SignIn}
                                                routeType={PublicRoute}
                                            />
                                            <LayoutRoute
                                                path="/sign-up"
                                                component={SignUp}
                                                routeType={PublicRoute}
                                            />
                                            <LayoutRoute
                                                path="/sign-out"
                                                component={SignOut}
                                                routeType={PublicRoute}
                                                authenticatedRoute={true}
                                            />
                                            <Redirect to="/" />
                                        </Switch>
                                    </WindowSizeContextProvider>
                                </NotificationsContextProvider>
                            </LeavesContextProvider>
                        </MemberContextProvider>
                    </UserContextProvider>
                </div>
            </CustomThemeContextProvider>
        </Fragment>
    )
}

export default App
