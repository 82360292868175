import React from 'react'
import styled, { css } from 'styled-components'
import noUserImg from '../../assets/images/profile_placeholder_gray.svg'

const StyledAvatar = styled.div`
    --borderRadius: ${(props) =>
        props.borderRadius ? props.borderRadius : '500px'};
    width: var(--accountMenuSize);
    min-width: var(--accountMenuSize);
    height: var(--accountMenuSize);
    min-height: var(--accountMenuSize);
    border-radius: var(--borderRadius);
    padding: 0;
    background-size: var(--accountMenuSize);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    object-fit: cover;
    border: 0px solid transparent;
    position: relative;
    transition: transform 0.2s ease-in-out;

    ${(props) =>
        props.size &&
        css`
            --accountMenuSize: ${props.size || '22px'};
        `}

    ${(props) =>
        props.size === 'xxxs' &&
        css`
            --accountMenuSize: 22px;
        `}
    ${(props) =>
        props.size === 'xxs' &&
        css`
            --accountMenuSize: 32px;
        `}
    ${(props) =>
        props.size === 'xs' &&
        css`
            --accountMenuSize: 40px;
        `}
    ${(props) =>
        props.size === 's' &&
        css`
            --accountMenuSize: 48px;
        `}
    ${(props) =>
        props.size === 'm' &&
        css`
            --accountMenuSize: 80px;
        `}
    ${(props) =>
        props.size === 'l' &&
        css`
            --accountMenuSize: 130px;
        `}
    ${(props) =>
        props.size === 'xl' &&
        css`
            --accountMenuSize: 180px;
        `} 
    ${(props) =>
        props.isMember &&
        css`
            --borderRadius: 25%;
        `}
`

const StyledImage = styled.div`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--borderRadius);
    background-color: var(--color_background_depth_2);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    backface-visibility: hidden;
    background-image: url(${(props) => props.src && `${props.src}`});
`

export const Avatar = ({
    src = null,
    children,
    size,
    borderRadius,
    isMember,
    ...rest
}) => {
    return (
        <StyledAvatar
            isMember={isMember}
            borderRadius={borderRadius}
            size={size}
            {...rest}
        >
            {<StyledImage borderRadius={borderRadius} src={src || noUserImg} />}

            {children}
        </StyledAvatar>
    )
}

export default Avatar
