import React, { useContext, useEffect, useState } from 'react'
import {
    buildBirthdayString,
    buildDeathDateString,
    buildFullAddressString,
    convertDate,
    getLeafImage,
    getNameDetails,
    loopThroughMemberBlueprint,
} from '../assets/js/utils'
import Button from '../components/atoms/Button'
import LeafProfileItem from '../components/general/DetailsPanel/LeafProfileItem'
import MemberContext from '../contexts/MemberContext'
import toaster from 'toasted-notes'
import firebase from 'firebase/app'
import DetailsPanelContext from '../contexts/DetailsPanelContext'
import LeavesContext from '../contexts/LeavesContext'
import CurrentTreeMembersContext from '../contexts/CurrentTreeMembersContext'

export const useProfileData = () => {
    const { panelState } = useContext(DetailsPanelContext)
    const { leaves } = useContext(LeavesContext)
    const { currentTreeMembers } = useContext(CurrentTreeMembersContext)
    const [profile, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const [empty, setEmpty] = useState(null)
    const [summary, setSummary] = useState([])
    const excludeCategories = ['name', 'address', 'birthday', 'deathdate']

    useEffect(() => {
        if (leaves.length > 0) {
            setData([])
            setSummary([])

            let inputItems = []
            let data = leaves.find((l) => l.id === panelState.leafId) || null
            data =
                (data &&
                    currentTreeMembers.find((m) => m.id === data.claimed_by)) ||
                data

            if (data) {
                const generateInputs = (key, value) => {
                    let pathName = value['pathName']
                    let icon = value['icon']
                    let category = value['category']
                    let description = value['description'] || null
                    let dataValue = data[pathName]
                    let dataFormat = value['dataFormat']

                    // if (dates.includes(value.pathName) && dataValue) {
                    //     dataValue = convertDate(dataValue);
                    // }

                    if (!excludeCategories.includes(category) && dataValue) {
                        inputItems.push({
                            key: pathName,
                            label: key,
                            icon: icon,
                            value: dataValue || null,
                            description: description,
                            category: category,
                            dataFormat: dataFormat,
                        })
                    }
                }

                const addressString = buildFullAddressString(data) || null

                addressString &&
                    inputItems.push({
                        key: 'address',
                        label: 'Address',
                        icon: 'house',
                        value: addressString,
                    })

                const birthdayString = buildBirthdayString(data) || null

                birthdayString &&
                    inputItems.push({
                        key: 'birthday',
                        label: 'Birthday',
                        icon: 'birthday-cake',
                        value: birthdayString,
                    })

                loopThroughMemberBlueprint({
                    functionCall: generateInputs,
                })

                const deathdateString = buildDeathDateString(data) || null

                deathdateString &&
                    inputItems.push({
                        key: 'deathdate',
                        label: 'Death date',
                        icon: 'tombstone',
                        value: deathdateString,
                    })

                const fullAddress = buildFullAddressString(data) || null
                const email = data.primary_email
                    ? { icon: 'envelope', value: data.primary_email }
                    : null
                const phone = data.primary_phone
                    ? { icon: 'phone', value: data.primary_phone }
                    : null
                const address = fullAddress
                    ? { icon: 'home-lg', value: fullAddress }
                    : null
                const birthdateString =
                    data.birth_date || data.birth_month || data.birth_year
                        ? buildBirthdayString(data)
                        : null
                const deathdateStringSummary =
                    data.death_date || data.death_month || data.death_year
                        ? buildDeathDateString(data)
                        : null
                const birthdate = birthdateString
                    ? { icon: 'birthday-cake', value: birthdateString }
                    : null
                const deathdate = deathdateStringSummary
                    ? { icon: 'tombstone', value: deathdateStringSummary }
                    : null

                setSummary(
                    [deathdate, address, birthdate, email, phone].filter(
                        (item) => item && item
                    )
                )

                setData({
                    data: inputItems,
                    photoSrc: getLeafImage(data?.id),
                    nameDetails: getNameDetails(data),
                    displayName: data?.display_name || data?.first_name,
                })

                setLoading(false)
                setEmpty(inputItems.length === 0 ? true : false)
            } else {
                setLoading(false)
                setSummary([])
                setData([])
            }
        }
    }, [leaves, panelState.leafId])

    // const getSummary = (doc) => {
    //     if (doc) {

    //     } else {
    //         setSummary([])
    //     }
    // }

    return { profile, loading, empty, summary }
}

export default useProfileData
