import React, { useContext, useState } from 'react'
import Button from '../atoms/Button'
import styled from 'styled-components'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import InputGroup from '../organisms/InputGroup'
import { newLeafForFirebase } from '../../assets/js/utils'
import MemberContext from '../../contexts/MemberContext'
import firebase from 'firebase/app'
import Header from '../atoms/Header'
import ButtonGroup from '../organisms/ButtonGroup'
import useCurrentMemberPermission from '../../hooks/useCurrentMemberPermission'
import { UserContext } from '../../contexts/UserContext'

const StyledButton = styled(Button)`
    width: 100%;
    margin-top: 16px;
`

const StyledInput = styled(InputGroup)`
    width: 100%;
    margin-bottom: 8px;
`

const StyledContainer = styled.div`
    padding: 16px;
    // border: 1px solid var(--color_border);
    border-radius: 8px;
    text-align: center;
    background-color: var(--color_background_depth_4);
    margin: 16px;
`

const StyledOption = styled.div`
    border-radius: 8px;
    // padding: clamp(16px, 2vw, 32px);
    margin: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
`

const StyledOptionContainer = styled.div`
    display: flex;
`

const StyledMessage = styled.div`
    font-size: 15px;
    color: var(--color_text_700);
    text-align: center;
    padding: 16px 24px;
`

const StyledHeader = styled(Header)`
    margin-bottom: 8px;
`

const StyledSubtitle = styled.p`
    margin-bottom: 32px;
    font-size: 15px;
    color: var(--color_text_700);
`

export const AddLeafToTrees = () => {
    const { panelState } = useContext(DetailsPanelContext)
    const { memberData } = useContext(MemberContext)
    const { userId } = useContext(UserContext)
    const [displayName, setDisplayName] = useState('')
    const [activeMenu, setActiveMenu] = useState('')
    const [errMessage, setErrMessage] = useState('')
    const [
        isAdmin,
        isContributor,
        isViewer,
        memberPermission,
    ] = useCurrentMemberPermission()

    const handleSubmit = (e, claimedBy = null) => {
        e.preventDefault()

        if (displayName.length > 0 || claimedBy) {
            let newFirstName = displayName

            if (claimedBy) {
                newFirstName = memberData.display_name || newFirstName
            }

            let newLeafObject = newLeafForFirebase({
                created_by: userId,
                claimed_by: claimedBy,
                display_name: newFirstName,
            })

            firebase
                .firestore()
                .collection('trees')
                .doc(panelState.treeId)
                .collection('leaves')
                .add(newLeafObject)
                .then((newLeafRef) => {
                    console.log(`Successfully created ${newLeafRef.id}`)
                })
        } else {
            setErrMessage('Enter a display name')
        }
    }

    const handleOnChange = (e) => {
        setDisplayName(e.target.value)
    }

    return (
        <StyledContainer>
            {isViewer && memberPermission ? (
                <StyledMessage>This tree has no leaves yet.</StyledMessage>
            ) : (
                <>
                    <StyledHeader size="s">Add your first leaf</StyledHeader>
                    <StyledSubtitle>Start with anyone.</StyledSubtitle>
                    <form>
                        <StyledOptionContainer>
                            <StyledOption>
                                <div>
                                    <StyledInput
                                        label="Display name"
                                        onChange={(e) => handleOnChange(e)}
                                        value={displayName}
                                        placeholder="First name or nickname"
                                        errorMessage={errMessage}
                                    />
                                    <ButtonGroup
                                        verticalStack={true}
                                        fillSpace={true}
                                    >
                                        <StyledButton
                                            onClick={(e) => handleSubmit(e)}
                                        >
                                            Add leaf
                                        </StyledButton>
                                    </ButtonGroup>
                                </div>
                            </StyledOption>
                        </StyledOptionContainer>
                    </form>
                </>
            )}
        </StyledContainer>
    )
}

export default AddLeafToTrees
