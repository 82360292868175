import React from 'react';

export const PanelHeaderDecoration = ({fill, ...rest}) => {
return (
    <svg 
        width="100%" 
        height="146" 
        preserveAspectRatio="none" 
        viewBox="0 0 352 146" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
            <path 
                d="M137.381 145.5C83.9732 145.5 23.5404 131.657 0 124.735V0H352V124.735C328.276 124.735 204.14 145.5 137.381 145.5Z" 
                fill={fill || `var(--color_15_dark)`}
            />
    </svg>
    )
}

export default PanelHeaderDecoration;