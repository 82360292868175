import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import Button from './Button'

export const LinkButton = ({
    history,
    location,
    match,
    staticContext,
    to,
    onClick,
    // ⬆ filtering out props that `button` doesn’t know what to do with.
    ...rest
}) => {
    return (
        <Button
            {...rest} // `children` is just another prop!
            onClick={(event) => {
                onClick && onClick(event)
                window.history.pushState(null, null, to)
                window.location.reload()
            }}
        />
    )
}

LinkButton.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
}

export default withRouter(LinkButton)
