import React, { useState, createContext, useEffect, useContext } from 'react'
import firebase from 'firebase/app'
import MemberContext from './MemberContext'

export const NotificationsContext = createContext()

export const NotificationsContextProvider = (props) => {
    const [notifications, setNotifications] = useState([])
    const { memberData } = useContext(MemberContext)

    useEffect(() => {
        const notificationsRef = firebase
            .firestore()
            .collection('notifications')

        if (memberData) {
            notificationsRef
                .where('status', '==', 'pending')
                .where('for_email', '==', memberData.account_email)
                .onSnapshot((snapshot) => {
                    let notificationDocs = []

                    for (const doc of snapshot.docs) {
                        notificationDocs.push({ id: doc.id, ...doc.data() })
                    }
                    setNotifications(notificationDocs)
                })
        }
    }, [memberData])

    return (
        <NotificationsContext.Provider
            value={{ notifications, setNotifications }}
        >
            {props.children}
        </NotificationsContext.Provider>
    )
}

export default NotificationsContextProvider
