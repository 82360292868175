import React, { useState, createContext, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'

const CustomThemeContext = createContext()

export const CustomThemeContextProvider = (props) => {
    const defaultTheme = { mode: 'light', color: 'purple-blue' }
    const [themeMode, setThemeMode] = useState(defaultTheme)

    useEffect(() => {
        let theme = window.localStorage.getItem('theme')
        theme = theme ? JSON.parse(theme) : defaultTheme
        setThemeMode(theme)
    }, [])

    useEffect(() => {
        window.localStorage.setItem('theme', JSON.stringify(themeMode))
    }, [themeMode])

    return (
        <CustomThemeContext.Provider value={{ themeMode, setThemeMode }}>
            <ThemeProvider theme={themeMode}>{props.children}</ThemeProvider>
        </CustomThemeContext.Provider>
    )
}

export default CustomThemeContext
