import { useState, useEffect } from 'react'
import { getSessionStorageByKey } from '../assets/js/utils'
import firebase from 'firebase/app'

const useInvitationParams = () => {
    const [invitation, setInvitation] = useState(false)

    const params = new URLSearchParams(window.location.search)
    const invitationId = params.get('invitation') || null
    const sessionInvite = getSessionStorageByKey('invitation')
    const notificationsRef = firebase.firestore().collection('notifications')

    useEffect(() => {
        if (invitationId) {
            notificationsRef
                .doc(invitationId)
                .get()
                .then((reqNotification) => {
                    const invitationDoc = {
                        id: reqNotification.id,
                        ...reqNotification.data(),
                    }

                    setInvitation(invitationDoc)
                })
        } else if (sessionInvite) {
            setInvitation(sessionInvite)
        } else {
            setInvitation(null)
        }
    }, [])

    return { invitation, setInvitation }
}

export default useInvitationParams
