import React from 'react'
import styled, { css } from 'styled-components'
import Icon from './Icon'

// import { sharedFunctionTest } from '../../../assets/js/utlis';

const StyledButton = styled.button`
    --btn_bg_color: var(--color_10_light);
    --btn_bg_color--hover: var(--color_10_dark);
    --btn_border_color: transparent;
    --btn_text_color: white;
    --btn_text_color--hover: white;
    --btn_border_color--hover: var(--color_outline);
    --bgColorSelected: var(--blue_50);
    --bgColorSelectedHover: var(--blue_100);
    --colorSelected: var(--blue_500);

    ${(props) =>
        props.theme.mode === 'dark' &&
        css`
            // --btn_text_color: white;
            // --btn_bg_color: var(--color_background_depth_2);
            --bgColorSelected: var(--blue_950);
            --bgColorSelectedHover: var(--blue_500);
            --colorSelected: var(--blue_300);
        `}

    &.selected {
        --btn_bg_color--hover: var(--bgColorSelectedHover);
        background-color: var(--bgColorSelected);
        color: var(--colorSelected);
    }

    background-color: var(--btn_bg_color);
    padding: 12px 18px;
    color: var(--btn_text_color);
    border-radius: 8px;
    border: 0px solid transparent;
    display: flex;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
    // transition: transform 0.1s ease-in-out;
    text-decoration: none;
    align-items: center;
    position: relative;
    box-shadow: 0 0 0 1px var(--btn_border_color) inset;
    // transition: all 0.2s ease;
    min-height: 44px;
    margin: 0px;

    &:focus {
        // transition: all 0.05s ease;
        background-color: var(--btn_bg_color--hover);
        box-shadow: 0 0 0 2px var(--color_10_dark);
    }

    &:hover {
        // transition: all 0.05s ease;
        background-color: var(--btn_bg_color--hover);
        box-shadow: 0 0 0 2px var(--btn_bg_color--hover);
        color: var(--btn_text_color--hover);
    }

    :focus:not(:focus-visible) {
        box-shadow: none;
    }

    &:active {
        background-color: var(--activeBackgroundColor);
        transition: transform 0s ease-in-out;
    }

    &:focus {
        box-shadow: 0 0 0 2px var(--color_outline);
        outline: 0px solid transparent;
        color: var(--btn_text_color--hover);
    }

    &[disabled] {
        pointer-events: none;
        opacity: 0.35;
    }

    ${(props) =>
        props.pill &&
        css`
            border-radius: 600px;
        `};

    ${(props) =>
        props.danger &&
        css`
            --btn_bg_color: --red_600;
            --btn_bg_color--hover: --red_700;
        `}

    ${(props) =>
        props.solid &&
        css`
            --btn_bg_color: var(--color_15_dark);
            --btn_bg_color--hover: var(--color_10_light);
            --btn_text_color: white;
            box-shadow: none;

            &:active {
                background-color: var(--brand_400);
            }
        `}

    ${(props) =>
        props.solid &&
        props.danger &&
        css`
            --btn_text_color: white;
            --btn_bg_color: var(--color_danger);
            --bgHoverColor: var(--red_700);
        `}

    ${(props) =>
        props.solid &&
        props.white &&
        css`
            --btn_text_color: var(--color_text);
            --btn_bg_color: white;
            --bgHoverColor: var(--brand_200);
        `}

    ${(props) =>
        (props.whiteTransparent || props.transparent) &&
        css`
            background-color: transparent;
            box-shadow: none;
            border: none;
            --btn_text_color: var(--color_text);
            --btn_bg_color--hover: var(--color_10_light);

            ${(props) =>
                props.danger &&
                css`
                    --btn_text_color: var(--color_danger);
                    --btn_border_color--hover: var(--color_danger);
                `}
        `};

    ${(props) =>
        props.outline &&
        css`
            --btn_border_color: var(--color_border_200);
            --btn_bg_color: transparent;
            --btn_bg_color--hover: var(--color_10_light);
            --btn_text_color: var(--color_10_light);
            --btn_text_color--hover: white;

            ${(props) =>
                props.danger &&
                css`
                    --btn_text_color: var(--color_danger);
                    --btn_bg_color: transparent;
                    --btn_border_color--hover: var(--color_danger);
                `};
        `};

    ${(props) =>
        props.size === 'l' &&
        css`
            padding: 18px 24px;
            font-size: 18px;
        `};

    ${(props) =>
        props.size === 'm' &&
        css`
            padding: 10px 12px;
            min-height: unset;
            font-size: 14px;
        `};

    ${(props) =>
        props.fullWidth &&
        css`
            width: 100%;
        `};

    ${(props) =>
        props.whiteText &&
        css`
            --btn_text_color: white;
        `};
`

const StyledLeftIcon = styled(Icon)`
    margin-right: 10px;
    pointer-events: none;
`

const StyledRightIcon = styled(Icon)`
    margin-left: 8px;
    pointer-events: none;
`

export const Button = ({
    children,
    className,
    onClick,
    disabled,
    secondary,
    danger,
    whiteText,
    light,
    size,
    iconLeft,
    iconLeftType = 'regular',
    iconRight,
    iconRightType,
    pill,
    label,
    ...rest
}) => {
    return (
        <StyledButton
            className={className}
            onClick={onClick}
            disabled={disabled}
            secondary={secondary}
            whiteText={whiteText}
            light={light}
            size={size}
            pill={pill}
            danger={danger}
            {...rest}
            aria-label={label}
        >
            {iconLeft && (
                <StyledLeftIcon icon={iconLeft} iconType={iconLeftType} />
            )}
            {children}
            {iconRight && (
                <StyledRightIcon icon={iconRight} iconType={iconRightType} />
            )}
        </StyledButton>
    )
}

Button.defaultProps = {
    text: null,
    disabled: false,
    onClick: null,
}

export default Button
