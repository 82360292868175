import React from 'react'
import styled from 'styled-components'
import Header from '../atoms/Header'
import { pageContainerStyles } from '../../pages/TreesPage'

const StyledWrapper = styled.div`
    // ${pageContainerStyles}
    margin: 0 auto;
    max-width: 800px;
    padding: 64px clamp(24px, 2.5vw, 80px);
`

const StyledItemContainer = styled.li`
    display: flex;
    align-items: flex-end;
    color: var(--color_text_800);
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    padding: 32px;
    border-radius: 16px;
    background-color: var(--color_background_depth_2);

    &:hover {
        background-color: var(--hoverBackgroundColor);
    }

    &:active {
        background-color: var(--activeBackgroundColor);
    }
`

const StyledHeaderContainer = styled(Header)`
    padding-left: 16px;
    padding-right: 16px;
`

const StyledHeader = styled(Header)`
    margin-top: 52px;
    margin-bottom: 48px;
`

const StyledTutorialContainer = styled.ul`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: 200px;

    gap: 24px;
    background-color: ;
`

export const TutorialsPage = () => {
    return (
        <>
            <StyledWrapper>
                <StyledHeader size="m">Tutorials (Coming soon!)</StyledHeader>
                <StyledTutorialContainer>
                    <StyledItemContainer>
                        Creating a family tree
                    </StyledItemContainer>
                    <StyledItemContainer>
                        Member permissions
                    </StyledItemContainer>
                    <StyledItemContainer>Inviting members</StyledItemContainer>
                    <StyledItemContainer>
                        Linking leaves to members
                    </StyledItemContainer>
                    <StyledItemContainer>Privacy settings</StyledItemContainer>
                </StyledTutorialContainer>
            </StyledWrapper>
        </>
    )
}

export default TutorialsPage
