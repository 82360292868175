import React, { useState, useContext } from 'react'
import { MemberContext } from '../contexts/MemberContext'
import styled, { css } from 'styled-components'
import Header from '../components/atoms/Header'
import { Link } from 'react-router-dom'
import Icon from '../components/atoms/Icon'
import PromptDisplayNameModal from '../components/general/PromptDisplayNameModal'
import MemberProfile from '../components/general/MemberProfile'
import Select from '../components/atoms/Select'

export const profileLabelStyling = css`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    font-weight: normal;
    font-size: 14px;
    color: var(--color_text_700);
`

export const StyledLabel = styled.label`
    ${profileLabelStyling}
`

const StyledWrapper = styled.div`
    display: flex;
    align-items: flex-start;

    @media only screen and (max-width: 720px) {
        flex-direction: column;
    }
`

const StyledContainer = styled.div`
    border-radius: 8px;
    flex: 1;
    margin-bottom: 10vh;

    @media only screen and (max-width: 720px) {
        margin: 24px auto;
    }
`

const StyledCenterContainer = styled.div`
    width: 100%;
    max-width: 780px;
    margin: 0 auto;
    padding: 64px clamp(24px, 2.5vw, 80px);
`

const StyledHeader = styled(Header)`
    margin-top: 52px;
    margin-bottom: 48px;
`

export const StyledInput = styled.input`
    margin-top: 8px;
`

export const StyledSelect = styled(Select)`
    margin-top: 8px;
`

const StyledNextStepMessage = styled.div`
    padding: 24px;
    border-radius: 8px;
    max-width: 800px;
    margin: 16px auto;
    background-color: var(--color_background_depth_5);
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 16px;
`

export const ProfilePage = () => {
    const { memberData } = useContext(MemberContext)
    const [editMode, setEditMode] = useState(false)

    return (
        <StyledCenterContainer>
            <MemberProfile changeEditMode={setEditMode} />
            {memberData && <PromptDisplayNameModal />}
        </StyledCenterContainer>
    )
}

export default ProfilePage
