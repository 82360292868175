import React, { useState, useContext, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { AccountDropdown } from './AccountDropdown'
import { Link, useHistory } from 'react-router-dom'
import { dropdownStyle } from '../../globalStyles'
import { UserContext } from '../../contexts/UserContext'
import ButtonGroup from './ButtonGroup'
import { LinkButton } from '../atoms/LinkButton'
import Button from '../atoms/Button'

const StyledLogo = styled(Link)`
    font-size: 22px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: white;
    height: calc(var(--navHeight) - 32px);
    text-decoration: none;
    border-radius: 8px;
    font-family: var(--fontFamily_header);
`

const StyledNav = styled.nav`
    height: var(--navHeight);
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    background-color: var(--color_15_light);
    z-index: 2;
    padding-left: 24px;
    padding-right: 24px;
`

const StyledLink = styled(LinkButton)`
    text-decoration: none;
`

const StyledNavGroup = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
`

const StyledDashboardButton = styled(Button)`
    margin-right: 16px;

    ${(props) =>
        props.active &&
        css`
            opacity: 0.4;
        `}
`

export const NavBar = ({ children, ...rest }) => {
    const { user, userId } = useContext(UserContext)
    let history = useHistory()

    const navigateToTrees = () => {
        history.push('/trees')
    }

    return (
        <StyledNav>
            <StyledLogo to={userId ? '/trees' : '/'}>Mily</StyledLogo>
            <StyledNavGroup>
                {userId && (
                    <StyledDashboardButton
                        onClick={() => navigateToTrees()}
                        transparent={true}
                        iconLeft="home"
                    >
                        Home
                    </StyledDashboardButton>
                )}
                {userId && <AccountDropdown />}
                <ButtonGroup>
                    {!userId && (
                        <StyledLink
                            to="/sign-up"
                            solid={true}
                            className="u-mar-l_auto"
                        >
                            Create account
                        </StyledLink>
                    )}
                    {!userId && (
                        <StyledLink
                            to="/sign-in"
                            solid={true}
                            className="u-mar-r_1"
                        >
                            Sign in
                        </StyledLink>
                    )}
                </ButtonGroup>
            </StyledNavGroup>
        </StyledNav>
    )
}

export default NavBar
