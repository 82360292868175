import React, { forwardRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { inputStyle, inputStyle_focus } from '../../globalStyles'
import IconButton from '../atoms/IconButton'

const StyledInput = styled.input`
    ${(props) =>
        props.size &&
        css`
            padding: 8px 12px;
            min-height: unset;
            font-size: 15px;
            border-radius: 6px;
        `}
`

const StyledPasswordContainer = styled.div`
    // ${inputStyle}
    padding: 0;
    display: flex;
    flex-direction: space-between;
    overflow: hidden;

    &:focus-within {
        ${inputStyle_focus}
    }
`

const StyledPasswordInput = styled.input`
    flex: 1;
    padding: 12px 16px;
    border: 0 solid transparent;
    font-size: 16px;
    font-family: var(--fontFamily), sans-serif;

    &:focus,
    &:hover {
        outline: none;
    }
`

export const Input = forwardRef(
    (
        {
            removeBaseStyling,
            showPasswordOption,
            style,
            className,
            type,
            ...rest
        },
        ref
    ) => {
        const [showPassword, setShowPassword] = useState(false)

        if (showPasswordOption) {
            return (
                <StyledPasswordContainer className={className} style={style}>
                    <StyledPasswordInput
                        {...rest}
                        type={showPassword ? 'text' : 'password'}
                    />
                    <IconButton
                        type="button"
                        circle={true}
                        data-tooltip={showPassword ? 'Hide' : 'Show'}
                        whiteTransparent={true}
                        icon={showPassword ? 'eye' : 'eye-slash'}
                        onClick={() => setShowPassword(!showPassword)}
                    />
                </StyledPasswordContainer>
            )
        } else {
            return (
                <StyledInput
                    style={style}
                    className={`${className} ${removeBaseStyling || 'a-input'}`}
                    ref={ref}
                    type={type}
                    {...rest}
                />
            )
        }
    }
)

// Input.defaultProps = {
//     type : "text",
//     placeholder : null,
//     value: null,
//     onChange: null,
// }

export default Input
