import React, { useContext, useEffect, useState } from 'react'
import { loopThroughMemberBlueprint } from '../assets/js/utils'
import MemberContext from '../contexts/MemberContext'
import toaster from 'toasted-notes'
import firebase from 'firebase/app'
import InputGroup from '../components/organisms/InputGroup'
import styled from 'styled-components'
import Select from '../components/atoms/Select'

const StyledFieldset = styled.fieldset`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-bottom: 48px;
`

const StyledDateSet = styled.fieldset`
    display: grid;
    grid-template-columns: 2.5fr 1.5fr 2fr;
    gap: 16px;
    margin-bottom: 48px;
`

const StyledSelect = styled(Select)`
    width: 100%;
`

export const useProfileForm = (profileData, treeId = null, leafId = null) => {
    const [inputElements, setInputElements] = useState([])
    const { memberData } = useContext(MemberContext)
    const [editMode, setEditMode] = useState(false)

    const submitForm = (e, afterSubmit, isMember = false) => {
        e.preventDefault()
        let obj = {}

        if (e.target['display_name'].value === '') {
            alert('Display name cannot be blank.')
        } else {
            const memberRef =
                isMember &&
                firebase.firestore().collection('members').doc(memberData?.id)
            const treeRef =
                !isMember &&
                firebase.firestore().collection('trees').doc(treeId)
            const leafRef =
                !isMember && treeRef.collection('leaves').doc(leafId)

            const ref = isMember ? memberRef : leafRef

            const firebaseObj = (key, value) => {
                let pathName = value['pathName']
                obj[pathName] = e.target[pathName].value || null
            }

            loopThroughMemberBlueprint({
                functionCall: firebaseObj,
            })

            ref.update(obj)
                .then(() => {
                    toaster.notify('Profile updated!')
                    afterSubmit()
                })
                .catch((err) => {
                    toaster.notify(err.message)
                })

            setEditMode(false)
        }
    }

    useEffect(() => {
        if (profileData) {
            // let inputDataArray = {};

            let fieldsetName = []
            let fieldsetAddress = []
            let fieldsetContact = []
            let fieldsetBirthday = []
            let fieldsetDeathdate = []
            let fieldsetOther = []
            let fieldsetGroup = []

            const createInputItems = (key, value) => {
                const dataType = value.dataType
                const category = value.category
                const pathName = value.pathName
                const data = profileData[pathName] || null
                let fieldsetArray

                switch (category) {
                    case 'name':
                        fieldsetArray = fieldsetName
                        break
                    case 'address':
                        fieldsetArray = fieldsetAddress
                        break
                    case 'contact':
                        fieldsetArray = fieldsetContact
                        break
                    case 'birthday':
                        fieldsetArray = fieldsetBirthday
                        break
                    case 'deathdate':
                        fieldsetArray = fieldsetDeathdate
                        break
                    default:
                        fieldsetArray = fieldsetOther
                        break
                }

                if (dataType === 'select') {
                    fieldsetArray.push(
                        <StyledSelect
                            name={pathName}
                            label={key}
                            key="gender"
                            // onChange={() => setChangesMade(true)}
                            defaultValue={data || ''}
                        >
                            <option value="" key="Choose...">
                                Choose...
                            </option>
                            {value.selectOptions.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </StyledSelect>
                    )
                } else if (dataType === 'tel') {
                    fieldsetArray.push(
                        <InputGroup
                            key={pathName}
                            name={pathName}
                            phone={true}
                            // onChange={() => setChangesMade(true)}
                            defaultValue={data || ''}
                            type={dataType}
                            label={key}
                            message={value.message || null}
                        />
                    )
                } else {
                    fieldsetArray.push(
                        <InputGroup
                            key={pathName}
                            name={pathName}
                            // onChange={() => setChangesMade(true)}
                            defaultValue={data || ''}
                            type={dataType}
                            label={key}
                            message={value.message || null}
                        />
                    )
                }

                fieldsetGroup = [
                    <StyledFieldset key="name">{fieldsetName}</StyledFieldset>,
                    <StyledFieldset key="address">
                        {fieldsetAddress}
                    </StyledFieldset>,
                    <StyledFieldset key="contact">
                        {fieldsetContact}
                    </StyledFieldset>,
                    <StyledDateSet key="birthday">
                        {fieldsetBirthday}
                    </StyledDateSet>,
                    <StyledFieldset key="other">
                        {fieldsetOther}
                    </StyledFieldset>,
                    <StyledDateSet key="deathdate">
                        {fieldsetDeathdate}
                    </StyledDateSet>,
                ]

                setInputElements(fieldsetGroup)
                // inputDataArray[pathName] = data;
            }

            loopThroughMemberBlueprint({
                functionCall: createInputItems,
            })
        }
    }, [profileData])

    return [inputElements, submitForm]
}

export default useProfileForm
