import React, {
    forwardRef,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import LeavesContext from '../../contexts/LeavesContext'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import styled from 'styled-components'
import { PanZoom } from 'react-easy-panzoom'
import CurrentTreeMembersContext from '../../contexts/CurrentTreeMembersContext'
import Leaf from '../atoms/Leaf'
import useModal from '../../hooks/useModal'
import ProfileModal from './ProfileModal'
import RemoveLeaf from './DetailsPanel/RemoveLeaf'
import { APART_RELATIONSHIPS } from '../../assets/js/utils'
import useBranchHierarchy from '../../hooks/useBranchHierarchy'
import useTreeMembers from '../../hooks/useTreeMembers'
import Button from '../atoms/Button'
import useCurrentMemberPermission from '../../hooks/useCurrentMemberPermission'
import ViewPreferenceContext from '../../contexts/ViewPreferenceContext'

const StyledBranch = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin: 0 8px;

    &:hover {
        cursor: grab;
    }
`

const StyledDescendantsContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const StyledPartnersContainer = styled.div`
    display: flex;
    flex-direction: row;

    &:hover + .descendants {
        .leaf_connections {
            z-index: -1;
            path {
                transition: all 0.1s ease-in-out;
                stroke: var(--color_outline);
                stroke-width: 4;
            }
        }
    }
`

const StyledTogetherContainer = styled.div`
    display: flex;
`

const StyledApartLeaves = styled.div`
    display: flex;
`

const StyledPanZoom = styled(PanZoom)`
    display: inline-flex;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 40px;
    z-index: 0;
    transition: padding-left var(--speed) ease-out;

    &:focus {
        outline: none;
    }
`

export const Branch = forwardRef((props, ref) => {
    const { leaves } = useContext(LeavesContext)
    const { panelState } = useContext(DetailsPanelContext)
    const [treeEl, setTreeEl] = useState([''])
    const [renderLines, setRenderLines] = useState(false)
    const [toggleProfileModal, isProfileModalShowing] = useModal(false)
    const [toggleDeleteModal, isDeleteModalShowing] = useModal(false)
    const { hierarchy } = useBranchHierarchy(leaves)
    const { treeMembers } = useTreeMembers()
    const { viewPreference } = useContext(ViewPreferenceContext)

    console.log('Branch is rendering')

    const clearConnectionLines = () => {
        let svgs = document.querySelectorAll('.leaf_connections')
        for (let svg of svgs) {
            svg.remove()
        }
    }

    const createSVG = (fromEl, toEl, relationshipType, strokeStyle) => {
        if (!toEl || !fromEl) {
            return
        } else {
            let target = toEl.getBoundingClientRect()
            let source = fromEl.getBoundingClientRect()
            let sourceStyle = getComputedStyle(fromEl)
            let sourceWidth = fromEl.offsetWidth
            let height = fromEl.offsetHeight
            let halfSourceWidth = sourceWidth / 2
            let halfSourceHeight = height / 2
            let sourceMarginTop = parseInt(sourceStyle.marginTop)
            let sourceMarginBottom = parseInt(sourceStyle.marginBottom)
            let distanceDif
            let path
            let arcValue = 8
            let goLeft = source.x > target.x
            if (goLeft) {
                distanceDif = -1 * (source.x - target.x) + halfSourceWidth
            } else {
                distanceDif = target.x - source.x + halfSourceWidth
            }
            let sourceArcX = goLeft
                ? `${halfSourceWidth - arcValue}`
                : `${halfSourceWidth + arcValue}`
            let targetArcX = goLeft
                ? `${distanceDif + arcValue + 8}`
                : `${distanceDif - arcValue - 8}`
            let sourceArcDirection = goLeft ? '1' : '0'
            let targetArcDirection = goLeft ? '0' : '1'

            let targetStartY = goLeft
                ? distanceDif + arcValue
                : distanceDif - arcValue

            let fromleafId = fromEl.getAttribute('data-id')
            let toLeafId = toEl.getAttribute('data-id')

            let d

            if (relationshipType === 'child') {
                if (source.x === target.x) {
                    d = `M${halfSourceWidth} ${
                        source.height - sourceMarginBottom
                    } v${source.height}`
                } else {
                    d = `
                    M${halfSourceWidth} ${height - sourceMarginBottom / 2}
                    L${halfSourceWidth} ${height + sourceMarginTop - arcValue}
                    A${arcValue} ${arcValue} 0 0 ${sourceArcDirection} ${sourceArcX} ${
                        height + sourceMarginTop
                    }
                    L${targetStartY} ${height + sourceMarginTop}
                    A${arcValue} ${arcValue} 0 0 ${targetArcDirection} ${targetArcX} ${
                        height + sourceMarginTop * 4
                    }
                `
                }

                //     L${distanceDif} ${height + sourceMarginTop}
                //     L${distanceDif} ${height + sourceMarginTop * 4}

                // d = `
                //     M${halfSourceWidth} ${
                //     height - sourceMarginBottom / 2
                // } A8 8 90 0 1 ${distanceDif} ${height + sourceMarginTop * 4}
                //     `
            } else if (relationshipType === 'partner') {
                d = `M${halfSourceWidth} ${halfSourceWidth} L${distanceDif} ${halfSourceWidth}`
            }

            let xmlns = 'http://www.w3.org/2000/svg'

            let svgElem = document.createElementNS(xmlns, 'svg')
            svgElem.setAttributeNS(
                null,
                'viewBox',
                '0 0 ' + sourceWidth + ' ' + sourceWidth
            )
            svgElem.setAttributeNS(null, 'width', sourceWidth)
            svgElem.setAttributeNS(null, 'height', sourceWidth)
            svgElem.setAttributeNS(null, 'class', 'leaf_connections')
            svgElem.setAttributeNS(null, 'data-from-leaf', fromleafId)
            svgElem.setAttributeNS(null, 'data-to-leaf', toLeafId)

            // svgElem.setAttributeNS(null, "preserveAspectRatio", "none");

            let svgNS = 'http://www.w3.org/2000/svg'
            path = document.createElementNS(svgNS, 'path')
            path.setAttributeNS(null, 'd', d)

            if (strokeStyle === 'dashed') {
                path.setAttributeNS(null, 'stroke-dasharray', '4 4')
            }

            svgElem.appendChild(path)
            fromEl.appendChild(svgElem)
        }
    }

    const apartArray = ['Separated', 'Divorced', 'Widowed']
    const unrelatedArray = ['Unrelated']

    const iterateOverConnections = (leaf) => {
        let parentEl = document.querySelector(`[data-id="${leaf.id}"]`)
        if (leaf.children.length > 0) {
            for (let childLeaf of leaf.children) {
                if (!unrelatedArray.includes(childLeaf.relationship)) {
                    let childEl = document.querySelector(
                        `[data-id="${childLeaf.id}"]`
                    )

                    createSVG(parentEl, childEl, 'child')
                    iterateOverConnections(childLeaf)
                }
            }
        }

        if (leaf.partners.length > 0) {
            for (let partnerLeaf of leaf.partners) {
                let partnerEl = document.querySelector(
                    `[data-id="${partnerLeaf.id}"]`
                )
                let apart = apartArray.includes(partnerLeaf.relationship)

                createSVG(parentEl, partnerEl, 'partner', apart && 'dashed')

                if (partnerLeaf.children.length !== 0) {
                    for (let child of partnerLeaf.children) {
                        if (!unrelatedArray.includes(child.relationship)) {
                            let childEl = document.querySelector(
                                `[data-id="${child.id}"]`
                            )

                            createSVG(partnerEl, childEl, 'child')
                        }
                    }
                }
            }
        }
    }

    hierarchy.length > 0 && iterateOverConnections(hierarchy[0])

    useEffect(() => {
        const generateBranch = (member) => {
            let childLeaves = []
            let partnerApartLeaves = []
            let partnerTogetherLeaves = []
            let mainDoc = (
                <Leaf
                    key={member.id}
                    toggleProfileModal={toggleProfileModal}
                    name={member.display_name || null}
                    leafId={member.id}
                />
            )

            if (member.children.length > 0) {
                for (const childLeaf of member.children) {
                    let descendants = generateBranch(childLeaf)

                    descendants &&
                        childLeaves.push(
                            <StyledBranch key={childLeaf.id}>
                                {descendants}
                            </StyledBranch>
                        )
                }
            }

            if (member.partners.length > 0) {
                for (const partnerLeaf of member.partners) {
                    if (member.id !== partnerLeaf.id) {
                        if (
                            APART_RELATIONSHIPS.includes(
                                partnerLeaf.relationship
                            )
                        ) {
                            partnerApartLeaves.push(
                                <Leaf
                                    toggleProfileModal={toggleProfileModal}
                                    key={partnerLeaf.id}
                                    name={partnerLeaf?.display_name}
                                    leafId={partnerLeaf.id}
                                />
                            )
                        } else {
                            partnerTogetherLeaves.push(
                                <Leaf
                                    toggleProfileModal={toggleProfileModal}
                                    key={partnerLeaf?.id}
                                    name={partnerLeaf?.display_name}
                                    leafId={partnerLeaf?.id}
                                />
                            )
                        }
                    }
                }
            }

            return (
                <StyledBranch key={hierarchy[0].id}>
                    <StyledPartnersContainer>
                        <StyledTogetherContainer>
                            {partnerTogetherLeaves}
                            {mainDoc}
                        </StyledTogetherContainer>
                        <StyledApartLeaves>
                            {partnerApartLeaves}
                        </StyledApartLeaves>
                    </StyledPartnersContainer>

                    <StyledDescendantsContainer>
                        {childLeaves}
                    </StyledDescendantsContainer>
                </StyledBranch>
            )
        }

        hierarchy.length !== 0 && setTreeEl(generateBranch(hierarchy[0]))
        setRenderLines(true)
    }, [treeMembers, hierarchy])

    useEffect(() => {
        if (renderLines || !viewPreference.table) {
            clearConnectionLines()
            hierarchy.length === 0 || iterateOverConnections(hierarchy[0])
            setRenderLines(false)
        }
    }, [renderLines, viewPreference])

    return (
        <>
            <StyledPanZoom
                ref={ref}
                enableBoundingBox={true}
                maxZoom={1.2}
                minZoom={0.35}
                zoomSpeed={1.5}
                autoCenterZoomLevel={0.5}
                disableDoubleClickZoom={true}
                realPinch={true}
                // moveByRatio={{x:30, y:30}}
                // autoCenter={true}
                // disableScrollZoom={true}
            >
                {leaves.length !== 0 && treeEl}
            </StyledPanZoom>

            {isProfileModalShowing && (
                <ProfileModal
                    isMember={panelState?.linkedMemberDoc ? true : false}
                    isModalShowing={isProfileModalShowing}
                    hide={toggleProfileModal}
                />
            )}
            {isDeleteModalShowing && (
                <RemoveLeaf
                    isModalShowing={isDeleteModalShowing}
                    hide={toggleDeleteModal}
                />
            )}
        </>
    )
})

export default Branch
