import React, { useState, useContext } from 'react'
import Button from '../atoms/Button'
import Modal from '../organisms/Modal'
import Card from '../organisms/Card'
import ButtonGroup from '../organisms/ButtonGroup'
import styled from 'styled-components'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import firebase from 'firebase/app'
import { getLeafImage, getPlaceholderImage } from '../../assets/js/utils'
import Avatar from '../atoms/Avatar'
import MemberContext from '../../contexts/MemberContext'
import toaster from 'toasted-notes'
import { LeafImagesContext } from '../../contexts/LeafImageContext'
import { useParams } from 'react-router'

const StyledAvatar = styled(Avatar)`
    margin: 0 auto 24px auto;
`

const StyledInput = styled.input`
    margin: 0 auto;
    display: block;
`

export const UploadPhotoModal = ({
    hide,
    isModalShowing,
    docType,
    hideRemovePhoto = false,
    panelState = null,
    ...rest
}) => {
    // const {panelState} = useContext(DetailsPanelContext);
    const { memberData, setMemberPhoto } = useContext(MemberContext)
    const [newPhotoPreview, setNewPhotoPreview] = useState(null)
    const { treeId } = useParams()
    // const {leafImages, setLeafImages, getImageUrlById} = useContext(LeafImagesContext);

    const handleSubmit = async (e) => {
        e.preventDefault()
        const form = e.target
        let storageRef = firebase.storage().ref()
        let newFile = form['photoInput'].files[0]
        let fileName = newFile.name
        let extension = fileName.split('.').pop()

        let leafProfilePhotoRef =
            panelState?.leafId &&
            storageRef.child(
                `trees/${treeId && treeId}/${
                    panelState.leafId
                }/profile.${extension}`
            )
        let memberProfilePhotoRef = storageRef.child(
            `members/${memberData.id}/profile.${extension}`
        )
        let photoRef =
            docType === 'member' ? memberProfilePhotoRef : leafProfilePhotoRef
        let snapshot = await photoRef.put(newFile)

        let ref =
            docType === 'member'
                ? firebase.firestore().collection('members').doc(memberData?.id)
                : firebase
                      .firestore()
                      .collection('trees')
                      .doc(panelState?.treeId)
                      .collection('leaves')
                      .doc(panelState?.leafId)

        if (snapshot) {
            ref.update({ profile_photo: snapshot.metadata.fullPath }).then(
                () => {
                    toaster.notify('Profile photo updated')

                    let photoRef = firebase
                        .storage()
                        .ref(snapshot.metadata.fullPath)

                    const downloadProfileImage = async () => {
                        console.log('downloading images...')
                        let url = await photoRef.getDownloadURL()
                        if (docType === 'member') {
                            setMemberPhoto(url)
                            // sessionStorage.setItem(memberData.id, url)
                        } else {
                            // const newLeafImages = leafImages.map(leaf => {
                            //     let leafDoc = leaf;
                            //     if (leaf.id === panelState.leafId) {
                            //         leafDoc.url = url;
                            //     }
                            //     return leafDoc;
                            // });
                            // setLeafImages(newLeafImages);
                        }
                        // window.location.reload();
                    }

                    downloadProfileImage()
                    handleClose()
                }
            )
        }
    }

    const handleClose = (e) => {
        setNewPhotoPreview(null)
        hide()
    }

    const handleUpload = async (e) => {
        let files = e.target.files

        if (files.length > 0) {
            const reader = new FileReader()
            const file = files[0]

            reader.addEventListener(
                'load',
                function () {
                    // convert image file to base64 string
                    setNewPhotoPreview(reader.result)
                },
                false
            )

            if (file) {
                reader.readAsDataURL(file)
            }
        }
    }

    const handleRemovePhoto = () => {
        let leafRef
        let memberRef = firebase
            .firestore()
            .collection('members')
            .doc(memberData.id)
        let id = docType === 'member' ? memberData.id : panelState.leafId

        if (docType !== 'member') {
            leafRef = firebase
                .firestore()
                .collection('trees')
                .doc(panelState.treeId)
                .collection('leaves')
                .doc(panelState.leafId)
        }

        let ref = docType === 'member' ? memberRef : leafRef

        ref.update({ profile_photo: null })
            .then(() => {
                sessionStorage.removeItem(id)

                if (docType !== 'member') {
                    const placeholderImage = getPlaceholderImage(id)
                    const leafEl = document.querySelector(`[data-id='${id}']`)
                    const leafImageEl = leafEl.querySelector('.leaf__image')
                    leafImageEl.style.backgroundImage = `url("${placeholderImage}")`
                } else {
                    window.location.reload()
                }

                toaster.notify('Profile photo removed')
                handleClose()
            })
            .catch((err) => {
                console.log(err.message)
            })
    }

    return (
        <>
            <Modal
                isShowing={isModalShowing}
                hide={handleClose}
                size="medium"
                closeButton={true}
                {...rest}
            >
                <Card.Container as="form" onSubmit={(e) => handleSubmit(e)}>
                    <Card.Header
                        title="Change profile photo"
                        titleAs="h2"
                        align="center"
                    />
                    <Card.Content>
                        {/* <StyledAvatar size="l" src={newPhotoPreview || getImageUrlById(panelState.leafId)}/> */}
                        <StyledInput
                            id="photoInput"
                            required
                            accept="image/*"
                            onChange={(e) => handleUpload(e)}
                            type="file"
                        />
                    </Card.Content>
                    <Card.Footer>
                        <ButtonGroup>
                            {hideRemovePhoto || (
                                <Button
                                    danger={true}
                                    whiteTransparent={true}
                                    type="button"
                                    onClick={() => handleRemovePhoto()}
                                >
                                    Remove profile photo
                                </Button>
                            )}
                            <Button
                                type="button"
                                onClick={handleClose}
                                outline={true}
                            >
                                Cancel
                            </Button>
                            <Button type="submit">Set photo</Button>
                        </ButtonGroup>
                    </Card.Footer>
                </Card.Container>
            </Modal>
        </>
    )
}

export default UploadPhotoModal
