import React, { useContext, useEffect, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import MemberContext from '../contexts/MemberContext'
import { UserContext } from '../contexts/UserContext'

export const useLeaves = (treeId) => {
    const { userId } = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [leaves, setLeaves] = useState([])
    const { memberData, setClaimedLeaf } = useContext(MemberContext)

    const getLeaves = () => {
        const leavesRef = firebase
            .firestore()
            .collection('trees')
            .doc(treeId)
            .collection('leaves')
        setLoading(true)
        setError(false)

        leavesRef.onSnapshot(
            (snapshot) => {
                if (snapshot.empty) {
                    setLeaves([])
                    setLoading(false)
                }

                console.log(userId)
                setLeaves(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        profile_photo_url: null,
                        ...doc.data(),
                    }))
                )

                setLoading(false)
            },
            (error) => {
                setLoading(false)
                setError(error.message)
            }
        )
    }

    useEffect(() => {
        if (memberData?.id) {
            console.log(leaves)
            leaves.map((leaf) => {
                if (leaf.claimed_by === memberData.id) {
                    setClaimedLeaf(leaf.id)
                }
            })
        }
    }, [memberData, leaves])

    useEffect(() => {
        treeId && getLeaves()
    }, [treeId, userId])

    return { getLeaves, leaves, loading, error }
}

export default useLeaves
