import React from 'react'
import styled from 'styled-components'
import Input from './Input'

const StyledCheckboxContainer = styled.div`
    --checkboxtransition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    --checkboxSize: 32px;

    display: flex;
    position: relative;

    input[type='checkbox'] {
        position: absolute;
        visibility: hidden;
        display: none;

        &:checked ~ label:before {
            content: '\f00c';
            box-shadow: 0 0 0 2px var(--color_10_dark) inset;
            font-size: 18px;
            transition: var(--checkboxtransition);
        }
    }

    input[type='radio'] {
        position: absolute;
        visibility: hidden;
        display: none;

        &:checked ~ label:before {
            content: '\f00c';
            box-shadow: 0 0 0 2px var(--color_10_dark) inset;
            font-size: 18px;
            transition: var(--checkboxtransition);
        }
    }

    &.right {
        justify-content: flex-end;

        label {
            padding-left: unset;
            padding-right: var(--checkboxSize) + 16px;
            text-align: right;

            &:before {
                left: unset;
                right: 0;
                margin-right: unset;
                margin-left: 8px;
            }
        }
    }
`

const StyledLabel = styled.label`
    padding-left: calc(var(--checkboxSize) + 16px);
    position: relative;
    min-height: var(--checkboxSize);
    padding-top: 8px;

    &:before {
        content: '';
        font-weight: 400;
        font-family: 'Font Awesome 5 Pro';
        width: var(--checkboxSize);
        height: var(--checkboxSize);
        line-height: var(--checkboxSize);
        border-radius: ${(props) => (props.type === 'radio' ? '800px' : '8px')};
        box-shadow: 0 0 0 2px var(--color_border) inset;
        margin-right: 8px;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0px;
        transition: var(--checkboxtransition);
        color: var(--color_10_dark);
    }

    &:hover {
        cursor: pointer;

        &:before {
            box-shadow: 0 0 0 2px var(--color_10_dark) inset;
        }
    }
`

export const Checkbox = ({
    id,
    label,
    onChange,
    isChecked,
    name,
    type,
    ...rest
}) => {
    return (
        <StyledCheckboxContainer {...rest}>
            <Input
                id={id}
                type={type || 'checkbox'}
                value=""
                name={name || id}
                checked={isChecked}
                onChange={(e) => onChange && onChange(e)}
            />
            <StyledLabel type={type} htmlFor={id}>
                {label}
            </StyledLabel>
        </StyledCheckboxContainer>
    )
}

export default Checkbox
