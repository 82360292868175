import React, { useState, createContext, useEffect } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'

export const UserContext = createContext()

export const UserContextProvider = (props) => {
    let authToken = sessionStorage.getItem('authToken')
    const [userId, setUserId] = useState(authToken)
    const [user, loading, error] = useAuthState(firebase.auth())

    useEffect(() => {
        if (!loading && user) {
            setUserId(user.uid)
            sessionStorage.setItem('authToken', user.uid)
        } else {
            sessionStorage.removeItem('authToken')
            setUserId(null)
        }
    }, [loading])

    return (
        <UserContext.Provider value={{ userId, setUserId, user, loading }}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserContextProvider
