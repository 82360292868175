import React from 'react'
import styled from 'styled-components'
import { headerDividerStyle } from '../../../globalStyles'
import Icon from '../../atoms/Icon'
import IconButton from '../../atoms/IconButton'
import toaster from 'toasted-notes'
import ButtonGroup from '../../organisms/ButtonGroup'

const StyledCopyButton = styled(IconButton)`
    color: var(--color_text_600);
`

const StyledItemAction = styled(IconButton)`
    color: var(--color_text_600);

    a {
        color: var(--color_text_600);
    }
`

const StyledActionsGroup = styled(ButtonGroup)`
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
`

const StyledLeafProfilItem = styled.li`
    --leaf_profile__label: var(--color_text_600);
    --leaf_profile__paragraph: var(--color_text_700);
    display: flex;
    width: 100%;
    border-radius: 8px;
    padding: 8px;
    margin-left: -8px;
    margin-right: -8px;

    &:hover {
        --leaf_profile__label: var(--color_text_900);
        --leaf_profile__paragraph: var(--color_text_900);
        // background-color: var(--color_background_depth_5);
    }
`

const StyledParagraph = styled.div`
    margin: 0;
    color: var(--leaf_profile__paragraph);
    font-size: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`

const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: ${(props) => props.labelWidth || '50%'} 1fr;
    gap: 24px;
    width: 100%;
    align-items: flex-start;
    justify-items: space-between;
`

const StyledLabel = styled.div`
    margin-bottom: 4px;
    color: var(--leaf_profile__label);
`

const StyledIcon = styled(Icon)`
    margin-right: 16px;
    color: var(--color_accent);
    font-size: 15px;
    text-align: center;
`

const ActionItem = ({ href, icon, tooltip, ...rest }) => {
    return (
        <StyledItemAction
            size="m"
            circle={true}
            data-tooltip={tooltip}
            data-tooltip-position="top middle"
            whiteTransparent={true}
        >
            <a tabIndex="-1" href={href} target="_blank">
                <Icon icon={icon} />
            </a>
        </StyledItemAction>
    )
}

const copyToClipboard = (clipboardData, itemName) => {
    navigator.clipboard
        .writeText(clipboardData)
        .then(() => {
            toaster.notify(`${itemName} copied to clipboard`)
        })
        .catch((err) => {
            console.log('Something went wrong', err)
        })
}

export const LeafProfileItem = ({
    data,
    showActions = false,
    children,
    fullWidth,
    labelWidth,
    ...rest
}) => {
    let actionItem = []

    switch (data?.dataFormat) {
        case 'url':
            actionItem = data.value.includes('http') && [
                <ActionItem
                    tooltip="Visit"
                    icon="external-link"
                    href={data.value}
                />,
            ]
            break
        case 'phone':
            actionItem = [
                <ActionItem
                    tooltip="Text"
                    icon="comment"
                    href={`sms:${data.value}`}
                />,
                <ActionItem
                    tooltip="Call"
                    icon="phone"
                    href={`tel:${data.value}`}
                />,
            ]
            break
        case 'email':
            actionItem = [
                <ActionItem
                    tooltip="Email"
                    icon="envelope"
                    href={`mailTo:${data.value}`}
                />,
            ]
            break
        default:
            break
    }

    return (
        <StyledLeafProfilItem {...rest}>
            <StyledContainer labelWidth={labelWidth}>
                <StyledLabel>
                    <StyledIcon
                        fullWidth={true}
                        iconType="regular"
                        icon={data?.icon}
                    />
                    {data?.label}
                </StyledLabel>
                <StyledParagraph>
                    {children ? children : data?.value}
                    {showActions && (
                        <StyledActionsGroup gap="0">
                            {actionItem}
                            <StyledCopyButton
                                data-tooltip="Copy"
                                data-tooltip-position="top middle"
                                size="m"
                                fontSize="15px"
                                icon="copy"
                                whiteTransparent={true}
                                circle={true}
                                onClick={() =>
                                    showActions &&
                                    copyToClipboard(data?.value, data?.label)
                                }
                            />
                        </StyledActionsGroup>
                    )}
                </StyledParagraph>
            </StyledContainer>
        </StyledLeafProfilItem>
    )
}

export default LeafProfileItem
