import React from 'react'
import NavBar from '../organisms/NavBar'

export const DefaultLayout = ({ children, ...rest }) => {
    return (
        <>
            <NavBar />
            {children}
        </>
    )
}

export default DefaultLayout
