import { useState, useEffect } from 'react'

const useModal = () => {
    const [isModalShowing, setIsModalShowing] = useState(false)

    function toggleModal() {
        setIsModalShowing(!isModalShowing)
    }

    useEffect(() => {
        let modals = document.querySelectorAll(`[aria-modal]`)
        isModalShowing && 
            document.body.classList.add('scroll-locked')
        !isModalShowing &&
            modals.length === 0 &&
            document.body.classList.remove('scroll-locked')
    }, [isModalShowing])

    return [toggleModal, isModalShowing]
}

export default useModal
