import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    buildBirthdayString,
    buildDeathDateString,
    buildFullAddressString,
    convertDate,
    getLeafImage,
} from '../../assets/js/utils'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import useModal from '../../hooks/useModal'
import Avatar from '../atoms/Avatar'
import Button from '../atoms/Button'
import Header from '../atoms/Header'
import Icon from '../atoms/Icon'
import ButtonGroup from '../organisms/ButtonGroup'
import Dropdown from '../organisms/Dropdown'
import LinkToMemberModal from '../organisms/LinkToMemberModal'
import RemoveLeaf from './DetailsPanel/RemoveLeaf'
import ProfileModal from './ProfileModal'
import firebase from 'firebase/app'
import useCurrentMemberPermission from '../../hooks/useCurrentMemberPermission'
import useUnlinkLeaf from '../../hooks/useUnlinkLeaf'
import useProfileData from '../../hooks/useProfileData'
import { UserContext } from '../../contexts/UserContext'
import { Link } from 'react-router-dom'

const StyledCard = styled.div`
    margin: 0;
    // border-radius: 16px;
    // background-color: var(--color_background_depth_1);
    position: relative;
    // overflow: hidden;
    padding: 24px 0 32px 0;
    display: flex;
    flex-direction: column;
    // border-bottom: 1px solid var(--color_border);
    // box-shadow: var(--shadow_2);
`

const StyledProfileList = styled.ul`
    display: inline-grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
`

const StyledProfileItem = styled.li`
    display: flex;
    padding: 4px 0;
    font-size: 14px;
    color: var(--color_text_900);
`

const StyledEmptyMessage = styled.div`
    text-align: center;
    padding: 16px;
    border-radius: 8px;
    font-size: 13px;
    color: var(--color_text_600);
    background-color: var(--color_background_depth_4);
`

const StyledProfileItemIcon = styled(Icon)`
    --profileItemIconSize: 16px;
    margin-right: 24px;
    margin-top: 2px;
    max-width: var(--profileItemIconSize);
    width: var(--profileItemIconSize);
    min-width: var(--profileItemIconSize);
    max-height: var(--profileItemIconSize);
    height: var(--profileItemIconSize);
    min-height: var(--profileItemIconSize);
    font-size: 16px;
    text-align: center;
    color: var(--color_text_900);
`

const StyledAvatarNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 16px;
    margin-bottom: 24px;
`

const StyledNameProfileButtonContainer = styled.div`
    margin-left: 4px;
`

const StyledHeader = styled(Header)`
    margin-top: 8px;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
`

const LeafProfileCard = ({ showProfileEditModal }) => {
    const { panelState } = useContext(DetailsPanelContext)
    const [profileItems, setProfileItems] = useState([])
    const [toggleProfileModal, isProfileModalShowing] = useModal(false)
    const [toggleLinkToLeafModal, isLinkToLeafModalShowing] = useModal(false)
    const [toggleDeleteModal, isDeleteModalShowing] = useModal(false)
    const [editMode, setEditMode] = useState(false)
    const { userId } = useContext(UserContext)
    const [
        isAdmin,
        isContributor,
        isViewer,
        memberPermission,
        isCreatorOfLeaf,
    ] = useCurrentMemberPermission([])
    const [unlinkLeaf, loading, error] = useUnlinkLeaf()
    const { profile, summary } = useProfileData()

    useEffect(() => {
        const generateStyledItem = (icon, value) => {
            return (
                <StyledProfileItem key={icon}>
                    <StyledProfileItemIcon icon={icon} />
                    {value}
                </StyledProfileItem>
            )
        }
        setProfileItems(
            summary.map((item) => {
                if (item?.value && item?.icon) {
                    return generateStyledItem(item.icon, item.value)
                }
            })
        )
    }, [panelState.leafId, summary])

    const showProfileModal = (isEditMode) => {
        setEditMode(isEditMode)
        toggleProfileModal()
    }

    const hiThere = () => {
        alert('k')
    }

    return (
        <>
            <StyledCard>
                <div className="u-bold u-mar-b_3">Summary</div>
                {profileItems.length > 0 ? (
                    <StyledProfileList>
                        {profileItems.map((item) => item)}
                        <StyledProfileItem>
                            {profileItems.length > 0 && (
                                <StyledProfileItemIcon />
                            )}
                        </StyledProfileItem>
                    </StyledProfileList>
                ) : (
                    <>
                        {userId ? (
                            <StyledEmptyMessage>
                                No summary information.{' '}
                                {(isAdmin ||
                                    (isContributor &&
                                        isCreatorOfLeaf(
                                            panelState.leafId
                                        ))) && (
                                    <span
                                        className="a-link a-link--hover"
                                        onClick={() => showProfileEditModal()}
                                    >
                                        Edit Profile.
                                    </span>
                                )}
                            </StyledEmptyMessage>
                        ) : (
                            <StyledEmptyMessage>
                                <Link to="/sign-up">Join Mily</Link> to view
                                summary information.
                            </StyledEmptyMessage>
                        )}
                    </>
                )}
                <>
                    {isProfileModalShowing && (
                        <ProfileModal
                            isModalShowing={isProfileModalShowing}
                            editMode={editMode}
                            hide={toggleProfileModal}
                        />
                    )}
                    {isDeleteModalShowing && (
                        <RemoveLeaf
                            isModalShowing={isDeleteModalShowing}
                            hide={toggleDeleteModal}
                        />
                    )}
                    {isLinkToLeafModalShowing && (
                        <LinkToMemberModal
                            isModalShowing={isLinkToLeafModalShowing}
                            hide={toggleLinkToLeafModal}
                        />
                    )}
                </>
            </StyledCard>
        </>
    )
}

export default LeafProfileCard
