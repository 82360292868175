import React from 'react'
import Header from '../components/atoms/Header'
import styled from 'styled-components'
import LinkButton from '../components/atoms/LinkButton'
import ButtonGroup from '../components/organisms/ButtonGroup'
import Icon from '../components/atoms/Icon'
import firebase from 'firebase/app'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'

const StyledWrapper = styled.div`
    margin: 0 auto;
    display: flex;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: clamp(16px, 4vw, 80px);
    text-align: center;
    background-color: var(--color_15_dark);
    color: white;
`

const StyledIcon = styled(Icon)`
    margin-bottom: 32px;
`

const StyledCreateButton = styled(LinkButton)`
    margin-top: 16px;
`

const StyledMessage = styled.p`
    font-size: 22px;
    opacity: 0.75;
    max-width: 40ch;
`

const StyledHeader = styled(Header)`
    font-size: 48px;
`

const Styledlink = styled(Link)`
    color: white;
    margin-top: 16px;
    opacity: 0.75;
`

export const Home = () => {
    const [user, loading, error] = useAuthState(firebase.auth())
    sessionStorage.removeItem('invitation')

    if (user) {
        return <Redirect to="/trees" />
    } else {
        return (
            <StyledWrapper>
                <StyledIcon size="64px" icon="tree-alt" />
                <StyledHeader>Stay connected with Family</StyledHeader>
                <StyledMessage>
                    Mily is a family directory visualized as a family tree.
                    Create a family tree and invite family members.
                </StyledMessage>
                <StyledCreateButton to="/sign-up" size="l">
                    Create a Mily account
                </StyledCreateButton>
                <Styledlink to="/sign-in">Or sign in</Styledlink>
            </StyledWrapper>
        )
    }
}

export default Home
