import React, { useContext, useEffect, useState } from 'react'
import CurrentTreeMembersContext from '../contexts/CurrentTreeMembersContext'

export const useTreeMembers = () => {
    const { currentTreeMembers } = useContext(CurrentTreeMembersContext)
    const [memberDoc, setMemberDoc] = useState()
    const [treeMembers, setTreeMembers] = useState(currentTreeMembers)

    const getClaimedBy = (claimedById) => {
        return (
            currentTreeMembers.find((member) => member.id === claimedById) ||
            null
        )
    }

    const setClaimedBy = () => {}

    const removeClaimedBy = () => {}

    useEffect(() => {
        setTreeMembers(currentTreeMembers)
    }, [currentTreeMembers])

    return { treeMembers, getClaimedBy, setClaimedBy, removeClaimedBy }
}

export default useTreeMembers
