import React from 'react'
import styled from 'styled-components'
import AccountDropdown from '../organisms/AccountDropdown'
import SideMenu from './SideMenu'

const StyledPageContainer = styled.div`
    display: flex;
    height: 100%;
    max-height: 100vh;
    min-height: 100vh;
    overflow: hidden;
`

const StyledLeftRailContainer = styled.div`
    width: clamp(280px, 25%, 320px);
    overflow: auto;
    background-color: var(--color_15_dark);
`

const StyledMainContainer = styled.div`
    overflow: auto;
    flex: 1;
    background-color: var(--color_background_depth_3);
`

const StyledAccountDropdownContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    border-bottom: 1px solid var(--color_border);
`

export const DashboardLayout = ({ children, ...rest }) => {
    return (
        <StyledPageContainer>
            <StyledLeftRailContainer>
                <SideMenu />
            </StyledLeftRailContainer>
            <StyledMainContainer>
                <StyledAccountDropdownContainer>
                    <AccountDropdown showEmail={true} />
                </StyledAccountDropdownContainer>
                {children}
            </StyledMainContainer>
        </StyledPageContainer>
    )
}

export default DashboardLayout
