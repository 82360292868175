import React, { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import Header from '../atoms/Header'
import Toggle from '../atoms/Toggle'
import { dropdownStyle } from '../../globalStyles'
import CustomThemeContext from '../../contexts/CustomThemeContext'
import MemberContext from '../../contexts/MemberContext'
import { THEME_COLOR_OPTIONS } from '../../assets/js/globalVars'

const StyledWrapper = styled.div`
    margin: 0 auto;
    max-width: 800px;
    padding: 64px clamp(24px, 2.5vw, 80px);
`

const StyledToggle = styled(Toggle)`
    margin-left: auto;
`

const StyledThemeToggleContainer = styled.div`
    ${dropdownStyle}
    padding: 12px 16px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    flex: 1;
    background-color: var(--color_background_depth_2);

    &:hover,
    &:active {
        background-color: var(--color_background_depth_2);
        cursor: unset;
        color: inherit;
    }
`

const StyledFlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`

const StyledHeader = styled(Header)`
    margin-top: 52px;
    margin-bottom: 48px;
`

const StyledColorOptionContainer = styled.div`
    display: flex;
    margin-left: auto;
`

const StyledColorOption = styled.div`
    --color_opt_size: 32px;
    width: var(--color_opt_size);
    height: var(--color_opt_size);
    border-radius: var(--color_opt_size);
    background-color: ${(props) => props.color};
    margin-left: 8px;
    border: 4px solid var(--color_background_depth_2);
    cursor: pointer;

    &:active {
        transform: scale(.95);
    }

    ${(props) =>
        props.current &&
        css`
            box-shadow: 0 0 0 2px ${(props) => props.color};
        `}}
`

export const SettingsPage = () => {
    const { themeMode, setThemeMode } = useContext(CustomThemeContext)
    const [currentTheme, setCurrentTheme] = useState(themeMode)
    const [currentColoring, setCurrentColoring] = useState(themeMode.color)

    const toggleThemeMode = () => {
        const newMode = currentTheme.mode === 'light' ? 'dark' : 'light'
        const newModeObj = { mode: newMode, color: themeMode.color }
        setThemeMode(newModeObj)
        setCurrentTheme(newModeObj)
    }

    const toggleThemeColor = (colorOption, change = false) => {
        const newModeObj = { mode: themeMode.mode, color: colorOption }
        setThemeMode(newModeObj)
        setCurrentTheme(newModeObj)
        change && setCurrentColoring(colorOption)
    }

    const handleMouseOut = () => {
        toggleThemeColor(currentColoring)
    }

    const handleMouseEnter = (colorOption) => {
        toggleThemeColor(colorOption)
    }

    return (
        <>
            <StyledWrapper>
                <StyledHeader size="m">Preferences</StyledHeader>
                <StyledFlexContainer>
                    <StyledThemeToggleContainer>
                        <label htmlFor="themeToggle">Dark mode</label>
                        <StyledToggle
                            onClick={() => toggleThemeMode()}
                            checked={currentTheme.mode === 'dark'}
                        />
                    </StyledThemeToggleContainer>
                </StyledFlexContainer>
                <StyledFlexContainer>
                    <StyledThemeToggleContainer>
                        <label>Coloring</label>
                        <StyledColorOptionContainer>
                            {THEME_COLOR_OPTIONS.map((opt) => (
                                <StyledColorOption
                                    color={opt.hex}
                                    current={currentColoring === opt.name}
                                    onMouseEnter={() =>
                                        handleMouseEnter(opt.name)
                                    }
                                    onMouseOut={() => handleMouseOut()}
                                    onClick={() =>
                                        toggleThemeColor(opt.name, true)
                                    }
                                ></StyledColorOption>
                            ))}
                        </StyledColorOptionContainer>
                    </StyledThemeToggleContainer>
                </StyledFlexContainer>
            </StyledWrapper>
        </>
    )
}

export default SettingsPage
