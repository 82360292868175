import React, { useEffect, useContext, useState } from 'react'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import firebase from 'firebase/app'
import styled from 'styled-components'
import Button from '../atoms/Button'
import { getLeafIndexFromId } from '../../assets/js/utils'
import Dropdown from '../organisms/Dropdown'
import toaster from 'toasted-notes'
import Icon from '../atoms/Icon'
import Header from '../atoms/Header'

const StyledNotification = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 0;
    width: 100%;
`
const StyledPlaceholderAvatar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 40px;
    border-radius: 8px;
    background-color: var(--brand_600);
    opacity: 0.5;
    font-size: 18px;
    font-weight: 900;
    color: var(--brand_100);
    margin-right: 16px;
`

const StyledCancelButton = styled(Button)`
    margin-left: auto;
`

const StyledEmptyMessage = styled.div`
    padding: 16px;
    text-align: center;
    color: var(--color_text_700);
`

const StyledTextGroup = styled.div`
    margin-right: 16px;
    font-size: 15px;
    flex: 1;
`

const StyledPermission = styled.div`
    font-size: 13px;
    color: var(--color_text_600);
`

export const InvitedMemberList = ({ invitations, ...rest }) => {
    const { panelState } = useContext(DetailsPanelContext)
    const [items, setItems] = useState([])
    const notificationsRef = firebase.firestore().collection('notifications')
    const [notificationUpdate, setNotificationUpdate] = useState(true)

    const handleCancelInvitation = (e, notificationId) => {
        if (
            window.confirm('Are you sure you want to cancel this invitation?')
        ) {
            notificationsRef
                .doc(notificationId)
                .delete()
                .then(() => {
                    window.location.reload()
                })
                .catch((err) => {
                    console.log(err.message)
                })
        }
    }

    const handleNotificationUpdate = (permission, notificationId) => {
        notificationsRef
            .doc(notificationId)
            .update({
                permission_type: permission,
            })
            .then(() => {
                setNotificationUpdate(true)
                toaster.notify(
                    `Invitation changed to ${permission.toLowerCase()}`
                )
            })
    }

    useEffect(() => {
        if (setNotificationUpdate) {
            const notificationsQuery = notificationsRef
                .where('for_tree', '==', panelState.treeId)
                .where('status', '==', 'pending')
            let notificationItems = []

            notificationsQuery.get().then((queryResults) => {
                if (!queryResults.empty) {
                    for (const doc of queryResults.docs) {
                        notificationItems.push(doc)
                    }
                    setItems(notificationItems)
                }
            })
            setNotificationUpdate(false)
        }
    }, [panelState.treeId, notificationUpdate])

    return (
        <>
            {items.length > 0 && <hr className="u-mar-tb_1" />}
            {items.length > 0 && (
                <Header className="u-mar-t_2" size="s">
                    Invitations
                </Header>
            )}

            {items.map((item) => (
                <StyledNotification key={item.id}>
                    <StyledPlaceholderAvatar>
                        <Icon iconType="regular" icon="envelope" />
                        {/* {item.data().for_email.charAt(0).toUpperCase()} */}
                    </StyledPlaceholderAvatar>
                    <StyledTextGroup>
                        {item.data().for_email}
                        <StyledPermission>Invited</StyledPermission>
                    </StyledTextGroup>
                    <Dropdown
                        right="0px"
                        usedInModal={true}
                        trigger={
                            <Button
                                // onClick={toggle}
                                whiteTransparent={true}
                                // disabled={memberData.id === memberId}
                                iconRight="chevron-down"
                            >
                                {item.data().permission_type}
                            </Button>
                        }
                    >
                        {/* <Dropdown.Item onClick={() => toggleInviteModal()}>Link to a leaf</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleUnlinkLeaf(memberId)}>Unlink from leaf</Dropdown.Item> */}
                        <Dropdown.Item
                            size="s"
                            onClick={() =>
                                handleNotificationUpdate('Admin', item.id)
                            }
                            disabled={item.data().permission_type === 'Admin'}
                            iconRight={
                                item.data().permission_type === 'Admin' &&
                                'check'
                            }
                        >
                            Admin
                        </Dropdown.Item>
                        <Dropdown.Item
                            size="s"
                            onClick={() =>
                                handleNotificationUpdate('Contributor', item.id)
                            }
                            disabled={
                                item.data().permission_type === 'Contributor'
                            }
                            iconRight={
                                item.data().permission_type === 'Contributor' &&
                                'check'
                            }
                        >
                            Contributor
                        </Dropdown.Item>
                        <Dropdown.Item
                            size="s"
                            onClick={() =>
                                handleNotificationUpdate('Viewer', item.id)
                            }
                            disabled={item.data().permission_type === 'Viewer'}
                            iconRight={
                                item.data().permission_type === 'Viewer' &&
                                'check'
                            }
                        >
                            Viewer
                        </Dropdown.Item>
                        <Dropdown.Item
                            size="s"
                            danger={true}
                            onClick={(e) => handleCancelInvitation(e, item.id)}
                        >
                            Cancel invitation
                        </Dropdown.Item>
                    </Dropdown>
                </StyledNotification>
            ))}
        </>
    )
}

export default InvitedMemberList
