import React, { useContext, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import useModal from '../../hooks/useModal'
import IconButton from '../atoms/IconButton'
import Dropdown from '../organisms/Dropdown'
import MembersModal from '../organisms/MembersModal'
import InviteModal from './InviteModal'
import firebase, { firestore } from 'firebase/app'
import MemberContext from '../../contexts/MemberContext'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import { CSSTransition } from 'react-transition-group'
import useOnClickOutside from '../../hooks/useOnOutsideClick'
import Button from '../atoms/Button'
import InputGroup from '../organisms/InputGroup'
import ViewPreferenceContext from '../../contexts/ViewPreferenceContext'
import toaster from 'toasted-notes'
import useCurrentMemberPermission from '../../hooks/useCurrentMemberPermission'
import Icon from '../atoms/Icon'
import useDeleteTree from '../../hooks/useDeleteTree'

const speed = 250

const StyledDropdownContainer = styled.div`
    // display: flex;
    // position: absolute;
    // top: calc(4px + var(--iconButtonSize));
    // left: 0;
    // z-index: 9999;
`

const StyledPrimaryContainer = styled.div`
    width: 100%;
    transition: all ${speed}ms var(--bounceEasing);

    &.main-enter {
        position: absolute;
        transform: translateX(-110%);
    }

    &.main-enter-active {
        transform: translateX(0%);
    }

    &.main-exit {
        position: absolute;
        transform: translateX(-110%);
    }

    &.main-exit-active {
        // transform: translateX(-110%);
    }

    &.secondary-enter {
        position: absolute;
        transform: translateX(110%);
    }

    &.secondary-enter-active {
        transform: translateX(0%);
    }

    &.secondary-exit {
        position: absolute;
        transform: translateX(110%);
    }

    &.secondary-exit-active {
        // transform: translateX(0%);
    }
`

const StyledOptionsWrapper = styled.div`
    position: relative;
    width: 220px;
    overflow: hidden;
    transition: all ${speed}ms var(--bounceEasing);
`

const StyledContainer = styled(Dropdown)`
    position: relative;
`

const StyledRenameContainer = styled.div`
    padding: 8px;
`

export const SideMenuTrigger = ({ permission }) => {
    const [toggleInviteModal, isInviteModalShowing] = useModal(false)
    const [toggleMembersModal, isMembersModalShowing] = useModal(false)
    const { memberData } = useContext(MemberContext)
    const { panelState } = useContext(DetailsPanelContext)
    const [activeMenu, setActiveMenu] = useState('main')
    const [open, setOpen] = useState(false)
    const [menuHeight, setMenuHeight] = useState(null)
    const { viewPreference, setViewPreference } = useContext(
        ViewPreferenceContext
    )
    const menuRef = useRef()
    const [treeName, setTreeName] = useState('Loading...')
    const [newTreeName, setNewTreeName] = useState('')
    const [
        isAdmin,
        isContributor,
        isViewer,
        memberPermission,
    ] = useCurrentMemberPermission([])
    const { deleteTree, deleting } = useDeleteTree()
    const treeRef = firebase
        .firestore()
        .collection('trees')
        .doc(panelState.treeId)

    useEffect(() => {
        if (panelState.treeDoc) {
            setTreeName(panelState.treeDoc.name)
            setNewTreeName(panelState.treeDoc.name)
        }
    }, [panelState.treeDoc])

    const handleLeaveTree = () => {
        const newMemberPermissions = panelState.treeDoc.member_permissions.filter(
            (m) => m.id !== memberData.id
        )

        if (
            window.confirm(
                'Are you sure you want to remove yourself from this tree?'
            )
        ) {
            treeRef
                .update({
                    members: firestore.FieldValue.arrayRemove(memberData.id),
                    member_permissions: newMemberPermissions,
                })
                .then(() => {
                    toaster.notify('Removed from tree')
                })
        }
    }

    const handleInputChange = () => {
        const treeRef = firebase
            .firestore()
            .collection('trees')
            .doc(panelState.treeId)

        if (newTreeName.length > 0) {
            if (treeName !== newTreeName) {
                treeRef
                    .update({
                        name: newTreeName,
                    })
                    .then(() => {
                        setNewTreeName(newTreeName)
                        setActiveMenu('main')
                        toaster.notify(`Tree name changed!`)
                    })
                    .catch(() => {
                        toaster.notify(`Error updated tree name`)
                        setNewTreeName(treeName)
                    })
            }
        } else {
            alert("Tree name can't be blank")
            setNewTreeName(treeName)
        }
    }

    const handleChange = (e) => {
        setNewTreeName(e.target.value)
    }

    const handleOnClick = () => {
        setOpen(false)
        setActiveMenu('main')
        calcHeight(menuRef)
    }

    const calcHeight = (el) => {
        const height = el.offsetHeight
        setMenuHeight(height)
    }

    useOnClickOutside(menuRef, () => handleOnClick())

    return (
        <>
            <StyledContainer
                closeEvents={() => handleOnClick()}
                trigger={
                    <IconButton
                        whiteTransparent={true}
                        whiteText={true}
                        icon="ellipsis-h"
                        square={true}
                        iconType="regular"
                        onClick={() => setOpen(true)}
                        // data-tooltip="More options"
                        // data-tooltip-position="right"
                    />
                }
            >
                <StyledDropdownContainer ref={menuRef}>
                    <StyledOptionsWrapper style={{ height: menuHeight }}>
                        <CSSTransition
                            in={activeMenu === 'main'}
                            unmountOnExit
                            classNames="main"
                            timeout={speed}
                            onEnter={calcHeight}
                            // onEnter={calcHeight}
                            // onEntered={focusOnInput}
                        >
                            <StyledPrimaryContainer>
                                {/* <Dropdown.Item className="u-bold" as="a" href="/trees" iconRight="home">Go to dashboard</Dropdown.Item>
                                <Dropdown.Divider/> */}
                                {/* {isAdmin && 
                                    <Dropdown.Item iconRight="envelope" onClick={toggleInviteModal}>Invite members</Dropdown.Item>
                                }
                                {(isAdmin || isContributor || isViewer) && 
                                    <Dropdown.Item iconRight="users" onClick={toggleMembersModal}>View members</Dropdown.Item>
                                }
                                {isAdmin && 
                                    <Dropdown.Divider/>
                                } */}

                                {/* <Dropdown.Item 
                                    onClick={() => setActiveMenu("viewMenu")}
                                    iconRight="chevron-right">
                                        Layout
                                </Dropdown.Item>
                                <Dropdown.Divider/> */}

                                {isAdmin && (
                                    <Dropdown.Item
                                        onClick={() =>
                                            setActiveMenu('renameMenu')
                                        }
                                        iconRight="chevron-right"
                                    >
                                        Rename
                                    </Dropdown.Item>
                                )}
                                {isAdmin && <Dropdown.Divider />}
                                {memberPermission && (
                                    <Dropdown.Item
                                        danger={true}
                                        iconRight="door-open"
                                        onClick={() => handleLeaveTree()}
                                    >
                                        Leave tree
                                    </Dropdown.Item>
                                )}
                                {isAdmin && (
                                    <Dropdown.Item
                                        iconRight="trash-alt"
                                        danger={true}
                                        onClick={() =>
                                            deleteTree(panelState.treeId)
                                        }
                                    >
                                        Delete tree
                                    </Dropdown.Item>
                                )}
                            </StyledPrimaryContainer>
                        </CSSTransition>

                        <CSSTransition
                            in={activeMenu === 'viewMenu'}
                            unmountOnExit
                            classNames="secondary"
                            timeout={speed}
                            onEnter={calcHeight}
                        >
                            <StyledPrimaryContainer>
                                <Button
                                    iconLeft="arrow-left"
                                    size="m"
                                    whiteTransparent={true}
                                    onClick={() => setActiveMenu('main')}
                                >
                                    Back
                                </Button>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                    onClick={() =>
                                        setViewPreference({ layout: 'tree' })
                                    }
                                    iconRight={
                                        viewPreference.layout === 'tree' &&
                                        'check'
                                    }
                                    iconLeft="sitemap"
                                >
                                    Map view
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() =>
                                        setViewPreference({ layout: 'table' })
                                    }
                                    iconRight={
                                        viewPreference.layout === 'table' &&
                                        'check'
                                    }
                                    iconLeft="table"
                                >
                                    Table view
                                </Dropdown.Item>
                            </StyledPrimaryContainer>
                        </CSSTransition>

                        <CSSTransition
                            in={activeMenu === 'renameMenu'}
                            unmountOnExit
                            classNames="secondary"
                            timeout={speed}
                            onEnter={calcHeight}
                        >
                            <StyledPrimaryContainer>
                                <Button
                                    iconLeft="arrow-left"
                                    size="m"
                                    whiteTransparent={true}
                                    onClick={() => setActiveMenu('main')}
                                >
                                    Back
                                </Button>
                                <Dropdown.Divider />
                                <StyledRenameContainer>
                                    <InputGroup
                                        label="Rename tree"
                                        placeholder="Name your tree"
                                        value={newTreeName}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Button
                                        fullWidth={true}
                                        onClick={() => handleInputChange()}
                                    >
                                        Rename
                                    </Button>
                                </StyledRenameContainer>
                            </StyledPrimaryContainer>
                        </CSSTransition>
                    </StyledOptionsWrapper>
                </StyledDropdownContainer>
            </StyledContainer>
            <InviteModal
                isModalShowing={isInviteModalShowing}
                hide={toggleInviteModal}
            />
            <MembersModal
                isModalShowing={isMembersModalShowing}
                hide={toggleMembersModal}
            />
        </>
    )
}

export default SideMenuTrigger
