import React, { useContext, useEffect, useState } from 'react'
import firebase from 'firebase/app'
import MemberContext from '../contexts/MemberContext'
import { useParams } from 'react-router'
import { pageContainerStyles } from '../pages/TreesPage'
import toaster from 'toasted-notes'

export const useTrees = () => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const { memberData } = useContext(MemberContext)
    const [trees, setTrees] = useState([])
    const treeRef = firebase.firestore().collection('trees')

    useEffect(() => {
        // NEW WAY
        if (memberData) {
            const query = treeRef.where(
                'members',
                'array-contains',
                memberData.id
            )

            query.onSnapshot((trees) => {
                setTrees(
                    trees.docs.map((tree) => {
                        return {
                            id: tree.id,
                            ...tree.data(),
                        }
                    })
                )
                setLoading(false)
            })
        }
    }, [memberData])

    const deleteTree = (treeId) => {
        const leavesRef = treeRef.doc(treeId).collection('leaves')
        if (
            window.confirm(
                "This can't be undone. Are you sure you want to delete this tree?"
            )
        ) {
            leavesRef.get().then((leaves) => {
                const leafPromises = leaves.docs.map((leaf) => {
                    leavesRef.doc(leaf.id).delete()
                })

                Promise.all(leafPromises)
                    .then(() => {
                        treeRef
                            .doc(treeRef)
                            .delete()
                            .then(() => {
                                toaster.notify('Tree deleted')
                            })
                    })
                    .catch(() => {
                        toaster.notify('Something went wrong')
                    })
            })
        }
    }

    return { trees, loading, error, deleteTree }
}

export default useTrees
