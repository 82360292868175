import React, { useContext, useState, useEffect } from 'react'
import LeavesContext from '../../../contexts/LeavesContext'
import DetailsPanelContext from '../../../contexts/DetailsPanelContext'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { getLeafImage } from '../../../assets/js/utils'
import toaster from 'toasted-notes'
import Card from '../../organisms/Card'
import ButtonGroup from '../../organisms/ButtonGroup'
import Modal from '../../organisms/Modal'
import Button from '../../atoms/Button'
import Avatar from '../../atoms/Avatar'
import styled from 'styled-components'
import MemberContext from '../../../contexts/MemberContext'

const StyledErrorMessage = styled.div`
    color: var(--color_danger);
    text-align: center;
`
const StyledAvatar = styled(Avatar)`
    margin: 16px auto 24px auto;
`

const StyledMessage = styled.p`
    text-align: center;
`

const StyledCardContainer = styled(Card.Container)`
    padding-left: 40px;
    padding-right: 40px;
`

const StyledButtonGroup = styled(ButtonGroup)`
    margin-top: 32px;
`

export const RemoveLeaf = ({
    isModalShowing,
    hide,
    explicitLeafId,
    ...rest
}) => {
    const { panelState, setPanelState } = useContext(DetailsPanelContext)
    const { leaves } = useContext(LeavesContext)
    const { memberData } = useContext(MemberContext)
    const [name, setName] = useState('leaf')
    const [claimed, setClaimed] = useState(false)
    // const [errorMessage, setErrorMessage] = useState('')
    const toDeleteId = explicitLeafId || panelState.leafId
    const toDeleteDoc = leaves.find((doc) => doc.id === toDeleteId)

    const treesRef = firebase
        .firestore()
        .collection('trees')
        .doc(panelState.treeId)
    const leavesRef = treesRef.collection('leaves')

    const { parents, children, siblings, partners } = toDeleteDoc.relationships
    let errorMessage = ''

    if (children.length > 0) {
        if (parents.length === 0 && partners.length > 0) {
            // removeOriginLeaf()
        } else {
            if (parents.length > 0) {
                errorMessage = `This leaf cannot be removed until either its parents or children have been removed.`
            } else if (partners.length === 0 && children.length > 1) {
                errorMessage = `This leaf cannot be removed since it connects multiple children together.`
            }
        }
    }

    const handleRemoveLeaf = async () => {
        let isTopMember = toDeleteDoc.top_member === true ? true : false
        let isClaimed = toDeleteDoc.claimed_by ? true : false
        let hasChildren =
            toDeleteDoc.relationships.children.length > 0 ? true : false
        let hasParentsAndChildren =
            parents.length > 0 && children.length > 0 ? true : false

        if (isClaimed && !memberData.id === toDeleteDoc.claimed_by) {
            alert(
                `${
                    // toDeleteDoc.display_name ||
                    // toDeleteDoc.first_name ||
                    'This leaf'
                } is claimed by a member. The leaf will be removed, but the member will remain a tree member.`
            )
        }

        removeOriginLeaf()
        hide()

        async function removeOriginLeaf() {
            let parentPromises = []
            if (parents.length > 0) {
                console.log('removing from children')

                parentPromises = parents.map((parentObj) => {
                    const parentDoc = leaves.find((l) => l.id === parentObj.id)
                    const newChildren = parentDoc.relationships.children.filter(
                        (obj) => obj.id !== toDeleteId
                    )
                    return leavesRef.doc(parentObj.id).update({
                        'relationships.children': newChildren,
                    })
                })
            }

            const siblingPromises = siblings.map((siblingObj) => {
                const siblingDoc = leaves.find((l) => l.id === siblingObj.id)
                const newSiblings = siblingDoc.relationships.siblings.filter(
                    (obj) => obj.id !== toDeleteId
                )
                return leavesRef.doc(siblingObj.id).update({
                    'relationships.siblings': newSiblings,
                })
            })

            let partnerPromises = []

            // Collect partners that should be deleted if they are reliant on the toBeDeleted leaf.
            const partnersToDelete = partners.filter((partnerObj) => {
                const partnerDoc = leaves.find((p) => p.id === partnerObj.id)

                if (partnerDoc.relationships.children.length === 0) {
                    return partnerObj
                }
            })

            if (parents.length > 0 && partnersToDelete.length > 0) {
                // if toBeDeleted leaf has parents and partners with no children, the partners should be deleted as well.
                if (
                    window.confirm(
                        'Deleting this leaf will also delete all partners to this leaf.'
                    )
                ) {
                    partnerPromises = partners.map((parentObj) => {
                        return leavesRef.doc(parentObj.id).delete()
                    })
                }
            } else {
                partnerPromises = partners.map((partnerObj) => {
                    const partnerDoc = leaves.find(
                        (l) => l.id === partnerObj.id
                    )
                    const newPartners = partnerDoc.relationships.partners.filter(
                        (obj) => obj.id !== toDeleteId
                    )
                    return leavesRef.doc(partnerObj.id).update({
                        'relationships.partners': newPartners,
                    })
                })
            }

            const childrenPromises = children.map((childObj) => {
                const childrenDoc = leaves.find((l) => l.id === childObj.id)
                const newChildren = childrenDoc?.relationships?.parents.filter(
                    (obj) => obj.id !== toDeleteId
                )

                leavesRef.doc(childObj.id).update({
                    'relationships.parents': newChildren,
                })
            })

            const storageRef = firebase.storage().ref()
            const leafImageRef =
                toDeleteDoc.profile_photo &&
                storageRef.child(toDeleteDoc.profile_photo)
            const leafImagePromise =
                toDeleteDoc.profile_photo && leafImageRef.delete()

            Promise.all([
                ...childrenPromises,
                ...partnerPromises,
                ...siblingPromises,
                ...parentPromises,
                leavesRef.doc(toDeleteId).delete(),
                leafImagePromise,
            ])
                .then(() => {
                    setPanelState({
                        ...panelState,
                        leafId: null,
                        leafDoc: null,
                    })

                    toaster.notify('Leaf removed')
                })
                .catch((err) => {
                    toaster.notify(err.message)
                })
        }
    }

    useEffect(() => {
        if (panelState.leafId) {
            setName(
                panelState.linkedMemberDoc?.display_name ||
                    panelState.linkedMemberDoc?.first_name ||
                    panelState.leafDoc?.display_name ||
                    panelState.leafDoc?.first_name ||
                    'leaf'
            )
            setClaimed(panelState.linkedMemberDoc ? true : false)
        }
    }, [panelState.leafId])

    return (
        <>
            <Modal
                isShowing={isModalShowing}
                hide={hide}
                closeButton={true}
                size="s"
                {...rest}
            >
                <StyledCardContainer>
                    <Card.Content>
                        <StyledAvatar
                            size="m"
                            borderRadius={
                                panelState.linkedMemberDoc ? '25%' : null
                            }
                            src={getLeafImage(
                                panelState?.linkedMemberDoc?.id ||
                                    panelState?.leafId
                            )}
                        />
                        {errorMessage ? (
                            <StyledErrorMessage>
                                {errorMessage}
                            </StyledErrorMessage>
                        ) : (
                            <StyledMessage>
                                {`Are you sure you want to delete ${
                                    panelState?.linkedMemberDoc?.display_name ||
                                    panelState?.linkedMemberDoc?.first_name ||
                                    panelState.leafDoc?.display_name ||
                                    panelState.leafDoc?.first_name ||
                                    'this leaf'
                                }?`}
                            </StyledMessage>
                        )}
                        {claimed && !errorMessage && (
                            <>
                                <StyledMessage>
                                    A member has claimed this leaf. The leaf
                                    will be deleted, but the member will still
                                    belong to this tree.
                                </StyledMessage>
                            </>
                        )}
                        <StyledButtonGroup justifyContent="center">
                            <Button outline={true} onClick={hide}>
                                {errorMessage ? 'Ok' : 'Keep'}
                            </Button>
                            {!errorMessage && (
                                <Button
                                    onClick={handleRemoveLeaf}
                                    solid={true}
                                    danger={true}
                                >
                                    Delete
                                </Button>
                            )}
                        </StyledButtonGroup>
                    </Card.Content>
                </StyledCardContainer>
            </Modal>
        </>
    )
}

export default RemoveLeaf
