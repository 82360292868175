import React, { useContext } from 'react'
import styled from 'styled-components'
import FlexibleContainer from './FlexibleContainer'
import Notifications from '../general/Notifications'
import Header from '../atoms/Header'
import { pageContainerStyles } from '../../pages/TreesPage'
import SideMenu from '../general/SideMenu'
import MemberContext from '../../contexts/MemberContext'

const StyledWrapper = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 64px clamp(24px, 2.5vw, 80px);
`

const StyledHeader = styled(Header)`
    margin-top: 52px;
    margin-bottom: 48px;
`

const StyledFlexContainer = styled.div`
    display: flex;
    flex-direction: row;
`

export const NotificationsPage = () => {
    const { memberData } = useContext(MemberContext)

    return (
        <>
            <FlexibleContainer includeDetailsPanel>
                <StyledWrapper>
                    <StyledHeader size="m">Notifications</StyledHeader>
                    <StyledFlexContainer>
                        <Notifications />
                    </StyledFlexContainer>
                </StyledWrapper>
            </FlexibleContainer>
        </>
    )
}

export default NotificationsPage
