import React, { createContext, useState } from 'react'

const LeavesContext = createContext()

export const LeavesContextProvider = ({ children, ...rest }) => {
    const [leaves, setLeaves] = useState([])

    return (
        <LeavesContext.Provider
            value={{
                leaves,
                setLeaves,
            }}
        >
            {children}
        </LeavesContext.Provider>
    )
}

export default LeavesContext
