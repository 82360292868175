import React, { useContext, useEffect, useState } from 'react'
import {
    buildBirthdayString,
    buildDeathDateString,
    buildFullAddressString,
    convertDate,
    getLeafImage,
    getNameDetails,
    loopThroughMemberBlueprint,
} from '../assets/js/utils'
import Button from '../components/atoms/Button'
import LeafProfileItem from '../components/general/DetailsPanel/LeafProfileItem'
import MemberContext from '../contexts/MemberContext'
import toaster from 'toasted-notes'
import firebase from 'firebase/app'

export const useMemberProfile = (data) => {
    const { memberData } = useContext(MemberContext)
    const [memberProfile, setMemberProfile] = useState({})
    const [loading, setLoading] = useState(true)
    const [empty, setEmpty] = useState(false)

    const excludeCategories = ['name', 'address', 'birthday', 'deathdate']

    useEffect(() => {
        if (memberData) {
            setMemberProfile([])

            let dataItems = []

            const generateInputs = (key, value) => {
                let pathName = value['pathName']
                let icon = value['icon']
                let category = value['category']
                let description = value['description'] || null
                let dataValue = memberData[pathName] || null
                let dataFormat = value['dataFormat']

                if (!excludeCategories.includes(category) && dataValue) {
                    dataItems.push({
                        key: pathName,
                        label: key,
                        icon: icon,
                        value: dataValue || null,
                        description: description,
                        category: category,
                        dataFormat: dataFormat,
                    })
                }
            }

            const addressString = buildFullAddressString(memberData) || null

            addressString &&
                dataItems.push({
                    key: 'address',
                    label: 'Address',
                    icon: 'house',
                    value: addressString,
                })

            const birthdayString = buildBirthdayString(memberData) || null

            birthdayString &&
                dataItems.push({
                    key: 'birthday',
                    label: 'Birthday',
                    icon: 'birthday-cake',
                    value: birthdayString,
                })

            const deathdateString = buildDeathDateString(memberData) || null

            deathdateString &&
                dataItems.push({
                    key: 'deathdate',
                    label: 'Death date',
                    icon: 'tombstone',
                    value: deathdateString,
                })

            loopThroughMemberBlueprint({
                functionCall: generateInputs,
            })

            setMemberProfile({
                data: dataItems,
                // photoSrc: getLeafImage(memberData?.id),
                nameDetails: getNameDetails(memberData),
                displayName: memberData?.display_name,
            })

            setLoading(false)
            setEmpty(dataItems.length === 0 ? true : false)
        }
    }, [memberData])

    return { memberProfile, loading, empty }
}

export default useMemberProfile
