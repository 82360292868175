import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { CSSTransition } from 'react-transition-group'
import styled, { css } from 'styled-components'
import Button from '../atoms/Button'
import Header from '../atoms/Header'
import Icon from '../atoms/Icon'
import Card from './Card'
import Modal from './Modal'

const SyledCardContent = styled(Card.Content)`
    padding: 56px;
    text-align: center;
`

const StyledIcon = styled(Icon)`
    font-size: 32px;
    margin-bottom: 28px;
    color: var(--turquoise_300);
`

const StyledHeader = styled.div`
    color: var(--turquoise_300);
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 24px;
`

const StyledFinishButton = styled(Button)`
    margin-top: 40px;
`

const StyledDotContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 18px);
    gap: 0;
    justify-content: center;
    margin-top: 64px;
`

const StyledDot = styled.div`
    --dotSize: 8px;
    height: var(--dotSize);
    width: var(--dotSize);
    border-radius: var(--dotSize);
    background-color: var(--color_text_300);

    ${(props) =>
        props.active &&
        css`
            background-color: var(--color_text_700);
        `}
`

export const Walkthrough = ({
    steps,
    isShowing,
    hide,
    finish,
    currentStep,
    setCurrentStep,
    ...rest
}) => {
    // const [currentIndex, setCurrentIndex] = useState(currentStep);
    const [activeMenu, setActiveMenu] = useState(`step-1`)
    const [redirect, setRedirect] = useState('')

    useEffect(() => {
        setActiveMenu(`step-${currentStep}`)
        if (currentStep === steps.length + 1) {
            setRedirect('/profile')
        }
    }, [currentStep])

    // const nextIndex = (currentIndex) => {
    //   setActiveMenu(`step-${currentIndex+1}`);
    //   setCurrentIndex(currentIndex+1)
    // }

    // return steps.map(step => <div>{step.title}</div>)

    if (redirect) {
        return <Redirect to={redirect} />
    } else {
        return (
            <Modal size="400px" isShowing={isShowing}>
                <Card.Container>
                    <SyledCardContent>
                        {steps.map((step, index) => (
                            <CSSTransition
                                in={activeMenu === `step-${index + 1}`}
                                unmountOnExit
                                classNames="step"
                                timeout={0}
                                key={index}
                                // onEnter={calcHeight}
                            >
                                <>
                                    <StyledIcon icon={step.icon} />
                                    <StyledHeader>{step.title}</StyledHeader>
                                    {step.content}
                                    {steps.length === index + 1 && (
                                        <StyledFinishButton
                                            fullWidth={true}
                                            solid={true}
                                            onClick={() => finish()}
                                        >
                                            Finish
                                        </StyledFinishButton>
                                    )}
                                    {step.showNext && (
                                        <StyledFinishButton
                                            fullWidth={true}
                                            solid={true}
                                            onClick={() =>
                                                setCurrentStep(currentStep + 1)
                                            }
                                        >
                                            Next
                                        </StyledFinishButton>
                                    )}
                                    <StyledDotContainer>
                                        {steps.map((step, index) => (
                                            <StyledDot
                                                key={index}
                                                active={
                                                    index + 1 === currentStep
                                                }
                                            />
                                        ))}
                                    </StyledDotContainer>
                                </>
                            </CSSTransition>
                        ))}
                    </SyledCardContent>
                </Card.Container>
            </Modal>
        )
    }
}

export default Walkthrough
