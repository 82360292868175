import { useContext } from 'react'
import MemberContext from '../contexts/MemberContext'
import { NotificationsContext } from '../contexts/NotificationsContext'
import firebase from 'firebase/app'
import toaster from 'toasted-notes'

export const useNotifcation = () => {
    const { notifications } = useContext(NotificationsContext)
    const { memberData } = useContext(MemberContext)

    const getNotificationById = (id) => {
        const doc = notifications && notifications.find((doc) => doc.id === id)
        return doc || null
    }

    const acceptNotification = (id, redirect = false, callBack = null) => {
        const notificationDoc = getNotificationById(id)
        const treesRef = firebase
            .firestore()
            .collection('trees')
            .doc(notificationDoc.for_tree)
        const leafRef = treesRef.collection('leaves')
        const notificationsRef = firebase
            .firestore()
            .collection('notifications')
            .doc(id)
        const arrayUnion = firebase.firestore.FieldValue.arrayUnion

        let promises = []
        promises.push(
            treesRef.update({
                member_permissions: arrayUnion({
                    id: memberData?.id,
                    permission: notificationDoc.permission_type,
                }),
                members: arrayUnion(memberData.id),
            })
        )
        promises.push(notificationsRef.delete())

        notificationDoc.for_leaf &&
            promises.push(
                leafRef
                    .doc(notificationDoc.for_leaf)
                    .update({ claimed_by: memberData?.id })
            )

        Promise.all(promises)
            .then(() => {
                if (redirect) {
                    window.history.pushState(
                        null,
                        null,
                        `/trees/${notificationDoc.for_tree}`
                    )
                    window.location.reload()
                }
                toaster.notify(`Invitation accepted`)
                callBack && callBack()
            })
            .catch(() => {
                toaster.notify(`An error occured`)
            })
    }

    const declineNotifcation = (id) => {
        const notificationDoc = getNotificationById(id)
        const treesRef = firebase
            .firestore()
            .collection('trees')
            .doc(notificationDoc.for_tree)
        const notificationsRef = firebase
            .firestore()
            .collection('notifications')
            .doc(id)

        let promises = []
        promises.push(
            treesRef.update({
                [`invitations.${id}`]: firebase.firestore.FieldValue.delete(),
            })
        )
        promises.push(notificationsRef.update({ status: 'decline' }))

        Promise.all(promises)
            .then(() => {
                toaster.notify(`Invitation declined`)
            })
            .catch(() => {
                toaster.notify(`An error occured`)
            })
    }

    return { notifications, acceptNotification, declineNotifcation }
}

export default useNotifcation
