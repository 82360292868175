import React, { createContext, useState, useEffect } from 'react'

const DetailsPanelContext = createContext()

export const DetailsPanelContextProvider = (props) => {
    const [panelState, setPanelState] = useState({
        leafId: null,
        leafDoc: null,
        linkedMemberDoc: null,
        treeId: null,
        showPanel: true,
        showSideMenu: false,
    })

    return (
        <DetailsPanelContext.Provider
            value={{
                panelState,
                setPanelState,
            }}
        >
            {props.children}
        </DetailsPanelContext.Provider>
    )
}

export default DetailsPanelContext
