import React, { useState } from 'react'
import toaster from 'toasted-notes'
import firebase from 'firebase/app'
import { useParams } from 'react-router'

export const useDeleteTree = () => {
    const [deleting, setDeleting] = useState(false)
    const { treeId } = useParams()

    const deleteTree = (treeIdToDelete) => {
        if (
            window.confirm(
                "This can't be undone. Are you sure you want to delete this tree?"
            )
        ) {
            const TREE_REF = firebase
                .firestore()
                .collection('trees')
                .doc(treeIdToDelete)
            const LEAVES_REF = TREE_REF.collection('leaves')
            setDeleting(true)
            LEAVES_REF.get().then((leaves) => {
                const deleteLeavesPromise = leaves.docs.map((leaf) => {
                    LEAVES_REF.doc(leaf.id).delete()
                })

                Promise.all(deleteLeavesPromise)
                    .then(() => {
                        TREE_REF.delete().then(() => {
                            setDeleting(false)
                            toaster.notify('Tree deleted')
                            if (treeId) {
                                window.history.pushState(null, null, '/trees')
                                window.location.reload()
                            }
                        })
                    })
                    .catch(() => {
                        setDeleting(false)
                        toaster.notify('An error occured')
                    })
            })
        }
    }

    return { deleteTree, deleting }
}

export default useDeleteTree
