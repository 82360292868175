import { useContext, useState } from 'react'
import firebase from 'firebase/app'
import DetailsPanelContext from '../contexts/DetailsPanelContext'
import MemberContext from '../contexts/MemberContext'

export const useUnlinkLeaf = () => {
    const [loading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const { panelState, setPanelState } = useContext(DetailsPanelContext)
    const { setClaimedLeaf } = useContext(MemberContext)

    const unlinkLeaf = (treeId, leafId) => {
        setIsLoading(true)
        let treeRef = firebase.firestore().collection('trees').doc(treeId)
        treeRef
            .collection('leaves')
            .doc(leafId)
            .update({ claimed_by: null })
            .then(() => {
                setIsLoading(false)
                setPanelState({
                    ...panelState,
                    linkedMemberDoc: null,
                })
                setClaimedLeaf(null)
            })
            .catch((err) => {
                setError(err.message)
            })
    }

    return [unlinkLeaf, loading, error]
}

export default useUnlinkLeaf
