import { useContext, useEffect, useState } from 'react'
import { getMemberPermission } from '../assets/js/utils'
import DetailsPanelContext from '../contexts/DetailsPanelContext'
import LeavesContext from '../contexts/LeavesContext'
import MemberContext from '../contexts/MemberContext'

export const useCurrentMemberPermission = () => {
    const { panelState } = useContext(DetailsPanelContext)
    const { memberData } = useContext(MemberContext)
    const [memberPermission, setMemberPermission] = useState(null)
    const [isAdmin, setIsAdmin] = useState(false)
    const [isContributor, setIsContributor] = useState(false)
    const [isViewer, setIsViewer] = useState(false)
    const { leaves } = useContext(LeavesContext)

    const isCreatorOfLeaf = (leafId) => {
        const leafDoc = leaves.find((l) => l.id === leafId)
        return leafDoc?.created_by === memberData.id ? true : false
    }

    useEffect(() => {
        if (panelState.treeDoc && memberData) {
            panelState.treeDoc.member_permissions.map((permDoc) => {
                if (permDoc.id === memberData.id) {
                    const perm = permDoc.permission
                    setMemberPermission(perm)
                    setIsAdmin(
                        perm === 'Admin' || perm === 'admin' ? true : false
                    )
                    setIsContributor(
                        perm === 'Contributor' || perm === 'contributor'
                            ? true
                            : false
                    )
                    setIsViewer(
                        perm === 'Viewer' || perm === 'viewer' ? true : false
                    )
                }
            })
        }
    }, [panelState.treeDoc, memberData])

    return [isAdmin, isContributor, isViewer, memberPermission, isCreatorOfLeaf]
}

export default useCurrentMemberPermission
