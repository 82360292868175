import React, { useState, useContext, useRef, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled, { css } from 'styled-components'
import Icon from '../atoms/Icon'
import { Link } from 'react-router-dom'
import useOnClickOutside from '../../hooks/useOnOutsideClick'
import MemberContext from '../../contexts/MemberContext'
import { dropdownStyle, StyledBold } from '../../globalStyles'
import { getLeafImage } from '../../assets/js/utils'
import Avatar from '../atoms/Avatar'
import CustomThemeContext from '../../contexts/CustomThemeContext'
import { NotificationsContext } from '../../contexts/NotificationsContext'
import Dropdown from './Dropdown'
import Toggle from '../atoms/Toggle'
import Button from '../atoms/Button'
import { LinkButton } from '../atoms/LinkButton'
import useNotifcation from '../../hooks/useNotification'
import ButtonGroup from './ButtonGroup'
import Modal from './Modal'
import useModal from '../../hooks/useModal'
import Card from './Card'
import { THEME_COLOR_OPTIONS } from '../../assets/js/globalVars'

const speed = 280

const StyledDropdown = styled.div`
    overflow: hidden;
    transition: all ${speed}ms var(--bounceEasing);
`

const StyledDropdownItem = styled.div`
    ${dropdownStyle}

    &:hover i {
        color: white;
    }
`

const StyledEmptyNotifications = styled.div`
    background-color: var(--color_background_depth_4);
    padding: 16px;
    border-radiu: 8px;
    color: var(--color_text_700);
    text-align: center;
`

const StyledProfileItem = styled(Link)`
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    align-items: flex-start;
    color: inherit;
`

const StyledMenu = styled.div`
    width: 100%;
    transition: all ${speed}ms var(--bounceEasing);

    &.main-enter {
        position: absolute;
        transform: translateX(-110%);
    }

    &.main-enter-active {
        transform: translateX(0%);
    }

    &.main-exit {
        position: absolute;
        transform: translateX(-110%);
    }

    &.main-exit-active {
        // transform: translateX(-110%);
    }

    &.secondary-enter {
        transform: translateX(110%);
    }

    &.secondary-enter-active {
        transform: translateX(0%);
    }

    &.secondary-exit {
        transform: translateX(110%);
    }

    &.secondary-exit-active {
        // transform: translateX(0%);
    }
`

const StyledChevron = styled(Icon)`
    margin-left: auto;
`

const StyledLeftIcon = styled(Icon)`
    font-size: 20px;
    color: var(--brand_500);
    margin-right: 16px;
`

const StyledHr = styled.hr`
    width: calc(100% - 32px);
    margin: 8px auto;
`

const StyledAccountFirstName = styled.p`
    margin-bottom: 0;
    margin-top: 0;
    opacity: 0.88;
    font-size: 18px;
    font-weight: bold;
`

const StyledAccountEmail = styled.p`
    margin-bottom: 0;
    margin-top: -4px;
    color: var(--color_text_600);
    font-size: 13px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const StyledCaretIcon = styled(Icon)`
    margin-left: 12px;
    opacity: 0.35;
    display: flex;
    align-items: center;
`

const StyledSignOutLink = styled(Link)`
    ${dropdownStyle}
    text-decoration: none;

    i {
        margin-right: 16px;
    }

    &:hover {
        background-color: var(--hoverBackgroundColor);
    }

    &:active {
        background-color: var(--color_background_depth_3);
    }
`

const StyledAccountDropdown = styled.div`
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    margin-left: 8px;
    text-decoration: none;
    position: relative;

    &:hover {
        background-color: var(--color_15_dark);
    }

    &:active {
        background-color: var(--color_15_light);
    }
`

const StyledProfileTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 16px;
`

const StyledNotificationBadge = styled.div`
    --badgeSize: 24px;
    width: var(--badgeSize);
    height: var(--badgeSize);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 99;
    background-color: var(--blue_400);
    color: white;
    font-weight: 900;
    margin-left: auto;
`

const StyledFloatingNotificationBadge = styled(StyledNotificationBadge)`
    position: absolute;
    top: -2px;
    right: 16px;
    margin-left: unset;
`

const StyledAvatar = styled(Avatar)`
    margin-top: 4px;
`

const StyledToggle = styled(Toggle)`
    margin-top: 16px;
    padding: 16px;
`

const StyledNotificationContainer = styled.div`
    padding: 8px;
`

const StyledNotification = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    gap: 8px;
    margin-bottom: 8px;
`

const StyledCardContent = styled(Card.Content)`
    text-align: center;
    padding-top: 48px;
`

const StyledAccountDropdownContainer = styled.div`
    display: flex;
    align-items: center;
`

const StyledShowEmail = styled.div`
    font-size: 14px;
    margin-right: 8px;
`

const StyledColorOptionContainer = styled.div`
    display: flex;
    margin-left: auto;
`

const StyledColorOptionsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
`

const StyledColorOption = styled.div`
    --color_opt_size: 32px;
    width: var(--color_opt_size);
    height: var(--color_opt_size);
    border-radius: var(--color_opt_size);
    background-color: ${(props) => props.color};
    margin-left: 8px;
    border: 4px solid var(--color_background_depth_2);
    cursor: pointer;

    &:active {
        transform: scale(.95);
    }

    ${(props) =>
        props.current &&
        css`
            box-shadow: 0 0 0 2px ${(props) => props.color};
        `}}
`

export const AccountDropdown = ({
    children,
    trigger,
    dropdownPosition,
    showEmail = false,
    ...rest
}) => {
    const [activeMenu, setActiveMenu] = useState('main')
    const [menuHeight, setMenuHeight] = useState(null)
    const [open, setOpen] = useState(false)
    const { memberData, memberPhoto } = useContext(MemberContext)
    const containerRef = useRef()
    const { themeMode, setThemeMode } = useContext(CustomThemeContext)
    const [currentTheme, setCurrentTheme] = useState(themeMode)
    const [currentColoring, setCurrentColoring] = useState(themeMode.color)

    const {
        notifications,
        acceptNotification,
        declineNotifcation,
    } = useNotifcation()
    const [
        toggleAcceptNotificationModal,
        isAcceptNotificationModalShowing,
    ] = useModal()
    const [acceptedNotification, setAcceptedNotification] = useState(null)

    const toggleThemeColor = (colorOption, change = false) => {
        const newModeObj = { mode: themeMode.mode, color: colorOption }
        setThemeMode(newModeObj)
        setCurrentTheme(newModeObj)
        change && setCurrentColoring(colorOption)
    }

    const handleMouseOut = () => {
        toggleThemeColor(currentColoring)
    }

    const handleMouseEnter = (colorOption) => {
        toggleThemeColor(colorOption)
    }

    const handleAcceptNotification = (id) => {
        setAcceptedNotification(getNotificationById(id))
        toggleAcceptNotificationModal()
    }

    const getNotificationById = (id) => {
        const doc = notifications && notifications.find((doc) => doc.id === id)
        return doc || null
    }

    const toggleThemeMode = () => {
        const newMode = currentTheme.mode === 'light' ? 'dark' : 'light'
        const newModeObj = { mode: newMode, color: themeMode.color }
        setThemeMode(newModeObj)
        setCurrentTheme(newModeObj)
    }

    const calcHeight = (el) => {
        const height = el.offsetHeight
        setMenuHeight(height)
    }

    const calcDimensions = (el) => {
        calcHeight(el)
    }

    const handleClose = () => {
        setActiveMenu('main')
        setOpen(false)
        calcDimensions(containerRef)
    }

    const setMenuAndCalc = (goToMenu) => {
        setActiveMenu(goToMenu)
        calcDimensions(containerRef)
    }

    useOnClickOutside(containerRef, () => handleClose())

    function AccountDropdownItem({ children, goToMenu, iconRight, iconLeft }) {
        return (
            <StyledDropdownItem
                onClick={() => goToMenu && setMenuAndCalc(goToMenu)}
            >
                {iconLeft && <StyledLeftIcon icon={iconLeft} />}
                {children}
                {iconRight && <StyledChevron icon={iconRight} />}
            </StyledDropdownItem>
        )
    }

    return (
        <>
            <Dropdown
                ref={containerRef}
                trigger={
                    <StyledAccountDropdownContainer>
                        <StyledShowEmail>
                            {showEmail && memberData?.account_email}
                        </StyledShowEmail>
                        <StyledAccountDropdown
                            data-tooltip="Account"
                            data-tooltip-position="bm"
                            onClick={() => setOpen(!open)}
                        >
                            <Avatar
                                src={memberPhoto}
                                size="xs"
                                borderRadius="8px"
                            />
                            <StyledCaretIcon
                                icon="caret-down"
                                iconType="solid"
                            />
                            {notifications.length > 0 && (
                                <StyledFloatingNotificationBadge>
                                    {notifications.length}
                                </StyledFloatingNotificationBadge>
                            )}
                        </StyledAccountDropdown>
                    </StyledAccountDropdownContainer>
                }
            >
                <StyledDropdown
                    dropdownPosition={dropdownPosition}
                    style={{ height: menuHeight }}
                >
                    <CSSTransition
                        in={activeMenu === 'main'}
                        unmountOnExit
                        timeout={speed}
                        classNames="main"
                        onEnter={calcDimensions}
                    >
                        <StyledMenu>
                            <StyledProfileItem to="/profile" key="profile">
                                <StyledAvatar
                                    src={memberPhoto}
                                    size="xs"
                                    borderRadius="8px"
                                />
                                <StyledProfileTextWrapper>
                                    <StyledAccountFirstName>
                                        {memberData?.display_name ||
                                            memberData?.first_name ||
                                            'No name set'}
                                    </StyledAccountFirstName>
                                    <StyledAccountEmail key="email">
                                        {memberData?.account_email}
                                    </StyledAccountEmail>
                                </StyledProfileTextWrapper>
                            </StyledProfileItem>
                            <StyledHr />
                            {/* <StyledSignOutLink onClick={() => setActiveMenu("trees")}>
                            <Icon icon="tree-alt"/>
                            Trees
                        </StyledSignOutLink> */}
                            <AccountDropdownItem
                                key="notifications"
                                iconRight="chevron-right"
                                iconLeft="bell"
                                goToMenu="notifications"
                            >
                                Notifications
                                {notifications.length > 0 && (
                                    <StyledNotificationBadge>
                                        {notifications.length}
                                    </StyledNotificationBadge>
                                )}
                            </AccountDropdownItem>
                            {/* <StyledSignOutLink to={`/notifications`}>
                            <Icon icon="bell"/>
                            Notifications
                            {notifications.length > 0 && 
                                <StyledNotificationBadge>{notifications.length}</StyledNotificationBadge>
                            }
                        </StyledSignOutLink> */}
                            <AccountDropdownItem
                                key="settings"
                                iconRight="chevron-right"
                                iconLeft="sliders-v"
                                goToMenu="settings"
                            >
                                Preferences
                            </AccountDropdownItem>
                            {/* <StyledHr />
                            <StyledSignOutLink to={`/tutorials`}>
                                <Icon icon="book-open" />
                                Tutorials
                            </StyledSignOutLink> */}
                            <StyledHr />
                            <StyledSignOutLink to={`/sign-out`}>
                                <Icon icon="sign-out" />
                                Sign out
                            </StyledSignOutLink>
                        </StyledMenu>
                    </CSSTransition>

                    <CSSTransition
                        in={activeMenu === 'settings'}
                        unmountOnExit
                        timeout={speed}
                        classNames="secondary"
                        onEnter={calcDimensions}
                    >
                        <StyledMenu>
                            <AccountDropdownItem
                                key="trees"
                                iconLeft="arrow-left"
                                goToMenu="main"
                            >
                                Preferences
                            </AccountDropdownItem>
                            <StyledToggle
                                label="Dark mode"
                                onClick={() => toggleThemeMode()}
                                checked={currentTheme.mode === 'dark'}
                            />
                            <StyledColorOptionsWrapper>
                                <label>Coloring</label>
                                <StyledColorOptionContainer>
                                    {THEME_COLOR_OPTIONS.map((opt) => (
                                        <StyledColorOption
                                            color={opt.hex}
                                            key={opt.hex}
                                            current={
                                                currentColoring === opt.name
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(opt.name)
                                            }
                                            onMouseOut={() => handleMouseOut()}
                                            onClick={() =>
                                                toggleThemeColor(opt.name, true)
                                            }
                                        ></StyledColorOption>
                                    ))}
                                </StyledColorOptionContainer>
                            </StyledColorOptionsWrapper>
                        </StyledMenu>
                    </CSSTransition>

                    <CSSTransition
                        in={activeMenu === 'notifications'}
                        unmountOnExit
                        timeout={speed}
                        classNames="secondary"
                        onEnter={calcDimensions}
                    >
                        <StyledMenu>
                            <AccountDropdownItem
                                key="notifications"
                                iconLeft="arrow-left"
                                goToMenu="main"
                            >
                                Notifications
                            </AccountDropdownItem>
                            <StyledHr />
                            <StyledNotificationContainer>
                                {notifications.map((notification) => (
                                    <StyledNotification>
                                        <span>
                                            <StyledBold>
                                                {notification.from_member_name}
                                            </StyledBold>{' '}
                                            invited you to{' '}
                                            <StyledBold>
                                                {notification.for_tree_name}
                                            </StyledBold>
                                        </span>
                                        <ButtonGroup>
                                            <Button
                                                solid={true}
                                                size="m"
                                                onClick={() =>
                                                    acceptNotification(
                                                        notification.id,
                                                        false,
                                                        handleAcceptNotification(
                                                            notification.id
                                                        )
                                                    )
                                                }
                                            >
                                                Accept
                                            </Button>
                                            <Button
                                                outline={true}
                                                size="m"
                                                onClick={() =>
                                                    declineNotifcation(
                                                        notification.id
                                                    )
                                                }
                                            >
                                                Decline
                                            </Button>
                                        </ButtonGroup>
                                    </StyledNotification>
                                ))}
                                {notifications.length === 0 && (
                                    <StyledEmptyNotifications>
                                        No notifications
                                    </StyledEmptyNotifications>
                                )}
                                {/* <LinkButton 
                            outline={true} 
                            fullWidth={true} 
                            to="/notifications">
                                View notificaitons
                        </LinkButton> */}
                            </StyledNotificationContainer>
                        </StyledMenu>
                    </CSSTransition>
                </StyledDropdown>
            </Dropdown>
            <Modal
                isShowing={isAcceptNotificationModalShowing}
                hide={toggleAcceptNotificationModal}
                size="s"
            >
                <Card.Container>
                    <StyledCardContent>
                        Would you like to view <br />
                        <StyledBold>
                            {acceptedNotification?.for_tree_name}?
                        </StyledBold>
                    </StyledCardContent>
                    <Card.Footer>
                        <ButtonGroup verticalStack={true} fullWidth={true}>
                            <Button
                                outline={true}
                                onClick={() => toggleAcceptNotificationModal()}
                            >
                                Not now
                            </Button>
                            <LinkButton
                                solid={true}
                                to={`/trees/${acceptedNotification?.for_tree}`}
                            >
                                View tree
                            </LinkButton>
                        </ButtonGroup>
                    </Card.Footer>
                </Card.Container>
            </Modal>
        </>
    )
}

export default AccountDropdown
