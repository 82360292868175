import React, { useState, useEffect, useContext } from 'react'
import Modal from './Modal'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import styled from 'styled-components'
import Card from './Card'
import MemberContext from '../../contexts/MemberContext'
import ButtonGroup from './ButtonGroup'
import Button from '../atoms/Button'
import useModal from '../../hooks/useModal'
import InviteModal from '../general/InviteModal'
import LeavesContext from '../../contexts/LeavesContext'
import { getLeafImage } from '../../assets/js/utils'
import Avatar from '../atoms/Avatar'
import CurrentTreeMembersContext from '../../contexts/CurrentTreeMembersContext'
import Checkbox from '../atoms/Checkbox'
import useTreeMembers from '../../hooks/useTreeMembers'
import { LeafImagesContext } from '../../contexts/LeafImageContext'
import firebase from 'firebase/app'

const StyledMemberItem = styled.label`
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: var(--hoverBackgroundColor);
    }

    &:active {
        background-color: var(--hoverBackgroundColor);
    }
`

const StyledAvatar = styled(Avatar)`
    margin-right: 16px;
`
const StyledEmptyMessage = styled.div`
    padding: 8px;
    border: 1px solid var(--color_border);
    border-radius: 8px;
    font-weight: 13px;
    text-align: center;
    font-style: italic;
    opacity: 0.64;
`

const StyledMessage = styled.div`
    color: var(--color_text_700);
    font-size: 16px;
    max-width: 40ch;
    text-align: center;
    margin: 16px auto 0 auto;
    padding: 16px;
`

const StyledAccountEmail = styled.span`
    opacity: 0.67;
    font-size: 13px;
    margin-top: 0px;
`

const StyledTextGroup = styled.div`
    display: flex;
    flex-direction: column;
`

export const LinkToMemberModal = ({ isModalShowing, hide, ...rest }) => {
    const { leaves } = useContext(LeavesContext)
    const { memberData } = useContext(MemberContext)
    const { panelState, setPanelState } = useContext(DetailsPanelContext)
    const { currentTreeMembers } = useContext(CurrentTreeMembersContext)
    const [toggleInviteMemberModal, isInviteMemberModalShowing] = useModal(
        false
    )
    const [treeMembers, setTreeMembers] = useState([])
    const [selectedMember, setSelectedMember] = useState()
    const [
        toggleLinkedMemberHelperModal,
        isLinkedMemberHelperModalShowing,
    ] = useModal(false)
    const { leafImages, getImageUrlById } = useContext(LeafImagesContext)

    useEffect(() => {
        if (panelState.treeDoc) {
            if (leaves) {
                const linkedLeaves = leaves
                    .filter((leaf) => leaf.claimed_by)
                    .map((leaf) => leaf.claimed_by)
                let newUnlinkedMembers = []
                const thing = panelState?.treeDoc?.members.map((memberId) => {
                    if (!linkedLeaves.includes(memberId)) {
                        let memberDocData =
                            memberId === memberData?.id
                                ? memberData
                                : currentTreeMembers.find(
                                      (member) => member.id === memberId
                                  )
                        memberDocData && newUnlinkedMembers.push(memberDocData)
                    }
                })

                setTreeMembers(newUnlinkedMembers)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panelState.treeDoc, currentTreeMembers])

    const handleSelectMember = (leafId) => {
        setSelectedMember(leafId)
    }

    const handleClose = () => {
        setSelectedMember(null)
        hide()
    }

    const handleSubmit = () => {
        if (!selectedMember) {
            alert('Select a member')
        } else {
            let treeRef = firebase
                .firestore()
                .collection('trees')
                .doc(panelState.treeId)

            treeRef
                .collection('leaves')
                .doc(panelState.leafId)
                .update({ claimed_by: selectedMember })
                .then(() => {
                    // window.location.reload();
                    console.log(
                        `${panelState.leafId} is claimed by ${selectedMember}`
                    )
                    const memberDoc = currentTreeMembers.find(
                        (member) => member.id === selectedMember
                    )
                    setPanelState({
                        ...panelState,
                        linkedMemberDoc: memberDoc || null,
                        leafDoc: panelState.leafDoc,
                    })
                })
                .catch((err) => {
                    console.log(err.message)
                })
            hide()
        }
    }

    return (
        <>
            <Modal
                isShowing={isLinkedMemberHelperModalShowing}
                zIndexOrder={3}
                size="s"
                hide={() => toggleLinkedMemberHelperModal()}
                {...rest}
            >
                <Card.Container>
                    <Card.Content>
                        <p>
                            A claimed leaf will show the profile of the member
                            who claimed it. This puts the member in control of
                            the information shown on the tree.
                        </p>
                    </Card.Content>
                    <Card.Footer>
                        <Button
                            solid={true}
                            fullWidth={true}
                            onClick={() => toggleLinkedMemberHelperModal()}
                        >
                            Close
                        </Button>
                    </Card.Footer>
                </Card.Container>
            </Modal>
            <Modal
                isShowing={isModalShowing}
                hide={() => handleClose()}
                size="m"
                closeButton={true}
                zIndexOrder={2}
            >
                <Card.Container>
                    <Card.Header
                        title="Claim a leaf"
                        titleAs="h2"
                        align="center"
                    >
                        <StyledMessage
                        // size="m"
                        // onClick={() => toggleLinkedMemberHelperModal()}
                        // transparent={true}
                        >
                            A claimed leaf will inherit the photo and profile of
                            the member who claimed it.
                            {/* What will this do? */}
                        </StyledMessage>
                    </Card.Header>
                    <Card.Content maxHeight="45vh">
                        {treeMembers.length === 0 && (
                            <StyledEmptyMessage>
                                All members have claimed a leaf.
                                {/* Consider{' '}
                                <a href="#" onClick={toggleInviteMemberModal}>
                                    inviting more family members
                                </a>{' '}
                                to your tree! */}
                            </StyledEmptyMessage>
                        )}
                        {treeMembers.map((member) => (
                            <StyledMemberItem
                                key={member.id}
                                onClick={() => handleSelectMember(member.id)}
                            >
                                <StyledAvatar
                                    src={getImageUrlById(member.id)}
                                    size="xs"
                                    borderRadius="8px"
                                />
                                <StyledTextGroup>
                                    <div>
                                        {member.display_name ||
                                            member.first_name}{' '}
                                        {member.last_name_current}
                                    </div>
                                    <StyledAccountEmail>
                                        {member.account_email}
                                    </StyledAccountEmail>
                                </StyledTextGroup>
                                {/* <input 
                                name="memberSelectionGrou" 
                                id={member.id} 
                                className="u-mar-l_auto" 
                                type="radio"/> */}
                                <Checkbox
                                    type="radio"
                                    name="memberSelectionGrou"
                                    id={member.id}
                                    className="u-mar-l_auto"
                                />
                            </StyledMemberItem>
                        ))}
                    </Card.Content>
                    <Card.Footer>
                        <ButtonGroup>
                            <Button
                                onClick={() => handleClose()}
                                whiteTransparent={true}
                            >
                                Cancel
                            </Button>
                            <Button
                                solid={true}
                                iconLeft="link"
                                onClick={() => handleSubmit()}
                            >
                                Claim leaf
                            </Button>
                        </ButtonGroup>
                    </Card.Footer>
                </Card.Container>
            </Modal>
            <InviteModal
                isModalShowing={isInviteMemberModalShowing}
                hide={toggleInviteMemberModal}
                zIndexOrder={3}
            />
        </>
    )
}

export default LinkToMemberModal
