import React, { useState, useContext, useRef, useEffect } from 'react'
import Modal from '../organisms/Modal'
import Card from '../organisms/Card'
import InputGroup from '../organisms/InputGroup'
import Button from '../atoms/Button'
import ButtonGroup from '../organisms/ButtonGroup'
import styled from 'styled-components'
import Icon from '../atoms/Icon'
import firebase, { firestore } from 'firebase/app'
import toaster from 'toasted-notes'
import { UserContext } from '../../contexts/UserContext'

const StyledHelper = styled.p`
    font-size: 14px;
    opacity: 0.74;
    font-style: italic;
    display: flex;
`

const StyledTipcIcon = styled(Icon)`
    margin-top: 4px;
    margin-right: 8px;
`

export const NewTreeModal = ({ isModalShowing, hide, ...rest }) => {
    const { userId } = useContext(UserContext)
    const treeInput = useRef()
    const [errorMessage, setErrorMessage] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        const treeName = form['name'].value

        if (treeName) {
            let newTreeObject = {
                created_by: userId,
                members: [userId],
                member_permissions: [
                    {
                        id: userId,
                        permission: 'admin',
                    },
                ],
                name: treeName,
                invitations: [],
            }

            const tree_ref = firebase.firestore().collection('trees')
            tree_ref
                .add(newTreeObject)
                .then((newTreeRef) => {
                    toaster.notify(`Tree created`)
                    window.history.pushState(
                        null,
                        null,
                        `trees/${newTreeRef.id}`
                    )
                    window.location.reload()
                })
                .catch(() => {
                    toaster.notify(`An error occured`)
                })

            hide()
        } else {
            setErrorMessage('Enter a tree name.')
        }
    }

    useEffect(() => {
        isModalShowing && treeInput.current.focus()
    }, [isModalShowing])

    return (
        <Modal
            isShowing={isModalShowing}
            hide={() => hide()}
            size="s"
            closeButton={false}
        >
            <Card.Container as="form" onSubmit={(e) => handleSubmit(e)}>
                <Card.Content>
                    <InputGroup
                        label="Tree name"
                        defaultValue=""
                        ref={treeInput}
                        name="name"
                        errorMessage={errorMessage}
                    />
                    <StyledHelper>
                        <StyledTipcIcon icon="lightbulb-on" />
                        Choose the last name of the person from whom the tree
                        will branch.
                    </StyledHelper>
                </Card.Content>
                <Card.Footer>
                    <ButtonGroup>
                        <Button
                            type="button"
                            whiteTransparent={true}
                            onClick={() => hide()}
                        >
                            Cancel
                        </Button>
                        <Button>Create</Button>
                    </ButtonGroup>
                </Card.Footer>
            </Card.Container>
        </Modal>
    )
}

export default NewTreeModal
