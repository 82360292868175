import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { getLeafImage } from '../../assets/js/utils'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import { LeafImagesContext } from '../../contexts/LeafImageContext'
import LeavesContext from '../../contexts/LeavesContext'
import useTreeMembers from '../../hooks/useTreeMembers'
import Avatar from '../atoms/Avatar'
import Icon from '../atoms/Icon'

const StyledLeafItem = styled.div`
    display: flex;
    padding: 8px;
    border-radius: 80px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color_background_depth_2);

    &:hover,
    &:focus {
        background-color: var(--hoverBackgroundColor);
        cursor: pointer;
        outline: none;
        color: white;
        // box-shadow: 0 0 0 2px var(--blue_400) inset;
    }

    &:active {
        background-color: var(--activeBackgroundColor);
    }

    ${(props) =>
        props.active &&
        css`
            background-color: var(--hoverBackgroundColor);
            pointer-events: none;
        `}
`

const StyledAvatarNameContainer = styled.div`
    display: flex;
    align-items: center;
`

const StyledNameSubtitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-contents: center;
    margin-left: 8px;
`

const StyledName = styled.div`
    font-size: 14px;
    font-weight: bold;
    flex: 1;
`

const StyledSubtitle = styled.div`
    font-size: 13px;
    color: var(--color_text_600);
    margin-top: -4px;
`

const StyledIcon = styled(Icon)`
    margin-right: 8px;
    font-size: 12px;
    opacity: 0.85;
`

export const LinkToLeafItem = ({ doc, subtitle, ...rest }) => {
    const { panelState, setPanelState } = useContext(DetailsPanelContext)
    const { leaves } = useContext(LeavesContext)
    const { getClaimedBy } = useTreeMembers()
    const { leafImages, getImageUrlById } = useContext(LeafImagesContext)

    const memberDoc = getClaimedBy(doc?.claimed_by)
    const leafDoc = leaves.find((leaf) => {
        if (leaf.id === doc.id) {
            return leaf
        }
    })

    const highlightLeaf = (shouldHighlight, leafId) => {
        let leafEl = document.querySelector(`[data-id="${leafId}"]`) || null

        if (leafEl) {
            if (shouldHighlight) {
                leafEl.classList.add('highlight')
            } else {
                leafEl.classList.remove('highlight')
            }
        }
    }

    return (
        <StyledLeafItem
            tabIndex={0}
            key={doc?.id}
            onMouseOut={() => highlightLeaf(false, doc?.id)}
            onMouseOver={() => highlightLeaf(true, doc?.id)}
            onClick={() =>
                setPanelState({
                    ...panelState,
                    leafId: doc?.id,
                    leafDoc: leafDoc,
                    linkedMemberDoc: memberDoc,
                })
            }
            {...rest}
        >
            <StyledAvatarNameContainer>
                <Avatar
                    size="xxs"
                    src={getImageUrlById(memberDoc?.id || doc?.id)}
                    borderRadius={memberDoc ? '12px' : '500px'}
                />
                <StyledNameSubtitleContainer>
                    <StyledName>
                        {memberDoc?.display_name || doc?.display_name}
                    </StyledName>
                    {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
                </StyledNameSubtitleContainer>
            </StyledAvatarNameContainer>
            <StyledIcon icon="chevron-right" />
        </StyledLeafItem>
    )
}

export default LinkToLeafItem
