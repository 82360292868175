import React from 'react'
import styled, { css } from 'styled-components'
import Header from '../atoms/Header'

const StyledCardHeader = styled.div`
    padding: var(--cardPadding);
`
const StyledCardContent = styled.div`
    padding: var(--cardPadding);

    ${(props) =>
        props.maxHeight &&
        css`
            max-height: ${props.maxHeight || 'unset'};
            overflow: auto;
        `}
`
const StyledCardFooter = styled.div`
    padding: var(--cardPadding);
`

const StyledCard = styled.div`
    --cardMaxWidth: 100%;
    --cardPadding: 24px;
    width: var(--cardMaxWidth);
    box-shadow: var(--leafShadow);
    border-radius: 8px;
    background-color: var(--color_background_depth_2);
    overflow: hidden;

    &__header {
        padding: var(--cardHeaderPadding, var(--cardPadding));
    }

    &__content {
        padding: var(--cardContentPadding, var(--cardPadding));
    }

    &__footer {
        padding: var(--cardFooterPadding, var(--cardPadding));
    }

    &.full_width {
        --cardMaxWidth: 100%;
    }

    ${(props) =>
        props.glassy &&
        css`
            @supports (backdrop-filter: blur(20px)) {
                backdrop-filter: blur(20px), brightness;
                background-color: rgba(255, 255, 255, 0.65);
            }
        `};

    ${(props) =>
        props.large &&
        css`
            --cardMaxWidth: 800px;
        `};

    ${(props) =>
        props.medium &&
        css`
            --cardMaxWidth: 560px;
        `};

    ${(props) =>
        props.small &&
        css`
            --cardMaxWidth: 320px;
        `};
`

const StyledTitle = styled(Header)`
    margin: 0;

    ${(props) =>
        props.align &&
        css`
            text-align: center;
        `}
`

export const Card = {
    Container: function ({ children, ...rest }) {
        return <CardContainer {...rest}>{children}</CardContainer>
    },
    Header: function ({ children, align, title, titleAs = 'h2', ...rest }) {
        return (
            <StyledCardHeader {...rest}>
                {title && (
                    <StyledTitle as={titleAs} align={align}>
                        {title}
                    </StyledTitle>
                )}
                {children}
            </StyledCardHeader>
        )
    },
    Content: function ({ children, maxHeight, ...rest }) {
        return (
            <StyledCardContent maxHeight={maxHeight} {...rest}>
                {children}
            </StyledCardContent>
        )
    },
    Footer: function ({ children, ...rest }) {
        return <StyledCardFooter {...rest}>{children}</StyledCardFooter>
    },
}

export const CardContainer = ({ children, ...rest }) => {
    return <StyledCard {...rest}>{children}</StyledCard>
}

export default Card
