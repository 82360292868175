import React, { useContext } from 'react'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import styled, { css } from 'styled-components'

const StyledContainer = styled.div`
    max-width: 100%;
    flex: 1;
    // padding-left: var(--navPanelWidthCollapsed);
    padding-right: unset;
    z-index: 1;
    transition: all var(--speed) ease-out;
    position: relative;

    &.detailsPanelOpen {
        padding-right: var(--detailsPanelWidth);
        transition: all var(--speed) ease-out;
    }

    ${(props) =>
        props.includeDetailsPanel &&
        css`
            padding-right: 0px !important;
            transition: 0s;
        `};
`

export const FlexibleContainer = ({ children, includeDetailsPanel }) => {
    return (
        <StyledContainer includeDetailsPanel={includeDetailsPanel}>
            {children}
        </StyledContainer>
    )
}

export default FlexibleContainer
