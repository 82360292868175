import React from 'react'
import styled from 'styled-components'

const StyledIcon = styled.i`
    font-size: ${(props) => props.size || 'inherit'};
`

export const Icon = ({
    type,
    iconType,
    style,
    fullWidth,
    icon,
    size,
    className,
    ...rest
}) => {
    let iconStyle
    let klass

    switch (iconType || style) {
        case 'regular':
            iconStyle = 'far'
            break
        case 'solid':
            iconStyle = 'fas'
            break
        case 'light':
            iconStyle = 'fal'
            break
        case 'duotone':
            iconStyle = 'fad'
            break
        default:
            iconStyle = 'far'
    }

    klass = `${fullWidth && 'fa-fw'} ${iconStyle} fa-${icon} ${className}`

    return <StyledIcon {...rest} size={size} className={klass}></StyledIcon>
}

Icon.defaultProps = {
    style: 'light',
    icon: null,
}

export default Icon
