import React, {
    useState,
    useEffect,
    useContext,
    useRef,
    useCallback,
} from 'react'
import { DetailsPanel } from '../components/general/DetailsPanel/DetailsPanel'
import firebase from 'firebase/app'
import DetailsPanelContext from '../contexts/DetailsPanelContext'
import styled, { css } from 'styled-components'
import LeavesContext from '../contexts/LeavesContext'
import { getMemberPermission, setCssVar, getCssVar } from '../assets/js/utils'
import MemberContext from '../contexts/MemberContext'
import LeafList from '../components/general/LeafList'
import Branch from '../components/general/Branch'
import Header from '../components/atoms/Header'
import Icon from '../components/atoms/Icon'
import Button from '../components/atoms/Button'
import ViewPreferenceContext from '../contexts/ViewPreferenceContext'
import PromptDisplayNameModal from '../components/general/PromptDisplayNameModal'
import { useCollection } from 'react-firebase-hooks/firestore'
import { useParams } from 'react-router'
import IconButton from '../components/atoms/IconButton'
import ButtonGroup from '../components/organisms/ButtonGroup'
import Dropdown from '../components/organisms/Dropdown'
import { WindowSizeContext } from '../contexts/WindowSizeContext'
import SideMenu from '../components/general/SideMenu'

const StyledContainer = styled.div`
    --treeHeaderHeight: calc(8px * 15);
    --viewHeaderHeight: calc(8px * 10);
    flex: 1;
    max-height: calc(100% - var(--treeNavHeight));
    max-height: calc(100vh - var(--treeNavHeight));
    height: calc(100% - var(--treeNavHeight));
    height: calc(100vh - var(--treeNavHeight));
    display: flex;
    flex-direction: column;
    background-color: var(--color_background_depth_4);

    position: relative;
    z-index: 1;

    flex: 1;
    overflow: auto;
`

const StyledBranchContainer = styled.div`
    padding-left: 0;
    padding-right: var(--dragHandleWidth);
    flex: 1;
    display: flex;
    overflow: auto;
`

const StyledFlexibleContainer = styled.div`
    transition: padding-right var(--speed) ease-out;
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: auto;
`

const StyledFlexyWrapper = styled.div`
    position: relative;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    transition: min-width 0.2s ease-out, width 0.2s ease-out;
`

const StyledPanelToggleContainer = styled.div`
    position: absolute;
    left: calc(-1 * var(--dragHandleWidth));
    top: 0;
    z-index: 100;
    padding: 16px;

    ${(props) =>
        !props.showPanel &&
        css`
            left: 0;
        `}
`

const StyledTest = styled.div`
    padding: 16px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    z-index: 99;

    ${(props) =>
        props.isTable &&
        css`
            position: unset;
            border-bottom: 1px solid var(--color_border);
        `}
`

const StyledPrivateTreeContainer = styled.div`
    max-width: 600px;
    border-radius: 16px;
    padding: 32px;
    display: flex;
    align-self: flex-start;
    justify-content: center;
    flex-direction: column;
    margin: 10vh auto 0 auto;
    text-align: center;
`

const StyledHeader = styled(Header)`
    margin-top: 16px;
    margin-bottom: 16px;
`

const StyledMessage = styled.p`
    margin: 0;
    opacity: 0.7;
`

const StyledRemoveButton = styled(Button)`
    margin-top: 40px;
`

const StyledPanZoomButtonGroup = styled(ButtonGroup)`
    margin-right: 24px;
`

const StyledDragHandle = styled.div`
    display: flex;
    width: var(--dragHandleWidth);
    cursor: col-resize;
    transition: width 0.1s ease-out;

    &:hover {
        background-color: var(--color_border);
    }
`

const StyledSideMenuContainer = styled.div`
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    overflow: auto;
    display: none;

    ${(props) =>
        props.show &&
        css`
            display: block;
        `}
`
const StyledSideMenuWrapper = styled.div`
    background-color: var(--color_15_dark);
    width: clamp(280px, 25%, 320px);
    height: 100vh;
    z-index: 999;
    position: relative;
    transform: translateX(-100%);
    transition: transform 0.1s ease-in;
    left: 0;
    top: 0;

    ${(props) =>
        props.show &&
        css`
            transform: translateX(0%);
        `}
`

const SideMenuClose = styled(IconButton)`
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 1000;
`

const StyledOverlay = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 998;
`

export const TreePage = ({ treeId, ...rest }) => {
    const { leaves, setLeaves } = useContext(LeavesContext)
    const { panelState, setPanelState } = useContext(DetailsPanelContext)
    const { memberData } = useContext(MemberContext)
    const [isEmpty, setIsEmpty] = useState(false)
    const [memberPermission, setMemberPermission] = useState(null)
    const [isMember, setIsMember] = useState(false)
    const treesRef = firebase.firestore().collection('trees')
    const memberRef = firebase.firestore().collection('members')
    const { viewPreference, setViewPreference } = useContext(
        ViewPreferenceContext
    )
    const [value, loading, error] = useCollection(treesRef.doc(treeId))
    const panZoom = useRef()
    const { windowWidth } = useContext(WindowSizeContext)

    const handler = useCallback(() => {
        const onMouseMove = (e) => {
            setCssVar('detailsPanelWidth', e.clientX)
        }
        const onMouseUp = () => {
            window.removeEventListener('mousemove', onMouseMove)
            window.removeEventListener('mouseup', onMouseUp)
            document.body.classList.remove('no-user-select')
        }
        window.addEventListener('mousemove', onMouseMove)
        window.addEventListener('mouseup', onMouseUp)

        document.body.classList.add('no-user-select')
    }, [])

    const updateTreeAndMember = (treeId, memberId) => {
        const treeRef = firebase.firestore().collection('trees').doc(treeId)

        treeRef.get().then((doc) => {
            let treeMembers = doc.data().members

            let arrayOfMemberPermissions = Object.entries(treeMembers).map(
                ([memberId, permission]) => {
                    return {
                        id: memberId,
                        permission: permission,
                    }
                }
            )

            let arrayOfMemberIds = Object.entries(treeMembers).map(
                ([memberId, permission]) => {
                    return memberId
                }
            )

            treeRef
                .update({
                    members: arrayOfMemberIds,
                    member_permissions: arrayOfMemberPermissions,
                })
                .then((ref) => {
                    console.log(`${ref} has been updated`)
                })
                .catch((err) => {
                    console.log(err.message)
                })
        })
    }

    useEffect(() => {
        if (!loading && value.exists) {
            setPanelState({
                ...panelState,
                leafid: null,
                leafDoc: null,
                linkedMemberDoc: null,
                treeDoc: value.data() || null,
                treeId: treeId || null,
            })

            setIsMember(value?.data()?.members?.includes(memberData?.id))
        }
    }, [memberData, value])

    useEffect(() => {
        if (panelState.treeDoc && memberData) {
            setMemberPermission(
                getMemberPermission(memberData.id, panelState.treeDoc)
            )
        }
    }, [panelState.treeDoc, memberData])

    useEffect(() => {
        leaves && setIsEmpty(leaves.length === 0)
    }, [treeId, memberData, leaves])

    const changeViewPreference = (layout) => {
        if (layout) {
            setViewPreference({ layout: layout })
        }
    }

    return (
        <>
            {memberData && <PromptDisplayNameModal />}
            <StyledSideMenuContainer show={panelState.showSideMenu}>
                <StyledSideMenuWrapper show={panelState.showSideMenu}>
                    <SideMenuClose
                        circle
                        whiteTransparent
                        icon="times"
                        onClick={() =>
                            setPanelState({
                                ...panelState,
                                showSideMenu: false,
                            })
                        }
                    />
                    <SideMenu />
                </StyledSideMenuWrapper>

                <StyledOverlay
                    onClick={() =>
                        setPanelState({ ...panelState, showSideMenu: false })
                    }
                />
            </StyledSideMenuContainer>
            {value && (
                <StyledContainer>
                    <StyledFlexibleContainer>
                        {isMember ? (
                            <>
                                <DetailsPanel
                                    memberPermission={memberPermission}
                                />
                                {panelState.showPanel && (
                                    <StyledDragHandle
                                        onMouseDown={() => handler()}
                                        onTouchStart={() => handler()}
                                    />
                                )}
                                <StyledFlexyWrapper>
                                    <StyledPanelToggleContainer
                                        showPanel={panelState.showPanel}
                                    >
                                        <IconButton
                                            size="m"
                                            whiteTransparent={true}
                                            icon="sidebar"
                                            square={true}
                                            font-size="16px"
                                            data-tooltip={
                                                panelState.showPanel
                                                    ? 'Hide panel'
                                                    : 'Show panel'
                                            }
                                            data-tooltip-position="right"
                                            onClick={() => {
                                                setPanelState({
                                                    ...panelState,
                                                    showPanel: !panelState.showPanel,
                                                })
                                            }}
                                        />
                                    </StyledPanelToggleContainer>
                                    <StyledTest
                                        isTable={
                                            viewPreference.layout === 'table'
                                        }
                                    >
                                        {viewPreference.layout === 'tree' && (
                                            <StyledPanZoomButtonGroup gap="0">
                                                <IconButton
                                                    size="m"
                                                    square={true}
                                                    fontSize="16px"
                                                    icon="redo"
                                                    onClick={() =>
                                                        panZoom.current.reset()
                                                    }
                                                    whiteTransparent={true}
                                                    data-tooltip="Reset view"
                                                    data-tooltip-position="bm"
                                                />
                                                <IconButton
                                                    size="m"
                                                    square={true}
                                                    fontSize="16px"
                                                    icon="plus"
                                                    onClick={() =>
                                                        panZoom.current.zoomIn(
                                                            5
                                                        )
                                                    }
                                                    whiteTransparent={true}
                                                    data-tooltip="Zoom in"
                                                    data-tooltip-position="bm"
                                                />
                                                <IconButton
                                                    size="m"
                                                    square={true}
                                                    fontSize="16px"
                                                    icon="minus"
                                                    onClick={() =>
                                                        panZoom.current.zoomOut(
                                                            5
                                                        )
                                                    }
                                                    whiteTransparent={true}
                                                    data-tooltip="Zoom out"
                                                    data-tooltip-position="bm"
                                                />
                                            </StyledPanZoomButtonGroup>
                                        )}
                                        <ButtonGroup gap="0">
                                            <IconButton
                                                icon="sitemap"
                                                size="m"
                                                square={true}
                                                // whiteTransparent={
                                                //     viewPreference.layout !==
                                                //     'tree'
                                                // }
                                                whiteTransparent={
                                                    viewPreference.layout !==
                                                    'tree'
                                                }
                                                data-tooltip="Family tree"
                                                data-tooltip-position="bm"
                                                fontSize="16px"
                                                onClick={() =>
                                                    changeViewPreference('tree')
                                                }
                                            />
                                            <IconButton
                                                icon="table"
                                                size="m"
                                                square={true}
                                                whiteTransparent={
                                                    viewPreference.layout !==
                                                    'table'
                                                }
                                                // solid={
                                                //     viewPreference.layout ===
                                                //     'table'
                                                // }
                                                data-tooltip="Directory"
                                                data-tooltip-position="bm"
                                                fontSize="16px"
                                                onClick={() =>
                                                    changeViewPreference(
                                                        'table'
                                                    )
                                                }
                                            />
                                        </ButtonGroup>
                                    </StyledTest>
                                    <StyledBranchContainer>
                                        {!isEmpty &&
                                            viewPreference.layout ===
                                                'tree' && (
                                                <Branch ref={panZoom} />
                                            )}

                                        {!isEmpty &&
                                            viewPreference.layout ===
                                                'table' && <LeafList />}
                                    </StyledBranchContainer>
                                </StyledFlexyWrapper>
                            </>
                        ) : (
                            <>
                                {!memberData ||
                                    (value?.exists && (
                                        <StyledPrivateTreeContainer>
                                            <Icon icon="lock-alt" size="32px" />
                                            <StyledHeader>
                                                Private tree
                                            </StyledHeader>
                                            <StyledMessage>
                                                This tree can only be viewed by
                                                its members.
                                            </StyledMessage>
                                        </StyledPrivateTreeContainer>
                                    ))}
                            </>
                        )}
                    </StyledFlexibleContainer>
                </StyledContainer>
            )}
            {!loading && !value?.exists && (
                <StyledPrivateTreeContainer>
                    <Icon icon="exclamation-triangle" size="32px" />
                    <StyledHeader>Tree not found</StyledHeader>
                    <StyledMessage>
                        This tree has been deleted or never existed.
                    </StyledMessage>
                    {/* <StyledRemoveButton
                        outline={true}
                        onClick={() => handleRemoveSelfFromTree()}
                    >
                        Remove this tree from your list
                    </StyledRemoveButton> */}
                </StyledPrivateTreeContainer>
            )}
        </>
    )
}

export default TreePage
