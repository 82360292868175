import React, { useCallback, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import IconButton from '../atoms/IconButton'

const StyledOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: calc(var(--overlayIndex) * ${(props) => props.zIndexOrder || 1});
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
`

const StyledModalWrapper = styled.div`
    --modalWrapperIndex: 1020;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    z-index: calc(
        var(--modalWrapperIndex) * ${(props) => props.zIndexOrder || 1}
    );
    padding-left: 16px;
    padding-right: 16px;

    :focus:not(:focus-visible) {
        box-shadow: none;
    }
`

const StyledModal = styled.div`
    --modalSize: 100%;
    --modalTopPosition: 56px;
    top: var(--modalTopPosition);
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    position: fixed;
    box-shadow: var(--leafShadow);
    z-index: calc(var(--modalIndex) * ${(props) => props.zIndexOrder || 1});
    max-width: calc(100vw - 48px);
    width: var(--modalSize);
    overflow: auto;
    border-radius: 16px;
    max-height: calc(100vh - var(--modalTopPosition) - 24px);

    ${(props) =>
        props.size &&
        css`
            --modalSize: ${props.size || '480px'};
        `}
    ${(props) =>
        props.size === 'xxl' &&
        css`
            --modalSize: 800px;
        `}
    ${(props) =>
        props.size === 'xl' &&
        css`
            --modalSize: 680px;
        `}
    ${(props) =>
        props.size === 'l' &&
        css`
            --modalSize: 560px;
        `}
    ${(props) =>
        props.size === 'm' &&
        css`
            --modalSize: 480px;
        `}
    ${(props) =>
        props.size === 's' &&
        css`
            --modalSize: 320px;
        `}
`

const StyledCloseButton = styled(IconButton)`
    position: absolute;
    top: 16px;
    right: 16px;
`

const Modal = ({
    isShowing,
    hide,
    children,
    size,
    closeButton,
    zIndexOrder,
}) => {
    const modalRef = useRef()
    const keyPress = useCallback(
        (e) => {
            if (isShowing && e.key === 'Escape') {
                hide()
            }
        },
        [isShowing]
    )

    useEffect(() => {
        if (isShowing) {
            modalRef.current.focus()
            document.addEventListener('keydown', keyPress)
            return () => document.removeEventListener('keydown', keyPress)
        }
    }, [keyPress])

    return (
        isShowing &&
        ReactDOM.createPortal(
            <>
                <StyledModalWrapper zIndexOrder={zIndexOrder}>
                    <StyledModal
                        ref={modalRef}
                        zIndexOrder={zIndexOrder}
                        size={size}
                        className={size}
                        aria-modal={true}
                        aria-hidden
                        tabIndex={-1}
                        role="dialog"
                    >
                        {closeButton && (
                            <StyledCloseButton
                                label="Close modal"
                                icon="times"
                                circle={true}
                                type="button"
                                whiteTransparent={true}
                                onClick={hide}
                            />
                        )}
                        {children}
                    </StyledModal>
                </StyledModalWrapper>
                <StyledOverlay zIndexOrder={zIndexOrder} onClick={hide} />
            </>,
            document.body
        )
    )
}
// const Modal = ({
//     isShowing,
//     hide,
//     children,
//     size="m",
//     closeButton,
//     zIndexOrder
// }) => isShowing ? ReactDOM.createPortal(
//   <>
//     <StyledModalWrapper zIndexOrder={zIndexOrder}>
//         <StyledModal
//             zIndexOrder={zIndexOrder}
//             size={size}
//             className={size}
//             aria-modal={true}
//             aria-hidden tabIndex={-1}
//             role="dialog"
//             >
//             {closeButton &&
//                 <StyledCloseButton label="Close modal" icon="times" circle={true} whiteTransparent={true} onClick={hide}/>}
//                 {children}
//         </StyledModal>
//     </StyledModalWrapper>
//     <StyledOverlay zIndexOrder={zIndexOrder} onClick={hide}/>
//   </>, document.body
// ) : null;

export default Modal
