import React, { createContext, useState, useEffect } from 'react'

const ViewPreferenceContext = createContext()

export const ViewPreferenceContextProvider = (props) => {
    const [viewPreference, setViewPreference] = useState({
        layout: 'tree',
    })

    return (
        <ViewPreferenceContext.Provider
            value={{
                viewPreference,
                setViewPreference,
            }}
        >
            {props.children}
        </ViewPreferenceContext.Provider>
    )
}

export default ViewPreferenceContext
