import React from 'react'
import styled, { css } from 'styled-components'

const StyledTrack = styled.div`
    --toggleControlSize: 32px;
    --controlInset: 12px;
    --trackOnColor: var(--color_10_light);
    --trackOffColor: var(--brand_200);

    ${(props) =>
        props.theme.mode === 'dark' &&
        css`
            --trackOnColor: var(--color_10_light);
            --trackOffColor: var(--hoverBackgroundColor);
        `}

    width: calc(var(--toggleControlSize) * 2);
    height: var(--toggleControlSize);
    border-radius: calc(var(--toggleControlSize) / 2.5);
    position: relative;
    background-color: var(--trackOffColor);
    transition: background-color 0.1s ease;

    &:active {
        background-color: var(--brand_500);
        transition: none;
    }

    &:focus-within {
        box-shadow: 0 0 0px 2px var(--color_background_depth_2),
            0 0 0px 4px var(--color_outline);
    }

    ${(props) =>
        props.checked &&
        css`
            background-color: var(--trackOnColor);

            &:active {
                background-color: var(--blue_200);
            }
        `}
`

const StyledControl = styled.div`
    position: absolute;
    width: calc(var(--toggleControlSize) - var(--controlInset));
    height: calc(var(--toggleControlSize) - var(--controlInset));
    border-radius: calc(var(--controlInset) / 1.5);
    background-color: white;
    z-index: 1;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.1s ease;

    ${(props) =>
        props.checked &&
        css`
            left: calc(
                var(--toggleControlSize) + calc(var(--controlInset) / 2)
            );
        `}
`

const StyledInput = styled.input`
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &:hover {
        cursor: pointer;
    }
`

const StyledToggleContainer = styled.div`
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr auto;
    align-items: center;
`

export const Toggle = ({
    checked,
    className,
    disabled = false,
    htmlFor,
    onChange,
    label = null,
    labelPosition,
    ...rest
}) => {
    if (label) {
        return (
            <StyledToggleContainer className={className}>
                {label}
                <StyledTrack checked={checked}>
                    <StyledInput
                        type="checkbox"
                        checked={checked}
                        disabled={disabled}
                        htmlFor={htmlFor}
                        onChange={() => onChange && onChange()}
                        {...rest}
                    />
                    <StyledControl checked={checked} />
                </StyledTrack>
            </StyledToggleContainer>
        )
    } else {
        return (
            <StyledTrack className={className} checked={checked}>
                <StyledInput
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    htmlFor={htmlFor}
                    onChange={() => onChange && onChange()}
                    {...rest}
                />
                <StyledControl checked={checked} />
            </StyledTrack>
        )
    }
}

export default Toggle
