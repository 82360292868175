import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { Link, useRouteMatch } from 'react-router-dom'
import Icon from '../atoms/Icon'
import IconButton from '../atoms/IconButton'
import { UserContext } from '../../contexts/UserContext'
import ButtonGroup from '../organisms/ButtonGroup'
import { LinkButton } from '../atoms/LinkButton'
import Avatar from '../atoms/Avatar'
import { getLeafImage } from '../../assets/js/utils'
import MemberContext, {
    MemberContextProvider,
} from '../../contexts/MemberContext'
import { NotificationsContext } from '../../contexts/NotificationsContext'

const StyledLinkContainer = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding: 16px;
`

const menuItemStyle = css`
    padding: 14px 16px;
    border-radius: 8px;
    color: white;
    text-decoration: none;
    // margin-bottom: 8px;
    font-size: 15px;
    display: flex;
    align-items: center;

    &:hover {
        background-color: var(--color_15_light);
        color: white;
    }

    &:active {
        background-color: var(--color_15_dark);
        color: white;
    }
`

const StyledMenuItem = styled(Link)`
    ${menuItemStyle}
    width: 100%;

    ${(props) =>
        props.active === 'true' &&
        css`
            color: white;
            background-color: var(--color_15_light);
            pointer-events: none;

            &:hover,
            &:active {
                color: white;
            }
        `}
`

const StyledLeftIcon = styled(Icon)`
    font-size: 16px;
    margin-right: 16px;
`

const StyledDivider = styled.div`
    height: 1px;
    background-color: var(--color_15_light);
    width: calc(100% - 80px);
    margin: 8px auto;
`

const StyledNotificationBadge = styled.div`
    --badgeSize: 24px;
    width: var(--badgeSize);
    height: var(--badgeSize);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 99;
    font-size: 14px;
    background-color: var(--color_10_dark);
    color: white;
    font-weight: bold;
    margin-left: auto;
`

export const UserInfoContainer = ({
    iconLeft,
    children,
    exact,
    label,
    to,
    activeOnlyWhenExact,
    className,
    ...rest
}) => {
    const { memberData, memberPhoto } = useContext(MemberContext)

    let match = useRouteMatch({
        path: to,
        exact: activeOnlyWhenExact,
    })

    return (
        <StyledUserInfoContainer
            to={to}
            exact="true"
            active={match ? 'true' : 'false'}
            className={className}
            {...rest}
        >
            <Avatar size="s" src={memberPhoto} borderRadius="12px" />
            <StyledUserInfo>
                <StyledUserName>{memberData?.display_name}</StyledUserName>
                <StyledUserEmail>{memberData?.account_email}</StyledUserEmail>
                <StyledEditProfile>View profile</StyledEditProfile>
            </StyledUserInfo>
        </StyledUserInfoContainer>
    )
}

export const SideMenuItem = ({
    iconLeft,
    children,
    exact,
    isExact,
    label,
    to,
    activeOnlyWhenExact,
    ...rest
}) => {
    let match = useRouteMatch({
        path: to,
        exact: activeOnlyWhenExact,
        isExact: true,
    })

    return (
        <StyledMenuItem
            to={to}
            exact="true"
            active={match?.isExact ? 'true' : 'false'}
            {...rest}
        >
            {iconLeft && (
                <StyledLeftIcon
                    fullWidth={true}
                    iconType={'regular'}
                    icon={iconLeft}
                />
            )}
            {label}
            {children}
        </StyledMenuItem>
    )
}

const StyledUserInfo = styled.div`
    max-with: 100%;
    overflow: hidden;
`

const StyledUserName = styled.div`
    font-weight: bold;
    color: white;
`

const StyledUserEmail = styled.div`
    font-size: 13px;
    color: white;
    // color: var(--color_text_600);
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: nowrap;
    max-width: 100%;
    overflow: hidden;
    margin-top: -2px;
    margin-bottom: 4px;
`

const StyledUserInfoContainer = styled(Link)`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 16px;
    margin-bottom: 48px;
    text-decoration: none;
    padding: 12px;
    border-radius: 12px;
    color: inherit;

    &:hover {
        background-color: var(--color_15_light);
        color: inherit;
    }

    &:active {
        background-color: var(--color_15_dark);
        color: inherit;
    }

    ${(props) =>
        props.active === 'true' &&
        css`
            background-color: var(--color_15_light);
            color: white;
            pointer-events: none;

            ${StyledUserEmail} {
                color: white;
            }
        `}
`

const StyledEditProfile = styled.div`
    font-size: 14px;
    color: white;

    &:hover {
        text-decoration: underline;
    }
`

const StyledLogo = styled(Link)`
    font-size: 22px;
    padding: 8px;
    display: inline-flex;
    margin-bottom: 56px;
    margin-top: 32px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: white;
    padding-left: 40px;
    height: calc(var(--navHeight) - 32px);
    text-decoration: none;
    border-radius: 8px;
    font-family: var(--fontFamily_header);
`

export const SideMenu = ({ ...rest }) => {
    const { notifications } = useContext(NotificationsContext)

    return (
        <StyledLinkContainer>
            <>
                <StyledLogo to={'/trees'}>Mily</StyledLogo>
                {/* <UserInfoContainer to="/profile" /> */}
                <SideMenuItem
                    to="/trees"
                    label="Family trees"
                    iconLeft="home"
                />
                <StyledDivider />
                <SideMenuItem
                    to="/profile"
                    label="My Profile"
                    iconLeft="user-circle"
                ></SideMenuItem>
                {/* <SideMenuItem to="/profile" label="Profile" iconLeft="user-circle"/> */}
                {/* <StyledDivider />
                    <SideMenuItem
                        to="/notifications"
                        label="Notifications"
                        iconLeft="bell"
                    >
                        {notifications.length > 0 && (
                            <StyledNotificationBadge>
                                {notifications.length}
                            </StyledNotificationBadge>
                        )}
                    </SideMenuItem> */}
                <SideMenuItem
                    to="/settings"
                    label="Preferences"
                    iconLeft="sliders-v"
                />
                {/* <StyledDivider />
                    <SideMenuItem
                        to="/tutorials"
                        label="Tutorials"
                        iconLeft="book-open"
                    /> */}
                {/* <SideMenuItem
                        to="/sign-out"
                        label="Sign out"
                        iconLeft="sign-out"
                    /> */}
            </>
        </StyledLinkContainer>
    )
}

export default SideMenu
