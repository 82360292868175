import React from 'react'
import styled, { css } from 'styled-components'
import { inputStyle } from '../../globalStyles'
import Icon from './Icon'

const StyledSelect = styled.div`
    --selectPadding: 12px 16px;
    --iconPadding: 40px;
    --iconRightPosition: 16px;
    padding: 0;
    position: relative;

    &:after {
        content: '\f0d7';
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        position: absolute;
        right: var(--iconRightPosition);
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
    }

    select {
        ${inputStyle}
        -webkit-appearance: none;
        padding: var(--selectPadding);
        border: 0px solid transparent;
        border-radius: 8px;
        padding-right: var(--iconPadding);
        width: 100%;
        height: 100%;

        &:hover {
            cursor: pointer;
        }

        option[value='choose'] {
            color: var(--brand_500);
        }
    }

    i {
        position: absolute;
        left: 12px;
        top: 50%;
        font-size: 20px;
        transform: translateY(-50%);
        opacity: 0.5;

        & + select {
            padding-left: 44px;
            flex: 1;
        }
    }

    ${(props) =>
        props.label &&
        css`
            margin-top: 8px;
            width: 100%;
        `}

    ${(props) =>
        props.size === 's' &&
        css`
            --selectPadding: 8px 12px;
            --iconPadding: 28px;
            --iconRightPosition: 12px;

            select {
                min-height: unset;
                font-size: 14px;
            }
        `}
`

const StyledLabel = styled.label`
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${(props) =>
        props.alignHorizontal &&
        css`
            flex-direction: row;
        `}
`

export const Select = ({
    children,
    value,
    defaultValue,
    icon,
    style,
    size,
    name,
    label,
    alignHorizontal,
    onChange,
    key,
    ...rest
}) => {
    if (label) {
        return (
            <StyledLabel>
                {label}
                <StyledSelect label={label} size={size} style={style} key={key}>
                    {icon && <Icon icon={icon} style={style} />}
                    <select
                        value={value}
                        defaultValue={defaultValue}
                        name={name}
                        onChange={onChange}
                        {...rest}
                    >
                        {children}
                    </select>
                </StyledSelect>
            </StyledLabel>
        )
    } else {
        return (
            <StyledSelect size={size} {...rest}>
                {icon && <Icon icon={icon} style={style} />}
                <select
                    value={value}
                    defaultValue={defaultValue}
                    name={name}
                    style={style}
                    key={key}
                    onChange={onChange}
                    {...rest}
                >
                    {children}
                </select>
            </StyledSelect>
        )
    }
}

const Option = ({ children, value, ...rest }) => {
    return (
        <option {...rest} value={value}>
            {children}
        </option>
    )
}

Select.Option = Option

export default Select
