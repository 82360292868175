import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import toaster from 'toasted-notes'
import { loopThroughMemberBlueprint } from '../../assets/js/utils'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import Button from '../atoms/Button'
import ButtonGroup from '../organisms/ButtonGroup'
import Card from '../organisms/Card'
import Modal from '../organisms/Modal'
import ProfileModalItems from './ProfileModalItems'
import ProfilieModalItemsEdit from './ProfileModalItemsEdit'
import firebase from 'firebase/app'
import LinkToMemberModal from '../organisms/LinkToMemberModal'
import useModal from '../../hooks/useModal'
import RemoveLeaf from './DetailsPanel/RemoveLeaf'
import useCurrentMemberPermission from '../../hooks/useCurrentMemberPermission'
import useUnlinkLeaf from '../../hooks/useUnlinkLeaf'
import UploadPhotoModal from './UploadPhotoModal'

const StyledCardHeader = styled(Card.Header)`
    padding: 12px;
    border-bottom: 1px solid var(--color_border);
`

const StyledCardContent = styled(Card.Content)`
    padding: 24px 64px 56px 64px;
`

export const ProfileModal = ({ hide, isModalShowing, editMode, ...rest }) => {
    const [isEditMode, setEditMode] = useState(editMode)
    const { panelState } = useContext(DetailsPanelContext)
    const [toggleLinkModal, isLinkModalShowing] = useModal(false)
    const [toggleDeleteModal, isDeleteModalShowing] = useModal(false)
    const [toggleUploadPhotoModal, isUploadPhotoModalShowing] = useModal(false)
    const [
        isAdmin,
        isContributor,
        isViewer,
        memberPermission,
    ] = useCurrentMemberPermission([])
    const [unlinkLeaf] = useUnlinkLeaf()

    const handleClose = () => {
        setEditMode(false)
        hide()
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (e.target['display_name'].value === '') {
            alert('Display name cannot be blank.')
        } else {
            const treeRef = firebase
                .firestore()
                .collection('trees')
                .doc(panelState?.treeId)
            const leafRef = treeRef.collection('leaves').doc(panelState?.leafId)
            const memberRef =
                panelState?.linkedMemberDoc &&
                firebase
                    .firestore()
                    .collection('members')
                    .doc(panelState.linkedMemberDoc.id)

            const ref = panelState?.linkedMemberDoc ? memberRef : leafRef
            let obj = {}

            const firebaseObj = (key, value) => {
                let pathName = value['pathName']
                obj[pathName] = e.target[pathName]?.value || null
            }

            loopThroughMemberBlueprint({
                functionCall: firebaseObj,
            })

            ref.update(obj)
                .then(() => {
                    toaster.notify('Profile updated')
                    setEditMode(false)
                })
                .catch((err) => {
                    toaster.notify(err.message)
                })
        }
    }

    return (
        <>
            <Modal
                isShowing={isModalShowing}
                hide={handleClose}
                size="xxl"
                zIndexOrder={1}
            >
                <Card.Container
                    as={isEditMode ? 'form' : 'div'}
                    onSubmit={(e) => isEditMode && handleSubmit(e)}
                >
                    <StyledCardHeader>
                        <ButtonGroup justifyContent="flex-end">
                            <ButtonGroup>
                                {!isEditMode && (
                                    <>
                                        {!panelState.linkedMemberDoc &&
                                            isAdmin && (
                                                <Button
                                                    iconLeft="pencil-alt"
                                                    size="m"
                                                    onClick={() =>
                                                        setEditMode(true)
                                                    }
                                                >
                                                    Edit profile
                                                </Button>
                                            )}
                                        <Button
                                            size="m"
                                            iconLeft="times"
                                            type="button"
                                            outline={true}
                                            onClick={() => hide()}
                                        >
                                            Close
                                        </Button>
                                    </>
                                )}
                                {isEditMode && (
                                    <>
                                        <Button
                                            size="m"
                                            type="button"
                                            outline={true}
                                            onClick={() => setEditMode(false)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button size="m" type="submit">
                                            Save changes
                                        </Button>
                                    </>
                                )}
                            </ButtonGroup>
                        </ButtonGroup>
                    </StyledCardHeader>
                    <StyledCardContent maxHeight={'70vh'}>
                        {!isEditMode && (
                            <ProfileModalItems
                                hideModal={handleClose}
                                setEditMode={setEditMode}
                            />
                        )}
                        {isEditMode && (
                            <ProfilieModalItemsEdit
                                toggleUploadPhotoModal={toggleUploadPhotoModal}
                                hideModal={handleClose}
                                setEditMode={setEditMode}
                            />
                        )}
                    </StyledCardContent>
                </Card.Container>
                {isUploadPhotoModalShowing && (
                    <UploadPhotoModal
                        zIndexOrder={2}
                        isModalShowing={isUploadPhotoModalShowing}
                        docType={
                            panelState?.linkedMemberDoc ? 'member' : 'leaf'
                        }
                        panelState={panelState}
                        hide={toggleUploadPhotoModal}
                    />
                )}
                {isDeleteModalShowing && (
                    <RemoveLeaf
                        zIndexOrder={2}
                        isModalShowing={isDeleteModalShowing}
                        hide={toggleDeleteModal}
                    />
                )}
                {isLinkModalShowing && (
                    <LinkToMemberModal
                        isModalShowing={isLinkModalShowing}
                        hide={toggleLinkModal}
                    />
                )}
            </Modal>
        </>
    )
}

export default ProfileModal
