import React, {
    useState,
    cloneElement,
    useRef,
    useEffect,
    useCallback,
    forwardRef,
} from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import useOnClickOutside from '../../hooks/useOnOutsideClick'
import { dropdownStyle } from '../../globalStyles'
import Icon from '../atoms/Icon'

const StyledFloater = styled.div`
    position: fixed;
    top: 0;
    overflow: auto;
    bottom: 0;
    height: clamp(100vh, 100vh, 100vh);
    width: clamp(100vw, 100vw, 100vw);
    padding: 40px;
    left: 0;
    right: 0;
    // background-color: red;
    // box-shadow: 0 0 0 8px green inset;
    z-index: ${(props) =>
        props.usedInModal === true
            ? 'calc(3 * var(--modalIndex))'
            : 'var(--dropdownIndex)'};
`
const StyledDropdown = styled.div`
    box-shadow: var(--shadow_2);
    border-radius: 8px;
    background-color: var(--color_background_depth_2);
    padding: 8px;
    z-index: ${(props) =>
        props.usedInModal === true
            ? 'calc(3 * var(--modalIndex))'
            : 'var(--dropdownIndex)'};
    position: absolute;
    color: var(--color_text);
    max-width: ${(props) => props.maxWidth || 'unset'};
    min-width: ${(props) => props.minWidth || '216px'};
    top: ${(props) => props.top};
    left: ${(props) => props.left};
    right: ${(props) => props.right || 'unset'};
    bottom: ${(props) => props.bottom || 'unset'};
    transition: transform 100ms var(--bounceEasing);
    overflow: auto;

    &.right {
        right: 0;
        left: unset;
    }

    &:focus {
        outline: none;
    }

    ${(props) =>
        props.fullWidth &&
        css`
            left: 0;
            right: 0;
            min-width: 100%;
        `}

    &.dropdown_animate {
        transform: scale(0.4);
    }
`

const StyledSubtext = styled.div`
    font-size: 13px;
    margin-top: 0px;
    color: var(--color_text_600);
    max-width: 24ch;
`

const StyledItem = styled.div`
    ${dropdownStyle}
    position: relative;
    white-space: ${(props) => (props.nowrap ? 'nowrap' : 'unset')};
    color: ${(props) => (props.danger ? 'var(--color_danger)' : 'inherit')};
    padding-right: ${(props) =>
        props.iconLeft && !props.iconRight ? '24px' : 'inherit'};
    justify-content: space-between;

    ${(props) =>
        props.size === 's' &&
        css`
            padding: 8px;
        `}

    &:hover {
        ${StyledSubtext} {
            color: white;
        }
    }
`

const StyledChevron = styled(Icon)`
    margin-left: 16px;
    margin-right: 8px;
`

const StyledLeftIcon = styled(Icon)`
    font-size: 16px;
    margin-right: 16px;
    color: ${(props) =>
        props.danger ? 'var(--color_danger)' : 'var(--brand_500)'};
`

const StyledDivider = styled.hr`
    margin-top: 8px;
    margin-bottom: 8px;
`

export const Item = ({
    iconLeft,
    iconRight,
    children,
    nowrap,
    size,
    subtext,
    ...rest
}) => {
    return (
        <StyledItem size={size} {...rest} tabIndex={0}>
            <div>
                {iconLeft && <StyledLeftIcon fullWidth icon={iconLeft} />}
                {children}
                <StyledSubtext>{subtext}</StyledSubtext>
            </div>
            {iconRight && <StyledChevron fullWidth icon={iconRight} />}
        </StyledItem>
    )
}

export const Divider = ({ ...rest }) => {
    return <StyledDivider />
}

export const Dropdown = forwardRef(
    (
        {
            children,
            trigger,
            maxWidth,
            minWidth,
            fullWidth,
            right,
            closeEvents,
            usedInModal,
            ...rest
        },
        ref
    ) => {
        const [isOpen, setIsOpen] = useState(false)
        const [position, setPosition] = useState({})
        const containerRef = useRef()
        // const triggerRef = useRef()

        const handleClick = (e) => {
            setIsOpen(!isOpen)
            let offset = 8
            let triggerData = e.target.getBoundingClientRect()
            let triggerX = triggerData.x
            let triggerY = triggerData.y
            let triggerWidth = triggerData.width
            let triggerHeight = triggerData.height
            let browserHeight = window.innerHeight
            let browserWidth = window.innerWidth
            let estDropdownWidth = 300
            let estDropdownHeight = 140

            let topPos = `${triggerHeight + triggerY + offset}px`
            let leftPos = `${triggerX}px`
            let positionObject
            let rightDropdownCutoff = browserWidth > estDropdownWidth + triggerX
            let bottomDropdownCutoff =
                triggerY + triggerHeight + estDropdownHeight > browserHeight

            if (!rightDropdownCutoff) {
                positionObject = {
                    top: topPos,
                    right: `${browserWidth - triggerX - triggerWidth}px`,
                }
            } else {
                positionObject = { top: topPos, left: leftPos }
            }

            if (bottomDropdownCutoff) {
                positionObject.bottom = '16px'
                positionObject.top = topPos
                delete positionObject.top
            }

            setPosition(positionObject)
        }

        const handleOnClickOutside = () => {
            closeEvents && closeEvents()
            setIsOpen(false)
        }

        const triggerElement = cloneElement(trigger, {
            onClick: (e) => handleClick(e),
        })

        useEffect(() => {
            const el = containerRef.current ? containerRef.current : null
            isOpen && containerRef.current.focus()
            if (isOpen) {
                el && el.classList.remove('dropdown_animate')
            } else {
                el && el.classList.add('dropdown_animate')
            }
        }, [isOpen])

        useOnClickOutside(containerRef, () => handleOnClickOutside())

        const keyPress = useCallback(
            (e) => {
                if (isOpen && e.key === 'Escape') {
                    setIsOpen(false)
                }
            },
            [isOpen]
        )

        useEffect(() => {
            if (isOpen) {
                document.addEventListener('keydown', keyPress)
                return () => document.removeEventListener('keydown', keyPress)
            }
        }, [keyPress])

        return (
            <>
                {triggerElement}
                {isOpen &&
                    ReactDOM.createPortal(
                        <>
                            <StyledFloater
                                usedInModal={usedInModal}
                                top={position.top}
                            >
                                <StyledDropdown
                                    ref={containerRef}
                                    top={position.top}
                                    bottom={position.bottom}
                                    right={position.right}
                                    left={position.left}
                                    maxWidth={maxWidth}
                                    minWidth={minWidth}
                                    fullWidth={fullWidth}
                                    className={'dropdown_animate'}
                                    usedInModal={usedInModal}
                                    tabIndex={0}
                                >
                                    <span ref={ref}>{children}</span>
                                </StyledDropdown>
                            </StyledFloater>
                        </>,
                        document.body
                    )}
            </>
        )
    }
)

Dropdown.Item = Item
Dropdown.Divider = Divider

export default Dropdown
