import React from 'react'
import { Route, useLocation, Redirect } from 'react-router-dom'
import DefaultLayout from './DefaultLayout'
import AuthenticatedRoute from '../../routes/AuthenticatedRoute'
import { isAuthenticated } from '../../assets/js/utils'
import firebase from 'firebase/app'
import 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import styled from 'styled-components'

const StyledLoading = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
`

export const LayoutRoute = ({
    authenticatedRoute = false,
    layout: Layout = DefaultLayout,
    component: Component,
    ...rest
}) => {
    const [user, loading, error] = useAuthState(firebase.auth())
    let location = useLocation()

    if (loading) {
        return <StyledLoading>Loading...</StyledLoading>
    } else if (authenticatedRoute) {
        return (
            <Route
                {...rest}
                render={(props) =>
                    !loading && !user ? (
                        <Redirect to={`/sign-in?page=${location.pathname}`} />
                    ) : (
                        <Layout {...props}>
                            <Component {...props} />
                        </Layout>
                    )
                }
            />
        )
    } else {
        return (
            <Route
                {...rest}
                render={(props) => (
                    <Layout {...props}>
                        <Component {...props} />
                    </Layout>
                )}
            />
        )
    }
}

export default LayoutRoute
