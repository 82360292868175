import React, { useContext, useState } from 'react'
import { UserContext } from '../../contexts/UserContext'
import Button from '../atoms/Button'
import InputGroup from './InputGroup'
import firebase from 'firebase/app'
import toaster from 'toasted-notes'
import styled from 'styled-components'
import ButtonGroup from './ButtonGroup'

const StyledForm = styled.form`
    margin-top: 40px;
    max-width: 320px;
    align-items: flex-end;
`

export const ChangePassword = ({ changePasswordMode }) => {
    const { user } = useContext(UserContext)

    const changePassword = (e) => {
        e.preventDefault()
        const newPasswordEl = e.target['newPassword']
        const currentPasswordEl = e.target['currentPassword']

        const newPassword = newPasswordEl.value
        const currentPassword = currentPasswordEl.value

        const credential = firebase.auth.EmailAuthProvider.credential(
            user.email,
            currentPassword
        )

        user.reauthenticateWithCredential(credential).then(() => {
            user.updatePassword(newPassword)
                .then(() => {
                    toaster.notify('Password updated')
                    newPasswordEl.value = ''
                    currentPasswordEl.value = ''
                })
                .catch((err) => {
                    newPasswordEl.value = ''
                    currentPasswordEl.value = ''
                    toaster.notify('Something went wrong. Try again.')
                    console.log(err.message)
                })
        })
    }

    return (
        <StyledForm onSubmit={(e) => changePassword(e)}>
            <InputGroup
                showPasswordOption={true}
                type="password"
                label="Current password"
                defaultValue=""
                name="currentPassword"
            />
            <InputGroup
                showPasswordOption={true}
                type="password"
                label="New password"
                defaultValue=""
                name="newPassword"
            />
            <ButtonGroup className="u-mar-t_2">
                <Button
                    whiteTransparent={true}
                    onClick={() => changePasswordMode(false)}
                >
                    Cancel
                </Button>
                <Button type="submit">Change password</Button>
            </ButtonGroup>
        </StyledForm>
    )
}

export default ChangePassword
