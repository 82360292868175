import React, { useContext, useState } from 'react'
import firebase from 'firebase/app'
import { UserContext } from '../contexts/UserContext'
import LeavesContext from '../contexts/LeavesContext'
import MemberContext from '../contexts/MemberContext'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

export const SignOut = () => {
    const { setUserId } = useContext(UserContext)
    const { setLeaves } = useContext(LeavesContext)
    const { setMemberData } = useContext(MemberContext)
    const [redirect, setRedirect] = useState(false)

    firebase
        .auth()
        .signOut()
        .then(() => {
            setUserId(null)
            setLeaves(null)
            setMemberData(null)
            setRedirect(true)
        })

    if (redirect) {
        return <Redirect to="/" />
    } else {
        return <div>Signing you out...</div>
    }
}

export default SignOut
