import React, { useState, createContext, useEffect, useContext } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { UserContext } from './UserContext'
import {
    getLeafImage,
    isAuthenticated,
    newMemberForFirebase,
} from '../assets/js/utils'
import { useCollection } from 'react-firebase-hooks/firestore'
import noUserImg from '../assets/images/profile_placeholder_gray.svg'

// import { REFS } from '../assets/js/globalVars';

export const MemberContext = createContext()

export const MemberContextProvider = (props) => {
    const { userId, loading } = useContext(UserContext)
    const [memberData, setMemberData] = useState(null)
    const memberRef = firebase.firestore().collection('members')
    const [memberPhoto, setMemberPhoto] = useState(null)
    const [claimedLeaf, setClaimedLeaf] = useState(false)

    useEffect(() => {
        const getMember = async () => {
            const value = await memberRef.doc(userId).get()

            if (value?.exists) {
                const doc = value || null

                setMemberData({
                    id: doc.id,
                    ...doc.data(),
                })

                const profilePhoto = doc?.data().profile_photo || null
                const photoRef =
                    profilePhoto && firebase.storage().ref(profilePhoto)
                if (photoRef) {
                    photoRef.getDownloadURL().then(function (url) {
                        setMemberPhoto(url)
                        // sessionStorage.setItem(memberData.id, url);
                    })
                }
            } else {
                createNewMember()
            }
        }

        const createNewMember = () => {
            console.log('No member found. Creating a new member...')
            // Prepare a member object to add to Firebase
            let obj = newMemberForFirebase({
                account_email: firebase.auth().currentUser.email,
            })

            memberRef
                .doc(userId)
                .set(obj)
                .then(() => {
                    memberRef.doc(userId).onSnapshot((snapshot) => {
                        console.log('New member successfully created!')

                        // Prepare member object for consumption via MemberContext
                        let memberDoc = {
                            id: snapshot.id,
                            ...snapshot.data(),
                        }

                        // Set member object to MemberContext
                        setMemberData(memberDoc)

                        // if (memberData?.profile_photo) {
                        //     // If a photo exists for that member, get the photo.
                        //     const photoRef = firebase
                        //         .storage()
                        //         .ref(memberData.profile_photo)

                        //     if (photoRef) {
                        //         photoRef.getDownloadURL().then(function (url) {
                        //             setMemberPhoto(url)
                        //             // sessionStorage.setItem(memberData.id, url);
                        //         })
                        //     }
                        // }
                    })
                })
                .catch((err) => {
                    console.log(err.message)
                })
        }

        userId && !loading && isAuthenticated() && getMember()
    }, [userId])

    useEffect(() => {
        if (!memberPhoto) {
            setMemberPhoto(noUserImg)
        }
    }, [memberPhoto])

    return (
        <MemberContext.Provider
            value={{
                memberData,
                setMemberData,
                memberPhoto,
                setMemberPhoto,
                claimedLeaf,
                setClaimedLeaf,
            }}
        >
            {props.children}
        </MemberContext.Provider>
    )
}

export default MemberContext
