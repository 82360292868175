import styled, { createGlobalStyle, css } from 'styled-components'

export const StyledHideOnMobile = styled.span`
    @media only screen and (max-width: 600px) {
        display: none;
    }
`

export const StyledBold = styled.span`
    font-weight: bold;
`

export const dropdownStyle = css`
    padding: 10px 14px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    color: var(--color_text);
    font-size: 15px;
    text-decoration: none;
    cursor: pointer;
    min-width: 100%;

    &:hover {
        background-color: var(--hoverBackgroundColor);
        color: white;
    }

    &:focus {
        outline: none;
        background-color: var(--hoverBackgroundColor);
    }

    &:active {
        background-color: var(--activeBackgroundColor);
    }

    a {
        text-decoration: none;
        color: var(--color_text);
    }
`

export const inputStyle_hover = css`
    box-shadow: 0 0 0 2px var(--color_border);
`

export const inputStyle_focus = css`
    outline: 0px solid transparent;
    box-shadow: 0 0 0 2px var(--color_10_light);
`

export const inputStyle = css`
    -webkit-appearance: none;
    font-family: var(--fontFamily), sans-serif;
    box-shadow: 0 0 0 1px var(--color_border_300);
    border: 0px solid transparent;
    background-color: var(--color_background_depth_1);
    color: var(--color_text);
    padding: 12px 16px;
    min-height: 44px;
    font-size: 16px;
    border-radius: 8px;

    &:hover {
        ${inputStyle_hover}
    }

    &:focus {
        ${inputStyle_focus}
    }
`

export const headerDividerStyle = css`
    font-weight: bold;
    display: flex;
    align-items: center;

    // &:after {
    //     content: "";
    //     height: 1px;
    //     flex: 1;
    //     width: 100%;
    //     background-color: var(--color_border);
    //     margin: 0 24px;
    // }
`

const GlobalStyle = createGlobalStyle`
    $detailsRevealTiming: .15s;
    $brand: #002831;

    :root {
        --fontFamily: 'Nunito';
        --fontFamily_header: 'Lato';
        --brand: #002831;

        --color_60: #f9f9f8;
        --color_15_light: #835fb3;
        --color_15_dark: #6b4a98;
        --color_10_light: #3a7ab6;
        --color_10_dark: #2e69a1;

        ${(props) =>
            props.theme.color === 'purple-blue' &&
            css`
                --color_60: #f9f9f8;
                --color_15_light: #835fb3;
                --color_15_dark: #6b4a98;
                --color_10_light: #3a7ab6;
                --color_10_dark: #2e69a1;
            `}

        ${(props) =>
            props.theme.color === 'purple-lightGreen' &&
            css`
                --color_60: #f9f9f8;
                --color_15_light: #835fb3;
                --color_15_dark: #6b4a98;
                --color_10_light: #609462;
                --color_10_dark: #508052;
            `}

        ${(props) =>
            props.theme.color === 'blue-purple' &&
            css`
                --color_60: #f9f9f8;
                --color_15_light: #5497d5;
                --color_15_dark: #3a7ab6;
                --color_10_light: #835fb3;
                --color_10_dark: #6b4a98;
            `}

        // // Green > Purple
        // --color_60: #F9F9F8;
        // --color_15_light: #295239;
        // --color_15_dark: #234530;
        // --color_10_light: #835FB3;
        // --color_10_dark: #6B4A98;

        // // Light Green > Purple
        // --color_60: #F9F9F8;
        // --color_15_light: #609462;
        // --color_15_dark: #508052;
        // --color_10_light: #835FB3;
        // --color_10_dark: #6B4A98;

        ${(props) =>
            props.theme.color === 'lightGreen-blue' &&
            css`
                --color_60: #f9f9f8;
                --color_15_light: #609462;
                --color_15_dark: #508052;
                --color_10_light: #3a7ab6;
                --color_10_dark: #2e69a1;
            `}

        // // OG Green > OG Green
        // --color_60: #F9F9F8;
        // --color_15_light: #002831;
        // --color_15_dark: #002831;
        // --color_10_light: #002831;
        // --color_10_dark: #002831;

        --brand_900: var(--brand);
        --brand_700: #485E66;
        --brand_600: #5C737B;
        --brand_500: #8699A0;
        --brand_400: #92A4AB;
        --brand_300: #BBC9CD;
        --brand_200: #D1DCDF;
        --brand_100: #e8edee;
        --brand_100s: #E3F2F5;
        --brand_50: #FAFCFD;

        --turquoise_300: #2A7899;

        --blue_50: #E9F5FB;
        --blue_100: #D3EAF8;
        --blue_200: #7CC0E8;
        --blue_400: #2D9CDB;
        --blue_500: #1B7FB7;
        --blue_950: #064162;

        --green_100: #B0D995;
        --green_800: #445C32;
        --green_900: #385126;

        --red_100: #F7E0DE;
        --red_400: #e14f45;
        --red_600: #B90B00;
        --red_700: #A40D03;

        // LIGHT THEME

        --color_gray: #BDBDBD;
        --color_dark_gray: #828282;
        --color_yellow: #FDD3A6;
        --color_border_500: var(--brand_500);
        --brand_lighter: #E3F2F5;

        --color_accent: var(--color_10_light);

        --navHeight: 88px;
        --treeNavHeight: 65px;
        --dragHandleWidth: 12px;

        --navDecorationHeight: 24px;
        --detailsWidth: 320px;
        --bounceEasing: cubic-bezier(0.51, 0.92, 0.24, 1.15);
        --iconButtonSize: 44px;
        // --color_border: #D9DFE0;
        --profileImageSize: 160px;
        --leafCaptionOffset: 12px;
        --leafSpacing: 16px;
        --leafShadow: 0px 2px 10px rgba(0,0,0,.15);
        --leafCaptionShadow: 0 0 12px rgba(0,0,0, .2);
        --detailsPanelDecorationTopOffset : 0;
        --spacer: 8px;
        --accountMenuSize: 56px;
        --detailsPanelImgSize: 48px;
        --toolbarHeight: 80px;

        --navPanelWidth: calc(240px + 16px);
        --navPanelShadow: -10px 10px 40px rgba(0,0,0,0.2);
        --detailsPanelShadow: 8px 8px 8px rgba(0,0,0,0.2);

        --navPanelWidthCollapsed: calc(72px + 0px);
        --detailsPanelWidth: 340px;
        // @media screen and (min-width: 1500px) {
        //       --detailsPanelWidth: 380px;
        //   }
        --panelPadding: 16px;
        --mainContentSpacing: 40px;
        --panelBoxShadow: 0 1px 48px rgba(0,0,0, 8%);
        --speed : .1s;
        --cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    :root {
        --color_text: #1F3C1B;
        --color_text_700: var(--brand_700);
        --color_text_600: var(--brand_600);
        --color_text_500: var(--brand_500);
        --color_text_300: var(--brand_300);
        --color_outline: var(--blue_400);
        --color_background_depth_3: #F4F5F7;
        --color_background_depth_2: white;
        --color_background_depth_1: var(--brand_50);
        --color_background_depth_4: #f5f8fa; // f1f5f8 or f5f8fa
        --color_background_depth_5: #e7eef1;;
        --color_border: #DADFE0;
        --color_border_200: var(--brand_200);
        --color_border_300: var(--brand_300);
        --color_border_400: var(--brand_400);
        --color_border_500: var(--brand_500);
        --color_border_700: var(--brand_700);
        --hoverBackgroundColor: var(--color_10_light);
        --activeBackgroundColor: var(--color_10_dark);

        --dropdownIndex: 1000;
        --overlayIndex: 1010;
        --modalWrapperIndex: 1020;
        --modalIndex: 1030;

        --color_danger: var(--red_600);
        --color_anchor: var(--color_accent);
        --button_color: var(--blue_50);
        --button_color_hover: var(--blue_50);
        --shadow_1: 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 40, 49, 0.04);
        --shadow_2: 0px 1px 8px rgba(0, 0, 0, 0.12), 0px 4px 32px rgba(0, 40, 49, 0.04);
        --shadow_3: 0px 1px 12px rgba(0, 0, 0, 0.12), 0px 2px 32px rgba(0, 40, 49, 0.08);

        ${(props) =>
            props.theme.mode === 'dark' &&
            css`
                --brand: #023e4c;
                --color_text: #e4e6eb;
                --color_text_700: var(--brand_500);
                --color_text_600: #637a82;
                --color_text_500: var(--brand_700);
                --color_text_300: #33454c;
                --color_background_depth_1: #111213;
                --color_background_depth_2: #18191a;
                --color_background_depth_3: #262626;
                --color_background_depth_4: #1d1e1e;
                --color_background_depth_5: #050607;
                --color_border: #333333;
                --color_border_200: #333;
                --color_border_300: #52595a;
                --color_border_500: var(--brand_500);
                --color_border_700: #464646;
                --color_anchor: var(--blue_400);
                --color_danger: var(--red_400);
                // --hoverBackgroundColor: #183343;
                // --activeBackgroundColor: #162129;
                --button_color: var(--blue_50);
                --shadow_1: 0px 1px 4px rgb(0 0 0 / 0.28),
                    0px 2px 24px rgb(0 0 0 / 24%);
                --shadow_2: 0px 1px 8px rgba(0, 0, 0, 0.45),
                    0px 4px 32px rgba(0, 40, 49, 0.25);
                --shadow_3: 0px 1px 12px rgba(0, 0, 0, 0.12),
                    0px 2px 32px rgba(0, 40, 49, 0.08);
            `}
    }

    * {
        box-sizing: border-box;
    }

    body {
        content: "${(props) => props.theme.mode}";
        font-family: var(--fontFamily), sans-serif;
        flex-direction: column;
        padding: 0;
        margin: 0;
        flex-direction: column;
        color: var(--color_text);
        overflow: auto;
        padding-bottom: env(safe-area-inset-bottom);
        background-color: var(--color_background_depth_1);
        line-height: 1.5;

        &.no-user-select {
            * {
                -webkit-user-select: none; /* Safari */
                -ms-user-select: none; /* IE 10 and IE 11 */
                user-select: none;
                pointer-events: none;
            }
        }
    }

    body, 
    html {
        overflow: auto;
        height: 100vh;
        max-height: 100vh;
        min-height: 100%;
        min-height: -moz-available;
        min-height: -webkit-fill-available;
        min-height: fill-available;
        
        &.scroll-locked {
            overflow: hidden;
            margin: 0;
            height: 100%:
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-weight: 900;
        font-family: var(--fontFamily_header);
    }

    button {
        font-family: var(--fontFamily), sans-serif;
    }

    ul,
    ol {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    h6 {
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: .5px;
        font-size: 11px;
        color: var(--color_text_700);
    }
    
    img {
        max-width: 100%;
    }
    
    fieldset {
        border: 0px solid transparent;
        padding: 0;
    }
    
    a,
    .a-link {
        color: var(--color_anchor);
        cursor: pointer;

        &--hover {
            &:hover {
                text-decoration: underline;
            }
        }
    
        &:focus {
            outline-offset: -2px;
            outline: 3px solid var(--color_outline);
        }

        :focus:not(:focus-visible) { 
            outline: none 
        }
    }
    
    hr {
        border-top: 1px solid var(--color_border);
        border-bottom: 0px solid transparent;
        border-left: 0px solid transparent;
        border-right: 0px solid transparent;
        // padding-top: 4px;
        // padding-bottom: 4px;
        margin: 0;
    }

    input,
    input[type=text],
    input[type=email],
    input[type=password],
    input[type=number],
    input[type=date],
    input[type=tel],
    input[type=search],
    select {
        &.a-input {
            ${inputStyle}
        }
    }

    input[type=radio] {
        --radioSize: 32px;
        width: var(--radioSize);
        min-height: unset;
        height: var(--radioSize);
        border-radius: calc(var(--radioSize) * 2);

        &:checked {
            background-color: var(--brand);
        }
    }
    
    label {
        display: block;
    }

    [role="dialog"] {

        &:focus {
            outline: none;
            box-shadow: 0 0 0 3px var(--color_outline);
        }

        &:focus:not(:focus-visible) { 
            box-shadow: none;
        }
    }

    .partners:hover,
    .partners:hover + .descendants {
        .leaf {
            svg {
                z-index: -1;
                
                path {
                    transition: all .1s ease-in-out;
                    stroke: var(--color_border_300);
                    stroke-width: 4;
                }
            }
    
            // transform: scale(1.05);
            // z-index: 1;
        }
    }
    
    .checkboxGroup {
        --checkboxtransition: all .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        --checkboxSize: 32px;
    
        display: flex;
        position: relative;
    
        input[type=checkbox] {
            position: absolute;
            visibility: hidden;
            display: none;
    
            &:checked ~ label:before {
                content: "\f00c";
                box-shadow: 0 0 0 2px var(--brand) inset;
                font-size: 18px;
                transition: var(--checkboxtransition);
            }
        }
    
        label {
            padding-left: calc(var(--checkboxSize) + 16px);
            position: relative;
            height: var(--checkboxSize);
            width: var(--checkboxSize);
            padding-top: 8px;
    
            &:before {
                content: "";
                font-weight: 400;
                font-family: "Font Awesome 5 Pro";
                width: var(--checkboxSize);
                height: var(--checkboxSize);
                line-height: var(--checkboxSize);
                border-radius: 8px;
                box-shadow: 0 0 0 2px var(--color_border) inset;
                margin-right: 8px;
                position: absolute;
                left: 0;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0px;
                transition: var(--checkboxtransition);
            }
    
            &:hover {
                cursor: pointer;

                &:before {
                    box-shadow: 0 0 0 2px var(--brand_500) inset;
                }
            }
        }
    
        &.right {
            justify-content: flex-end;
    
            label {
                padding-left: unset;
                padding-right: var(--checkboxSize) + 16px;
                text-align: right;
    
                &:before {
                    left: unset;
                    right: 0;
                    margin-right: unset;
                    margin-left: 8px;
                }
            }
        }
    }
    
    [disabled] {
        pointer-events: none;
        opacity: .5;
    }
    
    [data-tooltip] {
    
        &:after {
            content: attr(data-tooltip);
            font-family: var(--fontFamily), sans-serif;
            pointer-events: none;
            position: absolute;
            right: 50%;
            padding: 6px 10px;
            border-radius: 8px;
            font-size: 15px;
            font-weight: 400;
            opacity: 0;
            border: 1px solid var(--color_border);
            box-shadow: var(--shadow_1);
            line-height: initial;
            white-space: pre;
            color: var(--color_text);
            background-color: var(--color_background_depth_1);
            transition-delay: unset;
            transition: opacity .1s ease-in-out, 
                        right .15s ease-out, 
                        left .15s ease-out,
                        bottom .15s ease-out,
                        top .15s ease-out;
        }
        
        &:hover {
            z-index: 99;
            transition: opacity .2s ease-in-out;
    
            &:after {
                opacity: 1;
                right: calc(100% + 8px);
                // transition-delay: .65s;
            }
        }
    
        &:focus {
            z-index: 99;
    
            &:after {
                right: calc(100% + 8px);
                opacity: 1;
            }
        }
    }
    
    [data-tooltip-position="right"] {
        &:after {
            left: 50%;
            right: unset;
        }
        &:hover:after,
        &:focus:after {
            left: calc(100% + 8px);
            right: unset;
        }
    }
    [data-tooltip-position="top middle"] {
        &:after {
            left: 50%;
            top: unset;
            right: unset;
            transform: translateX(-50%);
            bottom: 0;
        }
        &:hover:after,
        &:focus:after {
            right: unset;
            bottom: calc(100% + 8px);
        }
    }
    [data-tooltip-position="top left"] {
        &:after {
            left: 0;
            top: unset;
            bottom: 50%;
            right: unset;
        }
        &:hover:after,
        &:focus:after {
            bottom: calc(100% + 16px);
            right: unset;
        }
    }
    [data-tooltip-position="bm"] {
        &:after {
            left: 50%;
            bottom: unset;
            top: 100%;
            right: unset;
            transform: translateX(-50%);
        }
        &:hover:after,
        &:focus:after {
            right: unset;
            top: calc(100% + 8px);
        }
    }
    
    [data-tooltip-reveal="fast"]:hover:after {
        transition-delay: unset;;
    }
`
export default GlobalStyle
