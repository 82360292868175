import React, { useContext } from 'react'
import styled from 'styled-components'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import Avatar from '../atoms/Avatar'
import IconButton from '../atoms/IconButton'
import Icon from '../atoms/Icon'
import useProfileForm from '../../hooks/useProfileForm'
import Header from '../atoms/Header'
import { LeafImagesContext } from '../../contexts/LeafImageContext'

const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 40px;
    // margin-top: 16px;
    align-items: flex-start;
`

const StyledProfileHeader = styled.div`
    display: flex;
    align-items: center;
    // margin-top: 16px;
    position: sticky;
    top: 0;
`

const StyledAvatarWrapper = styled.div`
    position: relative;
`

const StyledEditPhotoButton = styled(IconButton)`
    position: absolute;
    right: -4px;
    bottom: -4px;
`

const StyledMemberEditMessage = styled.div`
    padding: 16px;
    border-radius: 8px;
    // border: 1px solid var(--color_border);
    color: var(--color_text_700);
    font-size: 15px;
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 24px;
    align-items: center;
    grid-area: header;
    background-color: var(--color_background_depth_5);
`

const StyledInputContainer = styled.div`
    margin-top: 32px;
`

const StyledHeader = styled(Header)`
    margin-bottom: 24px;
`

export const ProfilieModalItemsEdit = ({
    hideModal,
    setEditMode,
    setChangesMade,
    toggleUploadPhotoModal,
    ...rest
}) => {
    const { panelState } = useContext(DetailsPanelContext)
    const doc = panelState?.linkedMemberDoc || panelState?.leafDoc
    const isMember = panelState.linkedMemberDoc ? true : false
    const [inputElements, submitForm] = useProfileForm(
        doc,
        panelState?.treeId,
        panelState?.leafId
    )
    const { leafImages, getImageUrlById } = useContext(LeafImagesContext)

    return (
        <div>
            {isMember && (
                <StyledMemberEditMessage>
                    <Icon icon="info-circle" size="22px" />
                    This leaf is synced with your member profile. You can make
                    changes in your account profile.
                </StyledMemberEditMessage>
            )}
            {isMember || (
                <StyledContainer>
                    <StyledProfileHeader>
                        <StyledAvatarWrapper
                            onClick={() => toggleUploadPhotoModal()}
                        >
                            <Avatar
                                size="160px"
                                src={
                                    panelState.linkedMemberDoc
                                        ? getImageUrlById(
                                              panelState.linkedMemberDoc.id
                                          )
                                        : getImageUrlById(panelState.leafId)
                                }
                                borderRadius={
                                    panelState?.linkedMemberDoc ? '25%' : null
                                }
                            />
                            <StyledEditPhotoButton
                                icon="pencil-alt"
                                type="button"
                                circle={true}
                                data-tooltip="Edit photo"
                                data-tooltip-position="bm"
                            />
                        </StyledAvatarWrapper>
                    </StyledProfileHeader>
                    <StyledInputContainer>
                        <StyledHeader size="l">Edit profile</StyledHeader>
                        {inputElements}
                    </StyledInputContainer>
                </StyledContainer>
            )}
        </div>
    )
}

export default ProfilieModalItemsEdit
