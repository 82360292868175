import React from 'react'
import styled, { css } from 'styled-components'
import Icon from './Icon'
import Button from './Button'

export const StyledIconButton = styled(Button)`
    padding: 0;
    min-width: var(--iconButtonSize);
    height: var(--iconButtonSize);
    line-height: var(--iconButtonSize);
    border-radius: 8px;
    align-items: center;
    position: relative;
    padding: 16px;

    ${(props) =>
        props.square &&
        css`
            max-width: var(--iconButtonSize);
            width: var(--iconButtonSize);
            padding: 0px;
        `}

    ${(props) =>
        props.circle &&
        css`
            max-width: var(--iconButtonSize);
            width: var(--iconButtonSize);
            padding: 0px;
            border-radius: 100px;
        `}

    ${(props) =>
        props.size === 'm' &&
        `
        --iconButtonSize: 37px;
    `}


    ${(props) =>
        props.danger &&
        `
        background-color: var(--color_danger);
        color: white;
    `}

    ${(props) =>
        props.iconRight &&
        `
        width: unset;
        padding-left: 8px;
        padding-right: 8px;
    `}

    ${(props) =>
        props.whiteText &&
        `
        color: white;
    `}

    &:focus {
        box-shadow: 0 0 0 2px var(--color_outline);
    }

    ${(props) =>
        props.alwaysShowLabel &&
        css`
            --iconButtonSize: initial;
            border-radius: 4px;
            flex-direction: column;
            padding: 8px 12px;
            justify-content: space-between;
        `}
`

const StyledLabel = styled.div`
    font-weight: normal;
    font-size: 13px;
    color: var(--color_text_600);
    line-height: 1.2;
`

const StyledIconRight = styled(Icon)`
    margin-left: 8px;
`

const StyledIcon = styled(Icon)`
    line-height: 1px;
    pointer-events: none;
    text-decoration: none;
    font-size: ${(props) => props.fontSize || '20px'};

    ${(props) =>
        props.alwaysShowLabel &&
        css`
            line-height: unset;
        `}
`

export const IconButton = ({
    alwaysShowLabel,
    iconType,
    iconRight,
    iconRightType,
    fontSize,
    label,
    icon,
    circle,
    children,
    style,
    ...rest
}) => {
    return (
        <StyledIconButton
            {...rest}
            circle={circle}
            aria-label={label}
            alwaysShowLabel={alwaysShowLabel ? true : false}
        >
            <StyledIcon
                fontSize={fontSize}
                alwaysShowLabel={alwaysShowLabel ? true : false}
                iconType={iconType}
                style={style}
                icon={icon}
            />
            {children}
            {alwaysShowLabel && <StyledLabel>{label}</StyledLabel>}
            {iconRight && (
                <StyledIconRight iconType={iconRightType} icon={iconRight} />
            )}
        </StyledIconButton>
    )
}

export default IconButton
