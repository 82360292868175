import React, { useContext } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { UserContext } from '../contexts/UserContext'
import 'firebase/auth'

export const PublicRoute = ({ component: Component, ...rest }) => {
    const { userId } = useContext(UserContext)
    let redirectTo = '/profile'

    function useQuery() {
        return new URLSearchParams(useLocation().search)
    }

    let query = useQuery()
    redirectTo = query.get('page') || redirectTo

    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route
            {...rest}
            render={(props) =>
                userId ? <Redirect to={redirectTo} /> : <Component {...props} />
            }
        />
    )
}

export default PublicRoute
