import React, { useContext, useEffect, useState } from 'react'
import LeavesContext from '../contexts/LeavesContext'

export const useBranchHierarchy = () => {
    const { leaves } = useContext(LeavesContext)
    const [hierarchy, setHierarchy] = useState([])

    useEffect(() => {
        // const topMember =
        //     leaves.length > 0
        //         ? leaves.reduce((topLeaf, leaf) => {
        //               if (parents.length === 0) {
        //                   topLeaf = leaf
        //               }
        //               return topLeaf
        //           })
        //         : []

        // const topMember = leaves
        //     .filter((leaf) => {
        //         if (leaf.relationships.parents.length === 0) {
        //             const partners = leaf.relationships.partners
        //             let partnerHasParents = false
        //             partners.map(({ id }) => {
        //                 const partnerLeaf = leaves.find((l) => l.id === id)
        //                 partnerHasParents =
        //                     partnerLeaf.relationships.parents.length > 0
        //             })
        //             return !partnerHasParents && leaf.id
        //         }
        //     })
        //     .sort()[0]

        const leavesWithNoParents = leaves.filter((leaf) => {
            return leaf.relationships.parents.length === 0 && leaf
        })

        const topMember = leavesWithNoParents.filter((leaf) => {
            const partners = leaf.relationships.partners
            const partnerHasParents = partners.map((p) => {
                const partner = leaves.find((l) => l.id === p.id)
                return partner.relationships.parents.length > 0
            })
            return !partnerHasParents.includes(true) && leaf
        })[0]

        // const topMember = leaves.find((doc) => doc.top_member === true)

        const makeFam = (leaf, parentRelationship = null) => {
            const children = leaf.relationships.children?.sort() || []
            const partners = leaf.relationships.partners?.sort() || []

            //
            // TODO: Add partners children to create connecting lines from
            //

            let main = {
                data: { ...leaf },
                display_name: leaf.display_name,
                id: leaf.id,
                relationship: parentRelationship,
                claimed_by: leaf.claimed_by,
                children: [],
                partners: [],
            }

            if (children.length > 0) {
                children.map((childObj) => {
                    const childDoc = leaves?.find(
                        (childLeaf) => childLeaf.id === childObj.id
                    )

                    const relationship = leaf.relationships.children?.find(
                        (c) => c.id === childObj.id
                    )?.relation
                    childDoc &&
                        main.children.push(makeFam(childDoc, relationship))
                })
            }

            partners.map((partnerObj) => {
                const partnerDoc = leaves?.find(
                    (partnerLeaf) => partnerLeaf.id === partnerObj.id
                )
                const partnerChildren = partnerDoc?.relationships.children.map(
                    (childObj) => {
                        return {
                            id: childObj.id,
                            relationship: childObj.relation,
                        }
                    }
                )

                partnerDoc &&
                    main.partners.push({
                        data: { ...leaf },
                        display_name: partnerDoc.display_name,
                        id: partnerDoc.id,
                        relationship: partnerDoc.relationships.partners?.find(
                            (p) => p.id === leaf.id
                        )?.relation,
                        children: partnerChildren,
                        claimed_by: partnerDoc.claimed_by,
                    })
            })

            return main
        }

        if (leaves.length > 0) {
            setHierarchy(topMember ? [makeFam(topMember)] : [])
        } else {
            setHierarchy([])
        }
    }, [leaves])

    return { hierarchy }
}

export default useBranchHierarchy
