import React, { useState, createContext, useEffect } from 'react'

export const WindowSizeContext = createContext()

export const WindowSizeContextProvider = (props) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)

    function debounce(fn, ms) {
        let timer
        return (_) => {
            clearTimeout(timer)
            timer = setTimeout((_) => {
                timer = null
                fn.apply(this, arguments)
            }, ms)
        }
    }

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setWindowWidth(window.innerWidth)
            setWindowHeight(window.innerHeight)
        }, 500)

        window.addEventListener('resize', debouncedHandleResize)

        return () => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    })

    return (
        <WindowSizeContext.Provider value={{ windowWidth, windowHeight }}>
            {props.children}
        </WindowSizeContext.Provider>
    )
}

export default WindowSizeContextProvider
