import React, { useContext, useState, useEffect } from 'react'
import LeavesContext from '../../contexts/LeavesContext'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import {
    buildFullAddressString,
    getLeafImage,
    convertDate,
    buildBirthdayString,
} from '../../assets/js/utils'
import Avatar from '../atoms/Avatar'
import styled, { css } from 'styled-components'
import MemberContext from '../../contexts/MemberContext'
import Icon from '../atoms/Icon'
import CurrentTreeMembersContext from '../../contexts/CurrentTreeMembersContext'
import { LeafImagesContext } from '../../contexts/LeafImageContext'
import useCurrentMemberPermission from '../../hooks/useCurrentMemberPermission'
import Input from '../atoms/Input'
import useBranchHierarchy from '../../hooks/useBranchHierarchy'
import useProfileData from '../../hooks/useProfileData'

const StyledContainer = styled.div`
    --tableBorderColor: var(--color_border);
    --tableBorderRadius: 0px;
    overflow: auto;
    flex: 1;
    padding: 0 0 40px 0;
    transition: padding-left var(--speed) ease-out;
    max-width: calc(1280px + var(--detailsPanelWidth));
    margin: 0 auto;
`

const StyledTable = styled.table`
    width: max(700px, 100%);
    border-collapse: collapse;
    overflow: auto;
`

const StyledTh = styled.th`
    text-align: left;
    padding: 16px 8px;
    position: sticky;
    top: 0;
    font-weight: normal;
    z-index: 2;
    background-color: var(--color_background_depth_4);
    box-shadow: 0 -1px 0 0 var(--tableBorderColor) inset;
`

const StyledTr = styled.tr`
    --td_bg_color--hover: #0060ba1a;
    text-align: left;

    &.focus {
        outline: none;
    }

    ${(props) =>
        props.selected &&
        css`
            --tableBorderRadius: 16px;
            --tableBorderColor: 0px solid transparent;

            td {
                background-color: var(--td_bg_color--hover);
            }

            &:hover td {
                background-color: var(--td_bg_color--hover);
                --tableBorderColor: 0px solid transparent;
            }
        `}

    &.highlight {
        td:first-child {
            transform: scale3d(1.2, 1.2, 1.2);
            transition: transform 0.3s cubic-bezier(0, 0.31, 0, 2.25);
        }
    }

    &:hover {
        cursor: pointer;

        td {
            background-color: var(--td_bg_color--hover);
            --tableBorderColor: 0px solid transparent;
            --tableBorderRadius: 16px;
        }
    }

    &:active {
        td {
            background-color: var(--td_bg_color--hover);
        }
    }

    td {
        &:first-child {
            border-top-left-radius: var(--tableBorderRadius);
            border-bottom-left-radius: var(--tableBorderRadius);
        }

        &:last-child {
            border-top-right-radius: var(--tableBorderRadius);
            border-bottom-right-radius: var(--tableBorderRadius);
        }
    }
`

const StyledTd = styled.td`
    padding: 8px 12px;
    white-space: nowrap;
    font-size: 15px;
`

const StyledPhotoTd = styled.td`
    padding: 8px;
    max-width: 48px;
    width: 64px;
    box-shadow: 0 -1px 0 0 var(--tableBorderColor) inset;
`

const StyledNameTd = styled(StyledTd)`
    font-weight: bold;
    box-shadow: 0 -1px 0 0 var(--tableBorderColor) inset;
`
const StyledCheckboxTd = styled(StyledTd)`
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledTdSmall = styled(StyledTd)`
    font-size: 14px;
    color: var(--color_text_700);
    box-shadow: 0 -1px 0 0 var(--tableBorderColor) inset;
`

const StyledAddressTd = styled(StyledTd)`
    font-size: 14px;
    white-space: unset;
    max-width: 24ch;
    color: var(--color_text_700);
    box-shadow: 0 -1px 0 0 var(--tableBorderColor) inset;
    min-width: 32ch;

    @media only screen and (max-width: 600px) {
        white-space: nowrap;
    }
`

const StyledAvatar = styled(Avatar)`
    margin-right: 8px;
`

const StyledPlaceholder = styled.span`
    font-style: italic;
    font-size: 15px;
    color: var(--color_text_300);
`

const StyledThead = styled.thead`
    margin-bottom: 8px;
    color: var(--color_text_700);
    font-size: 14px;
`

const StyledDeceased = styled(Icon)`
    color: var(--color_text_500);
    margin-left: 8px;
`

export const LeafList = () => {
    const { leaves } = useContext(LeavesContext)
    const { memberData } = useContext(MemberContext)
    const { panelState, setPanelState } = useContext(DetailsPanelContext)
    const TABLE_HEADERS = ['Name', 'Email', 'Phone', 'Birthday', 'Address']
    const [tableItems, setTableItems] = useState([])
    const { currentTreeMembers } = useContext(CurrentTreeMembersContext)
    const { getImageUrlById } = useContext(LeafImagesContext)
    const { memberPermission } = useCurrentMemberPermission()
    const { hierarchy } = useBranchHierarchy(leaves)

    const togglePanelState = (leafId) => {
        let leafDoc = leaves.find((leaf) => leaf.id === leafId)
        let memberDoc =
            leafDoc?.claimed_by &&
            currentTreeMembers.find(
                (member) => member.id === leafDoc.claimed_by
            )

        if (leafId) {
            setPanelState({
                ...panelState,
                leafId: leafId,
                leafDoc: leafDoc,
                linkedMemberDoc: memberDoc,
            })
        }
    }

    let items = []

    const generateTableRow = (doc) => {
        const leafId = doc.id
        let memberDoc =
            doc?.claimed_by &&
            currentTreeMembers.find((member) => member.id === doc.claimed_by)

        doc = memberDoc || doc
        if (doc.claimed_by === memberData?.id) {
            doc = memberData
        }

        const lastName = doc.last_name_current || doc.last_name_birth || ''
        const displayName = doc?.display_name || false
        const leadingName = displayName || false
        const fullName = leadingName ? (
            `${leadingName} ${lastName}`
        ) : (
            <StyledPlaceholder>No Name</StyledPlaceholder>
        )
        const birthday = doc && buildBirthdayString(doc)
        const deceased = doc?.death_date ? true : false
        const name = displayName ? displayName : fullName

        return (
            <StyledTr
                tabIndex="0"
                data-id={leafId}
                key={leafId}
                selected={panelState.leafId === leafId}
                onClick={() => togglePanelState(leafId)}
            >
                <StyledPhotoTd>
                    <StyledAvatar
                        size="xs"
                        src={getImageUrlById(doc.id)}
                        borderRadius={(memberDoc && '25%') || null}
                    />
                </StyledPhotoTd>
                <StyledNameTd>
                    {name}
                    {deceased && <StyledDeceased icon="tombstone" />}
                </StyledNameTd>
                <StyledTdSmall>
                    {doc.primary_email || (
                        <StyledPlaceholder>No email</StyledPlaceholder>
                    )}
                </StyledTdSmall>
                <StyledTdSmall>
                    {doc.primary_phone || (
                        <StyledPlaceholder>No phone</StyledPlaceholder>
                    )}
                </StyledTdSmall>
                <StyledTdSmall>
                    {birthday || (
                        <StyledPlaceholder>No birthday</StyledPlaceholder>
                    )}
                </StyledTdSmall>
                <StyledAddressTd>
                    {buildFullAddressString(doc) || (
                        <StyledPlaceholder>No address</StyledPlaceholder>
                    )}
                </StyledAddressTd>
            </StyledTr>
        )
    }

    const generateMember = (doc) => {
        let topDoc = leaves.find((l) => l.id === doc.id)
        items.push(generateTableRow(topDoc))

        const partners = doc.partners
        const children = doc.children

        if (partners?.length > 0) {
            for (const partnerObj of partners) {
                let partnerDoc = leaves.find(
                    (partnerDoc) => partnerDoc.id === partnerObj.id
                )
                partnerDoc && items.push(generateTableRow(partnerDoc))
            }
        }

        if (children?.length > 0) {
            for (const childObj of children) {
                let childDoc = leaves.find(
                    (reqDoc) => reqDoc.id === childObj.id
                )
                childDoc && generateMember(childObj)
            }
        }

        return items
    }

    useEffect(() => {
        if (hierarchy.length > 0) {
            // const topMemberDoc = leaves.find((doc) => doc.top_member === true)
            // topMemberDoc && setTableItems(generateMember(topMemberDoc))
            hierarchy[0] && setTableItems(generateMember(hierarchy[0]))
        }
    }, [hierarchy, panelState])

    return (
        <StyledContainer>
            <StyledTable>
                <StyledThead>
                    <tr>
                        <StyledTh key="photo" />
                        {TABLE_HEADERS.map((header) => (
                            <StyledTh key={header}>{header}</StyledTh>
                        ))}
                    </tr>
                </StyledThead>
                <tbody>{tableItems}</tbody>
            </StyledTable>
        </StyledContainer>
    )
}

export default LeafList
