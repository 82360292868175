import React, { useState, createContext, useEffect, useContext } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import LeavesContext, { LeavesContextProvider } from './LeavesContext'
import { getPlaceholderImage } from '../assets/js/utils'

export const LeafImagesContext = createContext()

export const LeafImagesContextProvider = (props) => {
    const [leafImages, setLeafImages] = useState([])
    const { leaves } = useContext(LeavesContext)

    useEffect(() => {
        if (leaves?.length === 0) {
            setLeafImages([])
        } else {
            const downloadProfileImage = async (photo, id) => {
                let url = await firebase
                    .storage()
                    .ref(`${photo}`)
                    .getDownloadURL()
                setLeafImages((leafImages) => [
                    ...leafImages,
                    { id: id, url: url, status: 'resolved' },
                ])
            }

            // TO DO LEFT OFF CHECK TO SEE IF EXITS IN THE LIST ALREADY. IF SO, MAYBE DON'T DOWNLOAD SOMEHOW
            for (const doc of leaves) {
                const existingLeafImage = leafImages.find(
                    (leaf) => leaf.id === doc.id
                )
                if (doc?.profile_photo) {
                    downloadProfileImage(doc.profile_photo, doc.id)
                }
            }
        }
    }, [leaves])

    const getImageUrlById = (id) => {
        return (
            leafImages.find((l) => l.id === id)?.url || getPlaceholderImage(id)
        )
    }

    return (
        <LeafImagesContext.Provider
            value={{ leafImages, setLeafImages, getImageUrlById }}
        >
            {props.children}
        </LeafImagesContext.Provider>
    )
}

export default LeafImagesContextProvider
