import React, { useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import styled from 'styled-components'
import Input from '../components/atoms/Input'
import Button from '../components/atoms/Button'
import InputGroup from '../components/organisms/InputGroup'
import useInvitationParams from '../hooks/useInvitationParams'
import { LinkButton } from '../components/atoms/LinkButton'
import Header from '../components/atoms/Header'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Redirect } from 'react-router'
import Checkbox from '../components/atoms/Checkbox'
import { Link } from 'react-router-dom'

const StyledWrapper = styled.div`
    background-color: var(--color_15_dark);
    padding: 48px;
    min-height: 100vh;
`
const StyledContainer = styled.div`
    display: flex;
    max-width: 380px;
    // margin: 64px auto;
    // border: 1px solid var(--color_border);
    border-radius: 16px;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: var(--shadow_1);
    background-color: var(--color_background_depth_2);

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        border: 0px solid var(--color_border);
    }
`

const StyledSignIn = styled.div`
    text-align: center;
    margin-bottom: 40px;
    color: var(--color_text_600);
    font-size: 14px;
    margin-top: 8px;
`

const StyledError = styled.p`
    color: var(--color_danger);
`

const StyledSignUpContainer = styled.form`
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    // text-align: center;
    padding: 48px;
    flex: 1;
`

const StyledHeader = styled(Header)`
    // margin-bottom: 40px;
    text-align: center;
`

export const SignUp = (props) => {
    const { invitation } = useInvitationParams()
    const [email, setEmail] = useState(invitation?.for_email || '')
    const [password, setPassword] = useState('')
    const [isChecked, setIsChecked] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [user, loading, error] = useAuthState(firebase.auth())

    const handleEmailInput = (e) => {
        !invitation && setEmail(e.target.value)
    }

    const handlePasswordInput = (e) => {
        setPassword(e.target.value)
    }

    const handleCheckboxChange = (e) => {
        setIsChecked(!isChecked)
    }

    const handleOnSubmit = (e) => {
        e.preventDefault()
        let isOfAge = e.target['ageVerification'].checked
        if (isOfAge) {
            const userEmail = invitation?.for_email || email
            firebase
                .auth()
                .createUserWithEmailAndPassword(userEmail, password)
                .then(() => {
                    let user = firebase.auth().currentUser
                    user.sendEmailVerification()
                        .then(function () {
                            // LEFT OFF:
                            // Error when signing up with "for_email". (w/o invitation param if that matters)
                            window.history.pushState(null, null, '/trees')
                            window.location.reload()
                        })
                        .catch((err) => {
                            setErrorMsg(err.message)
                        })
                })
                .catch((err) => {
                    setErrorMsg(err.message)
                    console.log('error creating a user')
                })
        } else {
            setErrorMsg('Verify that you are 13 years or older.')
        }
    }

    if (user) {
        return <Redirect to="/trees" />
    } else {
        return (
            <StyledWrapper>
                {1 === 1 && (
                    <StyledContainer>
                        <StyledSignUpContainer
                            autocomplete="off"
                            onSubmit={(e) => handleOnSubmit(e)}
                        >
                            <StyledHeader size="m">
                                {invitation
                                    ? `Create an account to view the invitation`
                                    : 'Create an account'}
                            </StyledHeader>
                            <StyledSignIn>
                                Already have an account?{' '}
                                <Link to="/sign-in">Sign in</Link>
                            </StyledSignIn>
                            <InputGroup
                                key="email"
                                label="Email"
                                autocomplete="off"
                                className="u-mar-b_2 u-w_full"
                                required={true}
                                name="email"
                                type="email"
                                value={invitation?.for_email || email}
                                onChange={handleEmailInput}
                            />
                            <InputGroup
                                key="password"
                                label="Password"
                                showPasswordOption={true}
                                autocomplete="new-password"
                                className="u-mar-b_2 u-mar-t_2 u-w_full"
                                required={true}
                                name="password"
                                type="password"
                                value={password}
                                onChange={handlePasswordInput}
                            />
                            <Checkbox
                                id="ageVerification"
                                className="u-mar-t_4"
                                value=""
                                name="ageVerification"
                                checked={isChecked}
                                label="I am 13 years of age or older."
                                onChange={(e) => handleCheckboxChange(e)}
                            />
                            {/* <div className="checkboxGroup u-mar-t_4">
                        <Input
                            id="ageVerification"
                            type="checkbox"
                            className="u-mar-t_4"
                            value=""
                            name="ageVerification"
                            checked={isChecked}
                            onChange={(e) => handleCheckboxChange(e)}
                        />
                        <label htmlFor="ageVerification">
                            I am 13 years of age or older.
                        </label>
                    </div> */}
                            <StyledError>{errorMsg}</StyledError>

                            <Button className="u-mar-t_4 u-w_full">
                                Create new account
                            </Button>
                        </StyledSignUpContainer>
                    </StyledContainer>
                )}
                {/* <StyledContainer>
                    <StyledSignUpContainer className="u-ta_center">
                        <p>Mily is currently in alpha.</p>
                        <p>Sign up requests coming soon!</p>
                    </StyledSignUpContainer>
                </StyledContainer> */}
            </StyledWrapper>
        )
    }
}

export default SignUp
