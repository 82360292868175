import React from 'react'
import LeafProfileItem from '../../components/general/DetailsPanel/LeafProfileItem'
import {
    DETAIL_ICONS,
    MEMBER_BLUEPRINT,
    DETAIL_METADATA,
    DETAIL_GROUPS,
    LEAF_BLUEPRINT,
    META_DETAILS,
    MEMBER_SPECIFIC_DETAILS,
    LEAF_SPECIFIC_DETAILS,
} from '../../assets/js/globalVars'
import placeholder_1 from '../../assets/images/profile_placeholder_gray.svg'
import placeholder_2 from '../../assets/images/profile_placeholder_2.svg'
import placeholder_3 from '../../assets/images/profile_placeholder_3.svg'
import placeholder_4 from '../../assets/images/profile_placeholder_4.svg'
import placeholder_5 from '../../assets/images/profile_placeholder_5.svg'
import placeholder_6 from '../../assets/images/profile_placeholder_6.svg'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'

export const getCurrentMemberPermissionByTreeId = (treeId, memberId) => {
    let currentMember = sessionStorage.getItem(memberId) || null
    let reqTree = sessionStorage.getItem(treeId) || null
    let parsedMember = currentMember && JSON.parse(currentMember)
    let parsedTree = reqTree && JSON.parse(reqTree)

    if (parsedMember && parsedTree) {
        return parsedTree.members[`${parsedMember.id}`]
    }
}

// export const getMemberPermissionByTreeId = (treeId, memberId) => {
//     let reqMember = sessionStorage.getItem(memberId) || null;
//     let reqTree = sessionStorage.getItem(treeId) || null;
//     let parsedMember = reqMember && JSON.parse(reqMember);
//     let parsedTree = reqTree && JSON.parse(reqTree);

//     if (parsedMember && parsedTree) {
//         return parsedTree.members[parsedMember.id];
//     }
// }

export const getSessionStorageByKey = (key) => {
    let reqData = sessionStorage.getItem(key) || null
    let paresedData = reqData && JSON.parse(reqData)

    return paresedData
}

export const getTreeDocFromStorage = (treeId) => {
    let reqTree = sessionStorage.getItem(treeId) || null
    let paresedData = reqTree && JSON.parse(reqTree)

    return paresedData
}

export const isAuthenticated = () => {
    let token = sessionStorage.getItem('authToken') || false
    return token
}

export const clearStorage = () => {
    sessionStorage.clear()
    // localStorage.clear();
}

export const sharedFunctionTest = (customMessage) => {
    alert(customMessage)
}

export const getLeafIndexFromId = (leafId, array) => {
    return array.indexOf(array.find((doc) => doc.id === leafId))
}

export const closeAllDropdowns = () => {
    let dropdowns = document.querySelectorAll('[data-dropdown]')

    for (let dropdown of dropdowns) {
        dropdown.classList.add('u-visibility_hidden')
    }
}

export const removeFromArrayReturnNewArray = (array, item) => {
    const targetArray = array
    const index = targetArray.indexOf(array.find((doc) => doc.id === item))

    console.log(item)

    if (index > -1) {
        targetArray.splice(index, 1)
    }

    return targetArray
}

export const buildFullNameString = (dataObject = null) => {
    let nameString = ''
    let displayName = dataObject['display_name'] || null
    let firstName = dataObject['first_name'] || null
    let middleName = dataObject['middle_name'] || null
    let lastNameCurrent = dataObject['last_name_current'] || null
    let lastNameBirth = dataObject['last_name_birth'] || null
    let lastName = lastNameCurrent || lastNameBirth || null
    let frontName = displayName || firstName

    if (!frontName && !middleName && !lastName) {
        nameString = null
    } else {
        nameString = `${frontName || ''} ${middleName || ''} ${lastName || ''}`
    }

    return nameString
}

export const buildFullAddressString = (memberObject) => {
    let addressString = ''
    let address1 = memberObject['address_1'] || null
    let address2 = memberObject['address_2'] || null
    let city = memberObject['city'] || null
    let state = memberObject['state'] || null
    let zipcode = memberObject['zipcode'] || null
    let country = memberObject['country'] || null

    city = city && state ? `${city},` : city
    state = state && country ? `${state},` : state

    if (!address1 && !address2 && !city && !state && !zipcode && !country) {
        addressString = null
    } else {
        addressString = `${address1 || ''} ${address2 || ''} ${city || ''} ${
            state || ''
        } ${zipcode || ''} ${country || ''}`
    }

    return addressString
}

export const buildBirthdayString = (memberObject) => {
    let birthdayString = ''
    let day = memberObject['birth_date'] || null
    let month = memberObject['birth_month'] || null
    let year = memberObject['birth_year'] || null

    if (month && day && year) {
        birthdayString = `${month} ${day}, ${year}`
    } else if (month && day && !year) {
        birthdayString = `${month} ${day}`
    } else if (month && !day && !year) {
        birthdayString = `${month}`
    } else if (!month && (!day || day) && year) {
        birthdayString = `${year}`
    } else if (month && !day && year) {
        birthdayString = `${month} ${year}`
    } else {
        birthdayString = null
    }

    return birthdayString
}

export const buildDeathDateString = (memberObject) => {
    let deathDateString = ''
    let day = memberObject['death_date'] || null
    let month = memberObject['death_month'] || null
    let year = memberObject['death_year'] || null

    if (month && day && year) {
        deathDateString = `${month} ${day}, ${year}`
    } else if (month && day && !year) {
        deathDateString = `${month} ${day}`
    } else if (month && !day && !year) {
        deathDateString = `${month}`
    } else if (!month && (!day || day) && year) {
        deathDateString = `${year}`
    } else if (month && !day && year) {
        deathDateString = `${month} ${year}`
    } else {
        deathDateString = null
    }

    return deathDateString
}

// export const buildFullName = (dataObject, Component = LeafProfileItem) => {
//     let nameData = dataObject["name"];
//     let nameString = '';
//     let firstName = nameData["firstName"] ? nameData["firstName"] : null;
//     let displayName = nameData["display_name"] ? `(${nameData["display_name"]})` : null;
//     let middleName = nameData["middleName"] ? nameData["middleName"] : null;
//     let surnameCurrent = nameData["surnameCurrent"] ? nameData["surnameCurrent"] : null;
//     let surnameBirth = nameData["surnameBirth"] ? nameData["surnameBirth"] : null;
//     let phonetic = nameData["phonetic"] ? nameData["phonetic"] : null;
//     let lastName = surnameCurrent || surnameBirth ? surnameCurrent || surnameBirth : null;

//     let nameElArray = [];

//     if (
//         !firstName && !displayName && !middleName && !lastName ) {
//         nameString = null;
//     } else {
//         nameString = `${firstName || ''} ${middleName || ''} ${lastName || ''}`;
//     }

//     if (nameString) {
//         nameElArray.push(
//             <Component
//                 key="name"
//                 icon={DETAIL_ICONS["name"]}
//                 data={nameString}
//                 >
//                 {nameString}
//             </Component>
//         )
//     }

//     if (phonetic) {
//         nameElArray.push(
//             <Component
//                 key="phonetic"
//                 icon={DETAIL_ICONS["phonetic"]}
//                 data={phonetic}
//                 >
//                 {phonetic}
//             </Component>
//         )
//     }

//     return nameElArray.length > 0 ? nameElArray : null;
// }

export const buildFullAddress = (memberObject) => {
    let addressData = memberObject['address']
    let addressString = ''
    let address1 = addressData['address1'] ? addressData['address1'] : null
    let address2 = addressData['address2'] ? addressData['address2'] : null
    let city = addressData['city'] ? addressData['city'] : null
    let state = addressData['state'] ? addressData['state'] : null
    let country = addressData['country'] ? addressData['country'] : null
    let zipcode = addressData['zipcode'] ? addressData['zipcode'] : null

    city = city && state ? `${city},` : city
    state = state && country ? `${state},` : state

    if (!address1 && !address2 && !city && !state && !country && !zipcode) {
        addressString = null
    } else {
        addressString = `${address1 || ''} ${address2 || ''} ${city || ''} ${
            state || ''
        } ${country || ''} ${zipcode || ''}`
    }

    if (addressString) {
        return (
            <LeafProfileItem key="address" icon="house" fullWidth>
                {addressString}
            </LeafProfileItem>
        )
    } else {
        return null
    }
}

export const newLeafForFirebase = (params) => {
    let newLeafObject = {}

    for (let [key, value] of Object.entries(LEAF_BLUEPRINT)) {
        let newValue =
            params && params[value['pathName']]
                ? params[value['pathName']]
                : value['defaultValue']
        newLeafObject[value['pathName']] = newValue
    }

    return newLeafObject
}

export const newMemberForFirebase = (params) => {
    let newMemberObject = {}
    for (let value of Object.values(MEMBER_BLUEPRINT)) {
        let newValue =
            params && params[value['pathName']]
                ? params[value['pathName']]
                : value['defaultValue']
        newMemberObject[value['pathName']] = newValue
    }

    return newMemberObject
}

export const loopThroughMemberBlueprint = (params) => {
    let functionCall = params.functionCall
    let EXCLUDE_METADATA = DETAIL_METADATA

    if (params.exclude === 'none') {
        EXCLUDE_METADATA = []
    } else if (params.exclude) {
        EXCLUDE_METADATA = [...params.exclude]
    }

    for (let [key, value] of Object.entries(MEMBER_BLUEPRINT)) {
        let pathName = value['pathName']
        let defaultValue = value['defaultValue']

        if (!EXCLUDE_METADATA.includes(pathName)) {
            functionCall(key, value)
        }
    }
}

export const loopThroughProfileCategory = (params) => {
    let functionCall = params.functionCall
    let category = params.category

    let EXCLUDE_METADATA = DETAIL_METADATA

    if (params.exclude === 'none') {
        EXCLUDE_METADATA = []
    } else if (params.exclude) {
        EXCLUDE_METADATA = [...params.exclude]
    }

    for (let [key, value] of Object.entries(MEMBER_BLUEPRINT)) {
        let pathName = value['pathName']
        let defaultValue = value['defaultValue']

        if (
            !EXCLUDE_METADATA.includes(pathName) &&
            value['category' === category]
        ) {
            functionCall(key, value)
        }
    }
}

export const convertDate = (data) => {
    let options = { year: 'numeric', month: 'long', day: 'numeric' }
    let date = new Date(data.replace(/-/g, '/'))
    let formattedDate = new Intl.DateTimeFormat('en-US', options).format(date)
    return `${formattedDate}`
}

export const getLeafImage = (id, usePlaceholder = true) => {
    let reqImage = sessionStorage.getItem(id) || null

    if (!reqImage && usePlaceholder) {
        reqImage = getPlaceholderImage(id)
    }

    return reqImage
}

export const getPlaceholderImage = (leafId) => {
    // let rePlaceholder1 = new RegExp('^([0-1]|[A-E]|[a-e])');
    // let rePlaceholder2 = new RegExp('^([2-3]|[F-H]|[f-h])');
    // let rePlaceholder3 = new RegExp('^([4-6]|[I-M]|[i-m])');
    // let rePlaceholder4 = new RegExp('^([5-7]|[L-T]|[l-t])');
    // let rePlaceholder5 = new RegExp('^([8-9]|[U-Z]|[u-z])');

    // if (rePlaceholder1.test(leafId)) { return placeholder_1;
    // } else if (rePlaceholder2.test(leafId)) { return placeholder_2;
    // } else if (rePlaceholder5.test(leafId)) { return placeholder_5;
    // } else if (rePlaceholder3.test(leafId)) { return placeholder_3;
    // } else if (rePlaceholder4.test(leafId)) { return placeholder_4;
    // } else { return placeholder_1; }

    return placeholder_1
}

export const getMemberDataFromClaimedLeaf = (leafDoc, memberId = null) => {
    let data = null

    if (leafDoc && leafDoc.claimed_by) {
        data = getSessionStorageByKey(leafDoc.claimed_by)
    }

    return data
}

export const isLeafEditable = (panelState) => {
    if (panelState.linkedMemberDoc) {
        return false
    } else if (panelState.editRelationships || panelState.edit) {
        return false
    } else {
        return true
    }
}

export const getNameDetails = (doc) => {
    const displayName = doc?.display_name || null
    const firstName = doc?.first_name || null
    const middleName = doc?.middle_name || null
    const lastNameCurrent = doc?.last_name_current || null
    const lastNameBirth = doc?.last_name_birth || null
    const isNameMatch = firstName && displayName && firstName === displayName

    //
    // TODO: If ONLY display_name and first_name have been provided AND they do not match, then show AKA. Otherwise DO NOT!
    //

    const showAka =
        (firstName && (lastNameCurrent || lastNameBirth)) ||
        (firstName && displayName) ||
        null
    const showFormerly =
        firstName &&
        lastNameBirth &&
        lastNameCurrent &&
        lastNameCurrent !== lastNameBirth
    const showMiddleName = middleName && !showAka && !showFormerly
    const showLastName =
        ((lastNameBirth || lastNameCurrent) &&
            lastNameBirth === lastNameCurrent) ||
        (lastNameBirth && !lastNameCurrent) ||
        (!lastNameBirth && lastNameCurrent)
    const showLastNameCurrent =
        !firstName && !showLastName && lastNameCurrent && !showAka
    const showLastNameAtBirth =
        !firstName && !showLastName && lastNameBirth && !showAka

    let nameObj = []

    if (showAka) {
        const lastName = lastNameCurrent || lastNameBirth || ''
        nameObj.push(
            `Aka: ${firstName || ''} ${middleName || ''} ${
                lastName ?? lastName
            }`
        )
    }

    if (showFormerly) {
        nameObj.push(
            `Formerly: ${firstName} ${middleName || ''} ${lastNameBirth}`
        )
    }

    if (showMiddleName) {
        nameObj.push(`Middle name: ${middleName}`)
    }

    if (showLastNameCurrent) {
        nameObj.push(`Last name (current): ${lastNameCurrent}`)
    }

    if (showLastNameAtBirth) {
        nameObj.push(`Last name (at birth): ${lastNameBirth}`)
    }

    if (showLastName && !showAka) {
        nameObj.push(`Last name: ${lastNameBirth || lastNameCurrent}`)
    }

    return nameObj.length > 0 ? nameObj : false
}

export const setCssVar = (property, value) => {
    document.documentElement.style.setProperty(`--${property}`, `${value}px`)
}

export const getCssVar = (property) => {
    return getComputedStyle(document.documentElement).getPropertyValue(
        '--detailsPanelWidth'
    )
}

// GENERAL RULES:
///// No user can edit a member's profile data from the Tree page. (isLeafLinked)
//// If a leaf is linked to a member and that member is an admin, only an admin can unlink their data. (isReqMemberAdmin)

// AN ADMIN USER CAN:
//// LEAF OPTIONS:
//// Delete any leaf (isReqMemberAdmin)
//// Unlink any leaf from a member (isReqMemberAdmin)
//// Link a leaf to any member (isReqMemberAdmin)
//// Edit profile data of unlinked leaf (isReqLeafLinked isReqMemberAdmin)
//// Edit/Add relationships to any leaf (linked or unlinked) (isReqMemberAdmin)
//// TREE OPTIONS:
//// Invite members (isReqMemberAdmin)
//// Remove members (isReqMemberAdmin)

// A CONTRIBUTOR USER CAN:
//// Add to any leaf (isReqMemberContributor)
//// Edit profile and relationships data to any leaf they created (isReqMemberCreator)
//// Delete any leaf they have created (isReqMemberCreator)
//// TREE OPTIONS:
//// View member list (just photo and name) (isReqMemberContributor)

// A VIEWER USER CAN:
//// View all information
//// View member list (just photo and name)
//// Request to link member to leaf
//// TREE OPTIONS:
//// Request to join tree (isReqMemberViewer)
//// Leave tree

// FUNCTIONS NEEDED:
export const isLeafLinked = (leafDoc) => {
    return leafDoc?.claimed_by ? true : false
}

export const isViewer = (memberId, treeDoc) => {
    return treeDoc?.members[`${memberId}`] === 'viewer' ? true : false
}

export const isContributor = (memberId, treeDoc) => {
    return treeDoc?.members[`${memberId}`] === 'contributor' ? true : false
}

export const isAdmin = (memberId, treeDoc) => {
    return treeDoc?.members[`${memberId}`] === 'admin' ? true : false
}

export const isCreator = (memberId, leafDoc) => {
    return leafDoc?.created_by === memberId ? true : false
}

export const getMemberPermission = (memberId, treeDoc) => {
    return treeDoc.members[`${memberId}`]
}

export const isLinkedLeafAdmin = (leafDoc, treeDoc, currentMember) => {
    if (leafDoc.claimed_by === currentMember) {
        return false
    } else {
        return treeDoc?.members[`${leafDoc?.claimed_by}`] === 'admin'
            ? true
            : false
    }
}

export const PARENT_OPTIONS = ['Biological', 'Foster', 'Step', 'Unrelated']
export const CHILD_OPTIONS = [
    'Biological',
    'Foster',
    'Step',
    'Adopted',
    'Unrelated',
]
export const PARTNER_OPTIONS = [
    'Married',
    'Engaged',
    'Dating',
    'Separated',
    'Divorced',
    'Widowed',
    'Unrelated',
]
export const SIBLING_OPTIONS = ['Biological', 'Half', 'Step', 'Unrelated']
export const APART_RELATIONSHIPS = [
    'Separated',
    'Divorced',
    'Widowed',
    'Unrelated',
]
