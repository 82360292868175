import React, { useState, useEffect, useContext } from 'react'
import Modal from './Modal'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import { getLeafImage } from '../../assets/js/utils'
import styled, { css } from 'styled-components'
import Card from './Card'
import MemberContext from '../../contexts/MemberContext'
import useDropdown from '../../hooks/useDropdown'
import Dropdown from './Dropdown'
import useModal from '../../hooks/useModal'
import InviteModal from '../general/InviteModal'
import LinkToleafModal from './LinkToLeafModal'
import firebase, { firestore } from 'firebase/app'
import LeavesContext from '../../contexts/LeavesContext'
import Avatar from '../atoms/Avatar'
import InvitedMemberList from '../general/InvitedMemberList'
import Button from '../atoms/Button'
import Icon from '../atoms/Icon'
import toaster from 'toasted-notes'
import CurrentTreeMembersContext from '../../contexts/CurrentTreeMembersContext'
import useCurrentMemberPermission from '../../hooks/useCurrentMemberPermission'
import { LeafImagesContext } from '../../contexts/LeafImageContext'

const StyledMemberItem = styled.div`
    padding-right 0;
    padding-left 0;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    font-size: 16px;

    &:last-child {
        margin-bottom: 8px;
    }
`

const StyledAvatar = styled(Avatar)`
    margin-right: 16px;
`

const StyledRightGroup = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`

const StyledMemberInfo = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const StyledAccountEmail = styled.span`
    opacity: 0.67;
    font-size: 13px;
    margin-top: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const StyledNotification = styled(Button)`
    // display: flex;
    align-items: left;
    justify-content: flex-start;
    padding: 0 8px;
    width: 100%;
    font-size: 16px;
    margin-bottom: 8px;
    color: var(--color_10_light);
`
const StyledPlusIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 40px;
    border-radius: 40px;
    // background-color: var(--blue_100);
    // opacity: .35;
    font-size: 18px;
    font-weight: 900;
    color: var(--color_10_light);
    margin-right: 16px;
`

const StyledButton = styled(Button)`
    ${(props) =>
        props.isAdmin ||
        css`
            pointer-events: none;
        `}
`

export const MembersModal = ({ isModalShowing, hide, ...rest }) => {
    const [treeMembers, setTreeMembers] = useState([])
    const { panelState } = useContext(DetailsPanelContext)
    const { leavesData } = useContext(LeavesContext)
    const { memberData } = useContext(MemberContext)
    const { toggle } = useDropdown()
    const [toggleInviteMemberModal, isInviteMemberModalShowing] = useModal(
        false
    )
    const [activeTab, setActiveTab] = useState('active')
    const membersRef = firebase.firestore().collection('members')
    const treesRef = firebase.firestore().collection('trees')
    const { currentTreeMembers } = useContext(CurrentTreeMembersContext)
    const [isAdmin] = useCurrentMemberPermission([])

    const handleUpdatePermission = (permission, memberId) => {
        const arrayUnion = firebase.firestore.FieldValue.arrayUnion
        let newPermissons = panelState.treeDoc.member_permissions.filter(
            (m) => m.id !== memberId
        )
        newPermissons.push({ id: memberId, permission: permission })

        treesRef
            .doc(panelState.treeId)
            .update({
                member_permissions: newPermissons,
            })
            .then(() => {
                toaster.notify('Permission changed')
            })
            .catch((err) => {
                console.log(err.message)
            })
    }

    const handleRemoveMemberFromTree = (memberId) => {
        if (
            window.confirm(
                'Are you sure you want to remove this member from this tree?'
            )
        ) {
            let treePermissions = panelState.treeDoc.member_permissions
            treePermissions = treePermissions.filter((m) => m.id !== memberId)

            treesRef
                .doc(panelState.treeId)
                .update({
                    members: firestore.FieldValue.arrayRemove(memberId),
                    member_permissions: treePermissions,
                })
                .catch((err) => {
                    console.log(err.message)
                })
        }
    }

    useEffect(() => {
        let newVersion = true
        let newMemberList = []

        if (newVersion) {
            if (panelState.treeDoc) {
                const members = panelState.treeDoc.member_permissions.map(
                    (permDoc) => {
                        return currentTreeMembers.map((memberDoc) => {
                            if (permDoc.id === memberDoc.id) {
                                return (
                                    <MemberItem
                                        key={memberDoc?.id}
                                        memberId={memberDoc?.id}
                                        permission={permDoc.permission}
                                        permissionLabel={
                                            permDoc.permission
                                                .charAt(0)
                                                .toUpperCase() +
                                            permDoc.permission.slice(1)
                                        }
                                        firstName={
                                            memberDoc?.display_name ||
                                            memberDoc?.first_name
                                        }
                                        lastName={
                                            memberDoc?.last_name_current ||
                                            memberDoc?.last_name_birth ||
                                            ''
                                        }
                                        email={memberDoc?.account_email}
                                    />
                                )
                            }
                        })
                    }
                )

                setTreeMembers(members)
            }
        } else {
            if (panelState.treeDoc) {
                for (const [memberId, permission] of Object.entries(
                    panelState.treeDoc.members
                )) {
                    const memberListData = currentTreeMembers.find(
                        (member) => member.id === memberId
                    )
                    // let memberListData = memberId === memberData?.id ? memberData : getSessionStorageByKey(memberId);
                    if (memberListData) {
                        newMemberList.push(
                            <MemberItem
                                key={memberListData?.id}
                                memberId={memberListData?.id}
                                permission={permission}
                                permissionLabel={
                                    permission.charAt(0).toUpperCase() +
                                    permission.slice(1)
                                }
                                firstName={
                                    memberListData?.display_name ||
                                    memberListData?.first_name
                                }
                                lastName={
                                    memberListData?.last_name_current ||
                                    memberListData?.last_name_birth ||
                                    ''
                                }
                                email={memberListData?.account_email}
                            />
                        )
                    }
                }
            }

            setTreeMembers(newMemberList)
        }
    }, [currentTreeMembers, panelState.treeDoc])

    const MemberItem = ({
        memberId,
        firstName,
        lastName,
        permissionLabel,
        permission,
        email,
    }) => {
        const [toggleInviteModal, isInviteModalShowing] = useModal(false)
        const { leafImages, getImageUrlById } = useContext(LeafImagesContext)
        const [profilePhoto, setProfilePhoto] = useState('')

        const handleSelectLeaf = (leafId) => {
            if (leafId) {
                let treesRef = firebase
                    .firestore()
                    .collection('trees')
                    .doc(panelState.treeId)

                treesRef
                    .collection('leaves')
                    .doc(leafId)
                    .update({ claimed_by: memberId })
                    .then(() => {
                        window.location.reload()
                    })
            }
        }

        useEffect(() => {
            setProfilePhoto(getImageUrlById(memberId))
        }, [leafImages])

        // const handleUnlinkLeaf = (memberId) => {
        //     let leafDoc = leavesData.find(leaf => leaf.claimed_by === memberId);
        //     if (leafDoc) {
        //         let treesRef = firebase.firestore().collection("trees").doc(panelState.treeId);
        //         treesRef.collection("leaves").doc(leafDoc.id).update({"claimed_by" : null})
        //         .then(() => {
        //             window.location.reload();
        //         })
        //     }
        // }

        return (
            <>
                <StyledMemberItem
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    permissionLabel={permissionLabel}
                    permission={permission}
                >
                    <StyledAvatar
                        src={profilePhoto}
                        size="xs"
                        borderRadius="8px"
                    />
                    <StyledMemberInfo>
                        <span>
                            {memberData?.id === memberId
                                ? `You`
                                : `${firstName} ${lastName && lastName}`}
                            {/* <StyledPermission>{permissionLabel}</StyledPermission> */}
                        </span>

                        <StyledAccountEmail>{email}</StyledAccountEmail>
                    </StyledMemberInfo>
                    <StyledRightGroup>
                        {/* <StyledPermission>{permissionLabel}</StyledPermission> */}
                        {isAdmin ? (
                            <Dropdown
                                right="0px"
                                usedInModal={true}
                                trigger={
                                    <Button
                                        onClick={toggle}
                                        whiteTransparent={true}
                                        disabled={memberData.id === memberId}
                                        iconRight="chevron-down"
                                    >
                                        {permissionLabel}
                                    </Button>
                                }
                            >
                                {/* <Dropdown.Item onClick={() => toggleInviteModal()}>Link to a leaf</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleUnlinkLeaf(memberId)}>Unlink from leaf</Dropdown.Item> */}
                                <Dropdown.Item
                                    size="s"
                                    onClick={() =>
                                        handleUpdatePermission(
                                            'admin',
                                            memberId
                                        )
                                    }
                                    disabled={permission === 'admin'}
                                    iconRight={
                                        permission === 'admin' && 'check'
                                    }
                                >
                                    Admin
                                </Dropdown.Item>
                                <Dropdown.Item
                                    size="s"
                                    onClick={() =>
                                        handleUpdatePermission(
                                            'contributor',
                                            memberId
                                        )
                                    }
                                    disabled={permission === 'contributor'}
                                    iconRight={
                                        permission === 'contributor' && 'check'
                                    }
                                >
                                    Contributor
                                </Dropdown.Item>
                                <Dropdown.Item
                                    size="s"
                                    onClick={() =>
                                        handleUpdatePermission(
                                            'viewer',
                                            memberId
                                        )
                                    }
                                    disabled={permission === 'viewer'}
                                    iconRight={
                                        permission === 'viewer' && 'check'
                                    }
                                >
                                    Viewer
                                </Dropdown.Item>
                                <Dropdown.Item
                                    size="s"
                                    danger={true}
                                    onClick={() =>
                                        handleRemoveMemberFromTree(memberId)
                                    }
                                >
                                    Remove member
                                </Dropdown.Item>
                            </Dropdown>
                        ) : (
                            <StyledButton
                                isAdmin={isAdmin}
                                whiteTransparent={true}
                                onClick={null}
                            >
                                {permissionLabel}
                            </StyledButton>
                        )}
                    </StyledRightGroup>
                </StyledMemberItem>
                <LinkToleafModal
                    onSelectLeaf={handleSelectLeaf}
                    zIndexOrder={2}
                    isModalShowing={isInviteModalShowing}
                    hide={toggleInviteModal}
                />
            </>
        )
    }

    const hideMembersModal = () => {
        setActiveTab('active')
        hide()
    }

    return (
        <>
            {isAdmin && (
                <InviteModal
                    isModalShowing={isInviteMemberModalShowing}
                    hide={toggleInviteMemberModal}
                    zIndexOrder={2}
                />
            )}
            <Modal
                isShowing={isModalShowing}
                hide={() => hideMembersModal()}
                size="m"
                closeButton={true}
            >
                <Card.Container>
                    <Card.Header title="Members" titleAs="h2" align="center">
                        {/* <StyledLinkedLeafKey>
                        <StyledLeafKeyIcon icon="leaf"/>
                        Claimed by a leaf
                    </StyledLinkedLeafKey> */}
                    </Card.Header>
                    <Card.Content>
                        {activeTab === 'active' && isAdmin && (
                            <StyledNotification
                                transparent={true}
                                onClick={() => toggleInviteMemberModal()}
                                key="addMember"
                            >
                                <StyledPlusIcon>
                                    <Icon icon="plus" />
                                </StyledPlusIcon>
                                Invite more people
                            </StyledNotification>
                        )}
                        {activeTab === 'active' && treeMembers}
                        {isAdmin && <InvitedMemberList />}
                    </Card.Content>
                </Card.Container>
            </Modal>
        </>
    )
}

export default MembersModal
