import React, { useState, createContext, useEffect, useContext } from 'react'
import DetailsPanelContext from './DetailsPanelContext'
import firebase from 'firebase/app'
import { LeafImagesContext } from './LeafImageContext'
import MemberContext from './MemberContext'

export const CurrentTreeMembersContext = createContext()

export const CurrentTreeMembersContextProvider = (props) => {
    const [currentTreeMembers, setCurrentTreeMembers] = useState([])
    const { panelState } = useContext(DetailsPanelContext)
    const { leafImages, setLeafImages } = useContext(LeafImagesContext)
    const { memberData } = useContext(MemberContext)

    useEffect(() => {
        if (panelState.treeDoc) {
            const treeDoc = panelState.treeDoc
            const membersRef = firebase.firestore().collection('members')

            const promises = treeDoc.members.map((memberId) =>
                membersRef.doc(memberId).get()
            )

            Promise.all(promises).then((values) => {
                const memberDocs = values.map((doc) => {
                    console.log(memberData)
                    if (panelState.treeDoc.members.includes(memberData?.id)) {
                        return {
                            id: doc.id,
                            ...doc.data(),
                        }
                    } else {
                        return {
                            id: doc.id,
                            display_name: doc.data().display_name,
                            profile_photo: doc.data().profile_photo,
                        }
                    }
                })

                setCurrentTreeMembers(memberDocs)

                const downloadProfileImage = async (photo, id) => {
                    let url = await firebase
                        .storage()
                        .ref(`${photo}`)
                        .getDownloadURL()
                    console.log('Downloading!')
                    setLeafImages((leafImages) => [
                        ...leafImages,
                        { id: id, url: url, status: 'resolved' },
                    ])
                }

                for (const doc of values) {
                    if (doc.data().profile_photo) {
                        downloadProfileImage(doc.data().profile_photo, doc.id)
                    }
                }
            })
        }
    }, [panelState.treeId])

    return (
        <CurrentTreeMembersContext.Provider
            value={{ currentTreeMembers, setCurrentTreeMembers }}
        >
            {props.children}
        </CurrentTreeMembersContext.Provider>
    )
}

export default CurrentTreeMembersContext
