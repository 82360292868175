import React from 'react'
import styled, { css } from 'styled-components'

const StyledButtonGroup = styled.div`
    --direction: row;
    --justify: ${(props) => props.justifyContent || 'flex-end'};
    --gap: ${(props) => props.gap || '8px'};
    // display: flex;
    flex-direction: var(--direciton);
    justify-content: var(--justify);
    // flex-wrap: wrap;

    display: grid;
    gap: var(--gap);
    grid-auto-flow: column;
    align-items: center;

    ${(props) =>
        props.fillSpace &&
        css`
            display: flex;
            gap: unset;

            > * {
                flex: 1;
                margin-left: 4px;
                margin-right: 4px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        `}

    ${(props) =>
        props.verticalStack &&
        css`
            display: flex;
            flex-direction: column;
            gap: unset;
            flex: 1;

            > * {
                width: 100%;
                margin-bottom: 4px;
                margin-top: 4px;

                &:first-child {
                    margin-top: 0;
                    margin-left: unset;
                    margin-right: unset;
                }

                &:last-child {
                    margin-bottom: 0;
                    margin-left: unset;
                    margin-right: unset;
                }
            }
        `}
`

export const ButtonGroup = ({ children, gap = '8px', ...rest }) => {
    return (
        <StyledButtonGroup gap={gap} {...rest}>
            {children}
        </StyledButtonGroup>
    )
}

export default ButtonGroup
