import React from 'react'
import Button from '../atoms/Button'
import ButtonGroup from '../organisms/ButtonGroup'
import styled from 'styled-components'
import useNotifcation from '../../hooks/useNotification'

const StyledNotificationItem = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    justify-content: space-between;
    border-radius: 8px;
    padding: 8px 8px 8px 16px;

    &:hover {
        background-color: var(--hoverBackgroundColor);
    }

`

const StyledMessage = styled.div`
    color: var(--color_text_500);
    font-style: italic;
`

const StyledButtonGroup = styled(ButtonGroup)`
    margin-left: 16px;
    display: flex;
`

const StyledList = styled.ul`
    width: 100%;
`

export const Notifications = () => {
    const {
        notifications,
        acceptNotification,
        declineNotifcation,
    } = useNotifcation()

    return (
        <StyledList>
            {notifications.length > 0 &&
                notifications.map((notification) => (
                    <StyledNotificationItem>
                        {`${notification.from_member_name} invited you to join "${notification.for_tree_name}"`}
                        <StyledButtonGroup>
                            <Button
                                whiteTransparent={true}
                                onClick={() =>
                                    declineNotifcation(notification.id)
                                }
                            >
                                Decline
                            </Button>
                            <Button
                                onClick={() =>
                                    acceptNotification(notification.id)
                                }
                            >
                                Accept
                            </Button>
                        </StyledButtonGroup>
                    </StyledNotificationItem>
                ))}
            {notifications.length === 0 && (
                <StyledMessage>You have no notifications</StyledMessage>
            )}
        </StyledList>
    )
}

export default Notifications
