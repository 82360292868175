import React, {
    useState,
    useContext,
    useRef,
    useEffect,
    cloneElement,
} from 'react'
import { CSSTransition } from 'react-transition-group'
import styled, { css } from 'styled-components'
import Icon from '../atoms/Icon'
import useOnClickOutside from '../../hooks/useOnOutsideClick'
import { dropdownStyle } from '../../globalStyles'
import Button from '../atoms/Button'
import MemberContext from '../../contexts/MemberContext'
import LeavesContext from '../../contexts/LeavesContext'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import { getSessionStorageByKey } from '../../assets/js/utils'

import childIcon from '../../assets/images/relationship_child.svg'
import parentIcon from '../../assets/images/relationship_parent.svg'
import siblingIcon from '../../assets/images/relationship_sibling.svg'
import partnerIcon from '../../assets/images/relationship_partner.svg'
import ButtonGroup from './ButtonGroup'
import Dropdown from './Dropdown'
import AddRelativeForm from '../general/AddRelativeForm'

const primary = 'primary'
const settingsMenu = 'secondary'
const speed = 250

const StyledDropdown = styled.div`
    position: relative;
    overflow: hidden;
    transition: all ${speed / 1.5}ms ease, width ${speed / 1.5}ms ease;
    min-width: 280px;
`

const StyledDropdownItem = styled.div`
    ${dropdownStyle}
    padding: 10px 8px;
`

const StyledMenu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: transform ${speed}ms var(--bounceEasing);
    min-width: 240px;
    max-width: 520px;
    padding: 1px;

    &.${primary}-enter {
        position: absolute;
        transform: translateX(-110%);
    }

    &.${primary}-enter-active {
        transform: translateX(0%);
    }

    &.${primary}-exit {
        position: absolute;
        transform: translateX(-110%);
    }

    &.${primary}-exit-active {
        // transform: translateX(-110%);
    }

    &.${settingsMenu}-enter {
        transform: translateX(110%);
    }

    &.${settingsMenu}-enter-active {
        transform: translateX(0%);
    }

    &.${settingsMenu}-exit {
        transform: translateX(110%);
    }

    &.${settingsMenu}-exit-active {
        // transform: translateX(0%);
    }
`

const StyledChevron = styled(Icon)`
    margin-left: auto;
`

const StyledLeftIcon = styled(Icon)`
    font-size: 16px;
    color: var(--brand_500);
    margin-right: 8px;
`

const StyledLeftImg = styled.img`
    height: auto;
    width: 24px;
    opacity: 0.65;
    margin-right: 16px;

    ${(props) =>
        props.theme.mode === 'dark' &&
        css`
            filter: brightness(6) saturate(0);
        `}
`

const StyledContainer = styled(Dropdown)`
    position: relative;
    // margin-left: 8px;
    transition: height ${speed}ms var(--bounceEasing);
`

const StyledIndirectMessage = styled.div`
    font-size: 14px;
    padding: 16px;
    border-radius: 8px;
    background-color: var(--color_background_depth_4);
    color: var(--color_text_700);
    max-width: 280px;
`

const StyledDivider = styled.hr`
    opacity: 75;
    margin: 0 auto;
    width: calc(100% - 32px);
    padding-bottom: 8px;
`

const StyledAddToButton = styled(Button)`
    animation: bounceIn;
    animation-duration: 0.2s;
`

export const buildRelationshipsObject = (relationshipObj) => {
    return Object.values(relationshipObj).reduce(
        (prev, { id, relationship, type }) => {
            if (prev[type]) {
                prev[type] = [...prev[type], { id: id, relation: relationship }]
            } else {
                prev[type] = [{ id: id, relation: relationship }]
            }

            return prev
        },
        {}
    )
}

export const AddRelationship = ({
    children,
    trigger,
    dropdownPosition,
    outline = true,
    transparent,
    fullWidth,
    forceDoc = null,
    ...rest
}) => {
    const [activeMenu, setActiveMenu] = useState('main')
    const { leaves } = useContext(LeavesContext)
    const { memberData } = useContext(MemberContext)
    const { panelState } = useContext(DetailsPanelContext)
    const [menuHeight, setMenuHeight] = useState(null)
    const [open, setOpen] = useState(false)
    const containerRef = useRef()
    const [firstName, setFirstName] = useState('')
    const [isIndirect, setIsIndirect] = useState(false)
    const [relationship, setRelationship] = useState('')

    const calcDimensions = (el) => {
        calcHeight(el)
    }

    const calcHeight = (el) => {
        const height = el?.offsetHeight
        setMenuHeight(height)
    }

    const handleClickOutside = () => {
        setOpen(false)
        calcDimensions()
    }

    const handleCancel = () => {
        setActiveMenu('main')
        calcDimensions()
    }

    useOnClickOutside(containerRef, () => handleClickOutside())

    useEffect(() => {
        setFirstName('leaf')
        const leafDoc =
            forceDoc || leaves.find((doc) => doc.id === panelState.leafId)

        if (leafDoc) {
            const parents = leafDoc?.relationships?.parents || []
            const partners = leafDoc?.relationships?.partners || []
            const name = leafDoc?.display_name || leafDoc?.first_name || null
            const isClaimed = leafDoc?.claimed_by || null

            if (isClaimed && isClaimed === memberData?.id) {
                setFirstName(memberData?.display_name || memberData?.first_name)
            } else if (isClaimed) {
                let data = getSessionStorageByKey(isClaimed)
                setFirstName(data?.display_name || data?.first_name)
            } else {
                setFirstName(name || 'leaf')
            }

            if (leafDoc.top_member === true) {
                setIsIndirect(false)
            } else {
                const partners = leafDoc.relationships.partners

                partners.forEach((partnerObj) => {
                    const partnerDoc = leaves.find(
                        (doc) => doc.id === partnerObj.id
                    )
                    const partnerParents =
                        partnerDoc.relationships.parents.length
                    partnerParents > 0 && setIsIndirect(true)
                })
            }

            // if (leafDoc.top_member === true) {
            //     setIsIndirect(false)
            // } else if (parents?.length === 0 && partners?.length > 0) {
            //     for (const partnerObj of partners) {
            //         const partnerDoc = leaves.find(
            //             (doc) => doc.id === partnerObj.id
            //         )
            //         const partnerParents =
            //             partnerDoc.relationships.parents.length
            //         if (partnerDoc.claimed_by) {
            //             let data
            //             if (partnerDoc.claimed_by === memberData.id) {
            //                 data = getSessionStorageByKey(memberData.id)
            //             } else {
            //                 data = getSessionStorageByKey(partnerDoc.claimed_by)
            //             }
            //         }
            //         partnerParents > 0 && setIsIndirect(true)
            //     }
            // } else {
            //     setIsIndirect(false)
            // }
        }
    }, [panelState.leafId])

    function AddRelationshipItem({
        children,
        goToMenu,
        iconRight,
        iconLeft,
        imgLeft,
        relationship,
    }) {
        const handleClick = () => {
            setActiveMenu(goToMenu)
            setRelationship(relationship)
        }

        return (
            <StyledDropdownItem
                onClick={() => goToMenu && handleClick(goToMenu)}
            >
                {imgLeft && (
                    <StyledLeftImg src={imgLeft} alt="relationship icon" />
                )}
                {iconLeft && <StyledLeftIcon fullWidth icon={iconLeft} />}
                {children}
                {iconRight && <StyledChevron icon={iconRight} />}
            </StyledDropdownItem>
        )
    }

    let triggerEl = null

    if (trigger) {
        triggerEl = cloneElement(trigger, {
            onClick: (e) => setOpen(!open),
        })
    }

    // prettier-ignore

    return (
        <StyledContainer
            closeEvents={() => handleCancel()}
            trigger={
                triggerEl || (
                    <StyledAddToButton
                        onClick={() => setOpen(!open)}
                        outline={panelState.leafId ? false : true}
                        solid={panelState.leafId ? true : false}
                        transparent={transparent}
                        size="m"
                        fullWidth={fullWidth}
                        iconLeft="user-plus"
                    >
                        Add leaf
                    </StyledAddToButton>
                )
            }
        >
            <StyledDropdown
                dropdownPosition={dropdownPosition}
                style={{ height: menuHeight}}
            >
                <CSSTransition
                    in={activeMenu === 'main'}
                    unmountOnExit
                    timeout={speed}
                    classNames="primary"
                    onEnter={calcDimensions}
                >
                    <StyledMenu>
                        {['child', 'parent', 'sibling', 'partner'].map((r) => {
                            if (panelState.leafId || forceDoc) {
                                if (isIndirect && r === "child" || !isIndirect) {
                                    return (
                                        <AddRelationshipItem
                                            key={r}
                                            goToMenu="chooseName"
                                            relationship={r}
                                            iconRight="chevron-right"
                                        >
                                            {`Add ${r}`}
                                        </AddRelationshipItem>
                                    )
                                }
                            }
                        })}
                        {isIndirect && <StyledDivider />}
                        {isIndirect && (
                            <StyledIndirectMessage>
                                <span className="u-bold">
                                    {`${firstName} is an indirect descendant.`}
                                </span>{' '}
                                {`Not all options are available for indirect descendants.`}
                            </StyledIndirectMessage>
                        )}

                        {!panelState.leafId && !forceDoc && (
                            <StyledIndirectMessage>
                                Select a leaf on the tree then try this button
                                again.
                            </StyledIndirectMessage>
                        )}
                    </StyledMenu>
                </CSSTransition>

                <CSSTransition
                    in={activeMenu === 'chooseName'}
                    unmountOnExit
                    timeout={speed}
                    classNames="secondary"
                    onEnter={() => calcDimensions(containerRef)}
                    // onEntered={focusOnInput}
                >
                    <StyledMenu>
                        <AddRelativeForm
                            leafId={forceDoc?.id || panelState.leafId}
                            type={relationship}
                            dismiss={handleCancel}
                        />
                    </StyledMenu>
                </CSSTransition>
            </StyledDropdown>
        </StyledContainer>
    )
}

export default AddRelationship
