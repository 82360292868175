import React, { useState, useContext } from 'react'
import Modal from './Modal'
import styled from 'styled-components'
import Card from './Card'
import ButtonGroup from './ButtonGroup'
import Button from '../atoms/Button'
import LeavesContext from '../../contexts/LeavesContext'
import Avatar from '../atoms/Avatar'
import { getLeafImage } from '../../assets/js/utils'

const StyledLeafItem = styled.label`
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: var(--blue_50);
    }

    &:active {
        background-color: var(--blue_100);
    }
`

const StyledAvatar = styled(Avatar)`
    margin-right: 8px;
`

export const LinkToleafModal = ({
    isModalShowing,
    hide,
    onSelectLeaf,
    currentLeaf = null,
    ...rest
}) => {
    const { leaves } = useContext(LeavesContext)
    const [selectedLeaf, setSelectedLeaf] = useState(currentLeaf)

    const handleClose = () => {
        onSelectLeaf && !currentLeaf && onSelectLeaf(null)
        hide()
    }

    const handleChooseLeaf = (leafId) => {
        onSelectLeaf && onSelectLeaf(leafId)
        hide()
        setSelectedLeaf(null)
    }

    return (
        <Modal
            isShowing={isModalShowing}
            hide={() => hide()}
            size="medium"
            closeButton={true}
            {...rest}
        >
            <Card.Container>
                <Card.Header
                    title="Choose a leaf"
                    titleAs="h2"
                    align="center"
                />
                <Card.Content maxHeight="45vh">
                    {leaves &&
                        leaves.map((leaf) => {
                            return (
                                !leaf.claimed_by && (
                                    <StyledLeafItem
                                        key={leaf.id}
                                        htmlFor={leaf.id}
                                        onClick={() => setSelectedLeaf(leaf.id)}
                                    >
                                        <StyledAvatar
                                            size="xs"
                                            src={getLeafImage(leaf.id)}
                                        />
                                        {leaf.first_name}
                                        <input
                                            defaultChecked={
                                                selectedLeaf === leaf.id ||
                                                currentLeaf === leaf.id
                                            }
                                            name="leafSelectionGroup"
                                            id={leaf.id}
                                            className="u-mar-l_auto"
                                            type="radio"
                                        />
                                    </StyledLeafItem>
                                )
                            )
                        })}
                </Card.Content>
                <Card.Footer>
                    <ButtonGroup>
                        <Button
                            whiteTransparent={true}
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!selectedLeaf}
                            onClick={() => handleChooseLeaf(selectedLeaf)}
                        >
                            Choose leaf
                        </Button>
                    </ButtonGroup>
                </Card.Footer>
            </Card.Container>
        </Modal>
    )
}

export default LinkToleafModal
