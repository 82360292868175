import React, { useContext, useEffect, useState } from 'react'
import DetailsPanelContext from '../contexts/DetailsPanelContext'
import LeavesContext from '../contexts/LeavesContext'
import MemberContext from '../contexts/MemberContext'
import firebase from 'firebase/app'
import {
    getMemberDataFromClaimedLeaf,
    newLeafForFirebase,
} from '../assets/js/utils'
import useTreeMembers from './useTreeMembers'
import { LeafImagesContext } from '../contexts/LeafImageContext'
import toaster from 'toasted-notes'
import { UserContext } from '../contexts/UserContext'

export const useRelatives = () => {
    const { panelState } = useContext(DetailsPanelContext)
    const { leaves } = useContext(LeavesContext)
    const { userId } = useContext(UserContext)
    const { getClaimedBy } = useTreeMembers()
    const getLeafDoc = (leafId = panelState?.leafId) => {
        return leaves?.find((l) => l.id === leafId)
    }
    const treeRef = firebase
        .firestore()
        .collection('trees')
        .doc(panelState.treeId)
    const leavesRef = treeRef.collection('leaves')
    const arrayUnion = firebase.firestore.FieldValue.arrayUnion

    const getRelatives = (doc, destinationRelativeType, sourceRelativeType) => {
        return doc.relationships[sourceRelativeType]?.map((d) => {
            const data = getLeafDoc(d.id)
            return {
                id: d.id,
                relation: null,
                type: destinationRelativeType,
                data: data,
                claimed: getClaimedBy(data.claimed_by),
            }
        })
    }

    const getOriginLeafObj = (doc, relativeType) => {
        return {
            id: doc.id,
            relation: null,
            type: relativeType,
            data: doc,
            claimed: getClaimedBy(doc.claimed_by),
        }
    }

    const getChildRelationships = (leafId) => {
        const doc = getLeafDoc(leafId)
        const toBeParents = getRelatives(doc, 'parents', 'partners')
        const toBeSiblings = getRelatives(doc, 'siblings', 'children')
        const originLeafDoc = getOriginLeafObj(doc, 'parents')

        return [...toBeParents, ...toBeSiblings, originLeafDoc]
    }

    const getParentRelationships = (leafId) => {
        const doc = getLeafDoc(leafId)
        const toBeChildren = getRelatives(doc, 'children', 'siblings')
        const toBePartners = getRelatives(doc, 'partners', 'parents')
        const originLeafDoc = getOriginLeafObj(doc, 'children')

        return [...toBeChildren, ...toBePartners, originLeafDoc]
    }

    const getSiblingRelationships = (leafId) => {
        const doc = getLeafDoc(leafId)
        const toBeSiblings = getRelatives(doc, 'siblings', 'siblings')
        const toBeParents = getRelatives(doc, 'parents', 'parents')
        const originLeafDoc = getOriginLeafObj(doc, 'siblings')

        return [...toBeSiblings, ...toBeParents, originLeafDoc]
    }

    const getPartnerRelationships = (leafId) => {
        const doc = getLeafDoc(leafId)
        const toBePartners = getRelatives(doc, 'partners', 'partners')
        const toBeChildren = getRelatives(doc, 'children', 'children')
        const originLeafDoc = getOriginLeafObj(doc, 'partners')

        return [...toBePartners, ...toBeChildren, originLeafDoc]
    }

    const createRelationObj = (relations, relationshipType) => {
        const relArray = relations.filter(
            (r) => r.relationship === relationshipType
        )
        return relArray.map((r) => ({
            id: r.id,
            relation: r.relation,
        }))
    }

    const getFirebasePromises = (toBeArray, relationship, newLeafId) => {
        return toBeArray.map((r) => {
            leavesRef.doc(r.id).update({
                [`relationships.${relationship}`]: arrayUnion({
                    id: newLeafId,
                    relation: r.relation,
                }),
            })
        })
    }

    const addChildTo = async (displayName, relations, callback = false) => {
        const toBeParents = createRelationObj(relations, 'parents')
        const toBeSiblings = createRelationObj(relations, 'siblings')

        const newLeaf = newLeafForFirebase({
            created_by: userId,
            relationships: {
                parents: toBeParents,
                siblings: toBeSiblings,
                children: [],
                partners: [],
            },
            display_name: displayName,
        })

        const newChildRef = await leavesRef.add(newLeaf)

        const parentPromises = getFirebasePromises(
            toBeParents,
            'children',
            newChildRef.id
        )

        const siblingPromises = getFirebasePromises(
            toBeSiblings,
            'siblings',
            newChildRef.id
        )

        Promise.all([...parentPromises, ...siblingPromises])
            .then(() => {
                toaster.notify(`${displayName} added!`)
                callback && callback()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const addParentTo = async (displayName, relations, callback = false) => {
        const toBeChildren = createRelationObj(relations, 'children')
        const toBePartners = createRelationObj(relations, 'partners')
        // const topMemberStatus = toBePartners.length === 0 ? true : false

        const newLeaf = newLeafForFirebase({
            created_by: userId,
            relationships: {
                parents: [],
                siblings: [],
                children: toBeChildren,
                partners: toBePartners,
            },
            display_name: displayName,
            // top_member: topMemberStatus,
        })

        const newChildRef = await leavesRef.add(newLeaf)

        // Demote toBeChild to have top_member: false.
        // await leavesRef.doc(panelState.leafId).update({
        //     top_member: false,
        // })

        const partnerPromises = getFirebasePromises(
            toBePartners,
            'partners',
            newChildRef.id
        )

        const childPromises = getFirebasePromises(
            toBeChildren,
            'parents',
            newChildRef.id
        )

        Promise.all([...partnerPromises, ...childPromises])
            .then(() => {
                toaster.notify(`${displayName} added!`)
                callback && callback()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const addSiblingTo = async (displayName, relations, callback = false) => {
        const toBeParents = createRelationObj(relations, 'parents')
        const toBeSiblings = createRelationObj(relations, 'siblings')
        let newParentRef
        let newParentPromises = []

        // If no parent, create a parent to connect the two siblings.
        if (toBeParents.length === 0) {
            const newParentLeaf = newLeafForFirebase({
                created_by: userId,
                relationships: {
                    parents: [],
                    siblings: [],
                    children: [...toBeSiblings],
                    partners: [],
                },
                display_name: 'New Parent',
            })

            newParentRef = await leavesRef.add(newParentLeaf)
            toBeParents.push({ id: newParentRef.id, relation: null })

            newParentPromises = toBeSiblings.map((r) => {
                leavesRef.doc(r.id).update({
                    [`relationships.parents`]: arrayUnion({
                        id: newParentRef.id,
                        relation: null,
                    }),
                })
            })
        }

        const newLeaf = newLeafForFirebase({
            created_by: userId,
            relationships: {
                parents: toBeParents,
                siblings: toBeSiblings,
                children: [],
                partners: [],
            },
            display_name: displayName,
        })

        const newChildRef = await leavesRef.add(newLeaf)

        const parentPromises = getFirebasePromises(
            toBeParents,
            'children',
            newChildRef.id
        )

        const siblingPromises = getFirebasePromises(
            toBeSiblings,
            'siblings',
            newChildRef.id
        )

        Promise.all([
            ...parentPromises,
            ...siblingPromises,
            ...newParentPromises,
        ])
            .then(() => {
                toaster.notify(`${displayName} added!`)
                callback && callback()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const addPartnerTo = async (displayName, relations, callback = false) => {
        const toBePartners = createRelationObj(relations, 'partners')
        const toBeChildren = createRelationObj(relations, 'children')

        const newLeaf = newLeafForFirebase({
            created_by: userId,
            relationships: {
                parents: [],
                siblings: [],
                children: toBeChildren,
                partners: toBePartners,
            },
            display_name: displayName,
        })

        const newChildRef = await leavesRef.add(newLeaf)

        const partnerPromises = getFirebasePromises(
            toBePartners,
            'partners',
            newChildRef.id
        )

        const childPromises = getFirebasePromises(
            toBeChildren,
            'children',
            newChildRef.id
        )

        Promise.all([...partnerPromises, ...childPromises])
            .then(() => {
                toaster.notify(`${displayName} added!`)
                callback && callback()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return {
        addChildTo,
        addParentTo,
        addSiblingTo,
        addPartnerTo,
        getChildRelationships,
        getParentRelationships,
        getSiblingRelationships,
        getPartnerRelationships,
    }
}

export default useRelatives
