import React from 'react'
import Input from './Input'
import NumberFormat from 'react-number-format'

// const StyledNumberFormat = styled(NumberFormat)`
// ${input]}
// `

export const PhoneInput = ({ className, ...rest }) => {
    return (
        <NumberFormat
            placeholder="+# (###) ###-###"
            className={`${className} a-input`}
            format="+# (###) ###-####"
            mask="_"
            {...rest}
        />
    )
}

export default PhoneInput
