import React, { useContext, useEffect, useRef, useState } from 'react'
import Avatar from '../atoms/Avatar'
import Button from '../atoms/Button'
import Header from '../atoms/Header'
import Select from '../atoms/Select'
import {
    CHILD_OPTIONS,
    PARENT_OPTIONS,
    SIBLING_OPTIONS,
    PARTNER_OPTIONS,
    getLeafImage,
} from '../../assets/js/utils'
import styled, { css } from 'styled-components'
import ButtonGroup from '../organisms/ButtonGroup'
import useRelatives from '../../hooks/useRelatives'
import { LeafImagesContext } from '../../contexts/LeafImageContext'
import InputGroup from '../organisms/InputGroup'

const StyledLi = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;

    &:last-of-type {
        margin-bottom: 0;
    }
`

const StyledUl = styled.ul`
    margin-bottom: 24px;

    &:last-of-type {
        margin-bottom: 0;
    }
`

const StyledAvatarNameContainer = styled.div`
    display: grid;
    gap: 16px;
    align-items: center;
    grid-template-columns: auto 1fr;
`

const StyledFoundMessage = styled.div`
    font-size: 15px;
    color: var(--color_text_800);
    background-color: var(--color_background_depth_4);
    border-radius: 8px;
    visibility: hidden;
    max-height: 0px;

    ${(props) =>
        props.isShowing &&
        css`
            visibility: unset;
            max-height: unset;
            padding: 12px;
            margin-bottom: 16px;
            margin-top: 16px;
        `}
`

const StyledForm = styled.form`
    width: 100%;
    padding: 1px;
`

export const AddRelativeForm = ({ leafId, type, dismiss, ...rest }) => {
    const {
        getChildRelationships,
        getParentRelationships,
        getSiblingRelationships,
        getPartnerRelationships,
        addChildTo,
        addParentTo,
        addSiblingTo,
    } = useRelatives()
    const [relatives, setRelatives] = useState([])
    const [children, setChildren] = useState([])
    const [parents, setParents] = useState([])
    const [siblings, setSiblings] = useState([])
    const [partners, setPartners] = useState([])
    const [addFunction, setAddFunction] = useState(null)
    const [nameError, setNameError] = useState('')
    const [showRelations, setShowRelations] = useState(false)
    const { getImageUrlById } = useContext(LeafImagesContext)
    const [name, setName] = useState('')
    const nameRef = useRef()

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        const selects = form.querySelectorAll('select')

        if (name) {
            const relationships = [...selects].map((s) => ({
                relation: s.value,
                relationship: s.dataset.type,
                id: s.name,
            }))
            addFunction(name, relationships, dismiss)
        } else {
            setNameError('Enter a name')
        }
    }

    useEffect(() => {
        if (leafId) {
            const formattedType = type.toLowerCase()
            let r

            switch (formattedType) {
                case 'child':
                    r = getChildRelationships(leafId)
                    setRelatives(r)
                    setAddFunction(() => addChildTo)
                    console.log(r)
                    break
                case 'parent':
                    r = getParentRelationships(leafId)
                    setRelatives(r)
                    setAddFunction(() => addParentTo)
                    break
                case 'sibling':
                    r = getSiblingRelationships(leafId)
                    setRelatives(r)
                    setAddFunction(() => addSiblingTo)
                    break
                case 'partner':
                    r = getPartnerRelationships(leafId)
                    setRelatives(r)
                    setAddFunction(() => addParentTo)
                    break
                default:
                    break
            }
            setChildren(r.filter((rel) => rel.type === 'children'))
            setParents(r.filter((rel) => rel.type === 'parents'))
            setSiblings(r.filter((rel) => rel.type === 'siblings'))
            setPartners(r.filter((rel) => rel.type === 'partners'))

            if (nameRef.current) {
                nameRef.current.focus()
            }
        }
    }, [leafId])

    return (
        <StyledForm onSubmit={(e) => handleSubmit(e)} {...rest}>
            <InputGroup
                ref={nameRef}
                name="display_name"
                label="First name or nickname"
                autoComplete="off"
                errorMessage={nameError}
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            {relatives.length > 0 && (
                <StyledFoundMessage isShowing={!showRelations}>
                    {relatives.length} relationships found
                    <span
                        onClick={() => setShowRelations(true)}
                        className="a-link a-link--hover u-mar-l_1"
                    >
                        Define now
                    </span>
                </StyledFoundMessage>
            )}
            <StyledFoundMessage isShowing={showRelations}>
                {[
                    {
                        array: children,
                        label: 'Children',
                        options: CHILD_OPTIONS,
                    },
                    {
                        array: parents,
                        label: 'Parents',
                        options: PARENT_OPTIONS,
                    },
                    {
                        array: siblings,
                        label: 'Siblings',
                        options: SIBLING_OPTIONS,
                    },
                    {
                        array: partners,
                        label: 'Partners',
                        options: PARTNER_OPTIONS,
                    },
                    ,
                ].map(
                    (data, index) =>
                        data.array.length > 0 && (
                            <div key={index}>
                                <div
                                    className="u-mar-t_1 u-mar-b_2 u-bold u-font-size_15"
                                    key={data.label}
                                >
                                    {name
                                        ? `${name}'s ${data.label.toLowerCase()}`
                                        : data.label}
                                </div>
                                <StyledUl key={index}>
                                    {data.array.map((rel) => (
                                        <StyledLi key={rel.id}>
                                            <StyledAvatarNameContainer>
                                                <Avatar
                                                    size="xs"
                                                    src={getImageUrlById(
                                                        rel.claimed?.id ||
                                                            rel.id
                                                    )}
                                                />
                                                {rel.claimed?.display_name ||
                                                    rel.data.display_name}
                                            </StyledAvatarNameContainer>
                                            <Select
                                                name={rel.id}
                                                defaultValue=""
                                                size="s"
                                                data-type={data.label.toLowerCase()}
                                            >
                                                <option disabled value="">
                                                    Choose...
                                                </option>

                                                {data.options.map((opt) => (
                                                    <option
                                                        key={opt}
                                                        value={opt}
                                                    >
                                                        {opt}
                                                    </option>
                                                ))}
                                            </Select>
                                        </StyledLi>
                                    ))}
                                </StyledUl>
                            </div>
                        )
                )}
            </StyledFoundMessage>
            <ButtonGroup>
                <Button
                    type="button"
                    iconLeft="arrow-left"
                    outline={true}
                    onClick={dismiss}
                >
                    Cancel
                </Button>
                <Button>{`Add ${type}`}</Button>
            </ButtonGroup>
        </StyledForm>
    )
}

export default AddRelativeForm
