import React, { useContext } from 'react'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import MemberContext from '../../contexts/MemberContext'
import { LeafImagesContext } from '../../contexts/LeafImageContext'

import Button from '../atoms/Button'
import Header from '../atoms/Header'
import ButtonGroup from './ButtonGroup'
import Card from './Card'
import Modal from './Modal'
import firebase from 'firebase/app'
import Avatar from '../atoms/Avatar'
import styled from 'styled-components'

const StyledAvatarGroup = styled.div`
    --claimLeafSize: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
`

const StyledLeafAvatar = styled(Avatar)`
    transform: translateX(12px);
    opacity: 0.5;
    box-shadow: 0 0 0 4px var(--color_background_depth_2);
`

const StyledMemberAvatar = styled(Avatar)`
    transform: translateX(-12px);
    --claimLeafSize: 80px;
    box-shadow: 0 0 0 4px var(--color_background_depth_2);
`

export const ClaimLeafModal = ({ isShowing, hide, ...rest }) => {
    const { panelState } = useContext(DetailsPanelContext)
    const { memberData, setClaimedLeaf, memberPhoto } = useContext(
        MemberContext
    )
    const { getImageUrlById } = useContext(LeafImagesContext)

    const handleSubmit = () => {
        const treeRef = firebase
            .firestore()
            .collection('trees')
            .doc(panelState.treeId)
        const leafRef = treeRef.collection('leaves').doc(panelState.leafId)

        memberData &&
            leafRef
                .update({
                    claimed_by: memberData.id,
                })
                .then(() => {
                    hide()
                    setClaimedLeaf(panelState.leafId)
                })
    }

    return (
        <Modal
            isShowing={isShowing}
            hide={hide}
            closeButton={false}
            size="s"
            {...rest}
        >
            <Card.Container>
                <Card.Content>
                    <Header className="u-ta_center u-mar-b_2">
                        Claim leaf
                    </Header>
                    <StyledAvatarGroup>
                        <StyledLeafAvatar
                            size="var(--claimLeafSize)"
                            src={getImageUrlById(panelState.leafId)}
                        />
                        <StyledMemberAvatar
                            size="var(--claimLeafSize)"
                            src={memberPhoto}
                        />
                    </StyledAvatarGroup>
                    <div className="u-ta_center">
                        This leaf will sync with your member photo and profile,
                        putting you in control of the profile.
                    </div>
                </Card.Content>
                <Card.Footer>
                    <ButtonGroup>
                        <Button onClick={() => hide()} outline={true}>
                            Cancel
                        </Button>
                        <Button onClick={() => handleSubmit()}>Continue</Button>
                    </ButtonGroup>
                </Card.Footer>
            </Card.Container>
        </Modal>
    )
}

export default ClaimLeafModal
