import React, { useContext, useEffect } from 'react'
import TreeNavBar from './TreeNavBar'
import { ViewPreferenceContextProvider } from '../../contexts/ViewPreferenceContext'
import styled from 'styled-components'
import { useParams } from 'react-router'
import useLeaves from '../../hooks/useLeaves'
import TreePage from '../../pages/TreePage'
import LeavesContext from '../../contexts/LeavesContext'
import { DetailsPanelContextProvider } from '../../contexts/DetailsPanelContext'
import { CurrentTreeMembersContextProvider } from '../../contexts/CurrentTreeMembersContext'
import { LeafImagesContextProvider } from '../../contexts/LeafImageContext'

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    height: 100vh;
    overflow: hidden;
`

export const TreeLayout = ({ children, ...rest }) => {
    let { treeId } = useParams()
    const { leaves, loading, error } = useLeaves(treeId)
    const { setLeaves } = useContext(LeavesContext)

    useEffect(() => {
        setLeaves(leaves)
    }, [leaves])

    return (
        <>
            <ViewPreferenceContextProvider>
                <LeafImagesContextProvider>
                    <DetailsPanelContextProvider>
                        <CurrentTreeMembersContextProvider>
                            <StyledContainer>
                                {!loading && (
                                    <>
                                        <TreeNavBar />
                                        <TreePage treeId={treeId} />
                                    </>
                                )}
                                {/* {error && <div>An error occured</div>} */}
                            </StyledContainer>
                        </CurrentTreeMembersContextProvider>
                    </DetailsPanelContextProvider>
                </LeafImagesContextProvider>
            </ViewPreferenceContextProvider>
        </>
    )
}

export default TreeLayout
