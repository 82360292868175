import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import IconButton from '../atoms/IconButton'
import Avatar from '../atoms/Avatar'
import Header from '../atoms/Header'
import ButtonGroup from '../organisms/ButtonGroup'
import DetailsPanelContext from '../../contexts/DetailsPanelContext'
import toaster from 'toasted-notes'
import Button from '../atoms/Button'
import useProfileData from '../../hooks/useProfileData'
import LeafProfileItem from './DetailsPanel/LeafProfileItem'
import LeavesContext from '../../contexts/LeavesContext'
import { LeafImagesContext } from '../../contexts/LeafImageContext'
import MemberContext from '../../contexts/MemberContext'

const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 40px;
    align-items: flex-start;
`

const StyledProfileHeader = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: sticky;
    top: 0;
    transform: scale(0.9);
    transition: transform 0.3s cubic-bezier(0, 0.31, 0, 2.25);

    ${(props) =>
        props.visible &&
        css`
            transform: scale(1);
        `}
`

const StyledNameContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 4px;
    margin-top: 32px;
    margin-bottom: 16px;
`

const StyledNameDetails = styled.div`
    color: var(--color_text_600);
    font-size: 14px;
`

const StyledProfileData = styled.div`
    display: flex;
    margin-bottom: 0;
    padding: 4px;
    border-radius: 8px;
    align-items: center;

    &:hover {
        background-color: var(--activeBackgroundColor);
    }
`

const StyledProperty = styled.div`
    margin: 4px 32px 4px 0;
    font-size: 11px;
    color: var(--color_text_500);
    width: 130px;
    max-width: 130px;
    text-align: right;
    text-transform: uppercase;
    letter-spacing: 0.1px;
    font-weight: bold;
`

const StyledValue = styled.div`
    flex: 1;
    max-width: 65%;
    margin-right: 16px;
    color: var(--color_text_700);
`

const StyledItemIconButton = styled(IconButton)`
    color: var(--color_text_500);
`

const StyledItemButtonGroup = styled(ButtonGroup)`
    margin-left: 16px;
    margin-right: 16px;
`

const StyledEmptyProfile = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 8px;
    padding: 24px;
    // text-align: center;
    // font-style: italic;
    color: var(--color_text_600);
    background-color: var(--color_background_depth_4);
`

const StyledButton = styled(Button)`
    margin-top: 16px;
`

const StyledFadeInWrapper = styled.div`
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    transition-delay: 0.2s;

    ${(props) =>
        props.visible &&
        css`
            opacity: 1;
        `}
`

export const ProfileModalItems = ({ hideModal, setEditMode, ...rest }) => {
    const { panelState } = useContext(DetailsPanelContext)
    const { memberPhoto } = useContext(MemberContext)
    const { profile, loading, empty } = useProfileData(
        panelState?.linkedMemberDoc || panelState?.leafDoc
    )
    const { leafImages, getImageUrlById } = useContext(LeafImagesContext)

    return (
        <>
            <StyledContainer>
                <StyledProfileHeader visible={loading ? false : true}>
                    <Avatar
                        size="160px"
                        src={
                            panelState.linkedMemberDoc?.id
                                ? getImageUrlById(panelState.linkedMemberDoc.id)
                                : getImageUrlById(panelState.leafId)
                        }
                        borderRadius={
                            panelState?.linkedMemberDoc ? '25%' : null
                        }
                    />
                </StyledProfileHeader>
                <StyledFadeInWrapper visible={loading ? false : true}>
                    {
                        <div>
                            <StyledNameContainer>
                                <Header size="l">{profile.displayName}</Header>
                                <StyledNameDetails>
                                    {profile?.nameDetails?.length > 0 &&
                                        profile?.nameDetails?.map(
                                            (name, index) => (
                                                <div key={index}>{name}</div>
                                            )
                                        )}
                                </StyledNameDetails>
                            </StyledNameContainer>
                            {profile?.data?.map(
                                (item) =>
                                    item.value && (
                                        <LeafProfileItem
                                            key={item.key}
                                            data={item}
                                            labelWidth="35%"
                                            showActions={true}
                                        />
                                    )
                            )}
                        </div>
                    }
                    {empty && (
                        <StyledEmptyProfile>
                            <div>
                                No profile details.{' '}
                                <span
                                    className="a-link a-link--hover"
                                    onClick={() => setEditMode(true)}
                                >
                                    Edit profile
                                </span>
                            </div>
                            {/* <StyledButton
                                size="m"
                                iconLeft="pencil-alt"
                                onClick={() => setEditMode(true)}
                            >
                                Edit
                            </StyledButton> */}
                        </StyledEmptyProfile>
                    )}
                </StyledFadeInWrapper>
            </StyledContainer>
        </>
    )
}

export default ProfileModalItems
