import React, { useEffect, useContext, useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import Button from '../atoms/Button'
import InputGroup from '../organisms/InputGroup'
import firebase from 'firebase/app'
import 'firebase/auth'
import { getLeafImage } from '../../assets/js/utils'
import MemberContext from '../../contexts/MemberContext'
import UploadPhotoModal from './UploadPhotoModal'
import useModal from '../../hooks/useModal'
import Walkthrough from '../organisms/Walkthrough'
import ButtonGroup from '../organisms/ButtonGroup'
import Avatar from '../atoms/Avatar'
import { useAuthState } from 'react-firebase-hooks/auth'
import Select from '../atoms/Select'
import { UserContext } from '../../contexts/UserContext'
import { Redirect } from 'react-router'

const StyledInputGroup = styled(InputGroup)`
    margin: 16px auto;
`

const StyledAvatar = styled(Avatar)`
    margin: 0 auto 32px auto;
`

const StyledBirthdayInputs = styled.div`
    display: grid;
    grid-template-columns: 2.5fr 1.5fr 2fr;
    gap: 8px;
    margin-bottom: 32px;
`

export const PromptDisplayNameModal = ({ ...rest }) => {
    const [redirect, setRedirect] = useState(false)
    const [displayNameErrorMessage, setDisplayNameErrorMessage] = useState(
        false
    )
    const { memberData, memberPhoto } = useContext(MemberContext)
    const [displayNameInput, setDisplayNameInput] = useState(
        memberData?.display_name || ''
    )
    const [toggleUploadPhotoModal, isUploadPhotoModalShowing] = useModal(false)
    const [user, loading] = useAuthState(firebase.auth())

    const handleOnChange = (e) => {
        setDisplayNameInput(e.target.value)
    }

    useEffect(() => {
        setMemberProfileImage(memberPhoto)
    }, [memberData])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (displayNameInput === '') {
            setDisplayNameErrorMessage('Enter a display name')
        } else {
            const membersRef = firebase
                .firestore()
                .collection('members')
                .doc(memberData?.id)
            membersRef.update({ display_name: displayNameInput }).then(() => {
                setDisplayNameInput('')
                setCurrentStep(currentStep + 1)
            })
        }
    }

    const [currentStep, setCurrentStep] = useState(1)
    const [memberProfileImage, setMemberProfileImage] = useState(
        getLeafImage(memberData?.id)
    )

    const handleFinishWalkthrough = () => {
        const membersRef = firebase
            .firestore()
            .collection('members')
            .doc(memberData?.id)
        membersRef.update({ completed_nm_walkthrough: true }).then(() => {
            window.history.pushState(null, null, `/profile`)
            window.location.reload()
        })
    }

    const handleProfileModal = () => {
        toggleUploadPhotoModal()
    }

    const handleProfileSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        const membersRef = firebase
            .firestore()
            .collection('members')
            .doc(memberData?.id)

        membersRef
            .update({
                birth_date: form['birth_date'].value,
                birth_month: form['birth_month'].value,
                birth_year: form['birth_year'].value,
                primary_email: form['primary_email'].value,
                primary_phone: form['primary_phone'].value,
            })
            .then(() => {
                setCurrentStep(currentStep + 1)
            })
    }

    const birthdayMonths = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    return (
        <Walkthrough
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            finish={handleFinishWalkthrough}
            isShowing={
                !loading &&
                user?.emailVerified &&
                (!memberData?.completed_nm_walkthrough ? true : false)
            }
            steps={[
                {
                    title: 'Welcome to Mily!',
                    icon: 'tree-alt',
                    showNext: true,
                    content: <>Create trees, invite family, stay connected!</>,
                },
                {
                    title: 'Welcome to Mily!',
                    icon: 'tree-alt',
                    content: (
                        <form onSubmit={(e) => handleSubmit(e)}>
                            What's your first name?
                            <StyledInputGroup
                                errorMessage={displayNameErrorMessage}
                                value={displayNameInput}
                                placeholder="First name or nickname"
                                onChange={(e) => handleOnChange(e)}
                            />
                            <Button fullWidth={true} type="submit">
                                {'Save and continue'}
                            </Button>
                        </form>
                    ),
                },
                {
                    title: 'Set your profile photo',
                    icon: 'user-circle',
                    content: (
                        <>
                            <StyledAvatar size="l" src={memberPhoto} />
                            <ButtonGroup verticalStack={true} fullWidth={true}>
                                <Button onClick={() => handleProfileModal()}>
                                    Choose photo...
                                </Button>
                                <Button
                                    outline={true}
                                    onClick={() =>
                                        setCurrentStep(currentStep + 1)
                                    }
                                >
                                    {memberPhoto ? 'Continue' : 'Skip'}
                                </Button>
                            </ButtonGroup>
                            <UploadPhotoModal
                                docType="member"
                                hideRemovePhoto={true}
                                hide={toggleUploadPhotoModal}
                                isShowing={isUploadPhotoModalShowing}
                                zIndexOrder={2}
                            />
                        </>
                    ),
                },
                {
                    title: 'Set your profile',
                    icon: 'user-circle',
                    content: (
                        <form onSubmit={(e) => handleProfileSubmit(e)}>
                            <p>
                                Add a few details about yourself. You'll be able
                                to add more at any time.
                            </p>
                            <InputGroup
                                name="primary_email"
                                label="Primary email"
                                type="email"
                                defaultValue={user && user.email}
                                messge=""
                            />
                            <InputGroup
                                name="primary_phone"
                                label="Primary phone"
                                type="tel"
                            />
                            <StyledBirthdayInputs>
                                <Select
                                    name="birth_month"
                                    label="Birth month"
                                    deafultValue=""
                                >
                                    <>
                                        <option value="">Choose...</option>
                                        {birthdayMonths.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </>
                                </Select>
                                <InputGroup
                                    name="birth_date"
                                    label="Birth day"
                                    defaultValue=""
                                    type="number"
                                    max="31"
                                    min="1"
                                />
                                <InputGroup
                                    name="birth_year"
                                    label="Birth year"
                                    defaultValue=""
                                />
                            </StyledBirthdayInputs>
                            <ButtonGroup verticalStack={true} fullWidth={true}>
                                <Button type="submit">Save and continue</Button>
                                <Button
                                    outline={true}
                                    type="button"
                                    onClick={() =>
                                        setCurrentStep(currentStep + 1)
                                    }
                                >
                                    Skip
                                </Button>
                            </ButtonGroup>
                        </form>
                    ),
                },
                {
                    title: 'Thanks!',
                    icon: 'thumbs-up',
                    content: (
                        <div>
                            Continue to edit your profile then create your first
                            family tree!
                        </div>
                    ),
                },
            ]}
        ></Walkthrough>
        // <Modal
        //     isShowing={userId && hasVerifiedEmail && !memberData?.display_name || false}
        //     size="m"
        //     handleSubmit={() => handleSubmit()}
        //     displayNameInput={displayNameInput}
        //     onChange={(e) => handleOnChange(e)}
        //     {...rest}
        // >
        //   <Card.Container as="form" onSubmit={(e) => handleSubmit(e)}>
        //     <Card.Header>
        //       <StyledWalkthroughHeader size="xl">Welcome!</StyledWalkthroughHeader>
        //     </Card.Header>
        //     <Card.Content>
        //       <StyledInputGroup
        //         label="What is your name?"
        //         type="text"
        //         value={displayNameInput}
        //         onChange={(e) => handleOnChange(e)}
        //         message={"Choose a first name, nickname, or whatever you like to be called."}
        //       >
        //       </StyledInputGroup>
        //       {/* <StyledPhotoButton
        //         iconLeft="image"
        //         iconType="light"
        //         type="button"
        //         transparent={true}
        //         onClick={toggleUploadPhotoModal}>
        //           Choose a profile photo
        //       </StyledPhotoButton> */}
        //       <StyledSetDisplayNameButton
        //        >
        //           Save to profile
        //       </StyledSetDisplayNameButton>
        //     </Card.Content>
        //   </Card.Container>
        //   <UploadPhotoModal
        //     docType="member"
        //     hideRemovePhoto={true}
        //     hide={toggleUploadPhotoModal}
        //     isShowing={isUploadPhotoModalShowing}
        //     zIndexOrder={2}
        //   />
        // </Modal>
    )
}

export default PromptDisplayNameModal
