import React, { forwardRef } from 'react'
import Input from '../atoms/Input'
import styled, { css } from 'styled-components'
import PhoneInput from '../atoms/PhoneInput'

const StyledLabel = styled.label`
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 15px;

    ${(props) =>
        props.alignHorizontal &&
        css`
            flex-direction: row;
        `}
`

const StyledInput = styled(Input)`
    margin-top: calc(8px * ${(props) => props.spacing || '1'});
    width: 100%;
`

const StyledPhoneInput = styled(PhoneInput)`
    margin-top: calc(8px * ${(props) => props.spacing || '1'});
    width: 100%;
`

const StyledMessage = styled.div`
    font-size: 13px;
    color: var(--color_text_600);
    margin-top: 4px;
`

const StyledErrorMessage = styled.div`
    font-size: 13px;
    color: var(--color_danger);
    margin-top: 4px;
`

export const InputGroup = forwardRef(
    (
        {
            label,
            errorMessage,
            alignHorizontal,
            className,
            message,
            phone,
            ...rest
        },
        ref
    ) => {
        return (
            <StyledLabel
                alignHorizontal={alignHorizontal}
                className={className}
            >
                {label}
                {phone ? (
                    <StyledPhoneInput spacing={1} ref={ref} {...rest} />
                ) : (
                    <StyledInput spacing={1} ref={ref} {...rest} />
                )}
                {message && <StyledMessage>{message}</StyledMessage>}
                {errorMessage && (
                    <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
                )}
            </StyledLabel>
        )
    }
)

export default InputGroup
